import React from 'react';
import Cellpal from '../../../../../assets/images/SoftwareDevelopment/SoftwareDevelopmentTab2/projectDetail/Cellpal/Cellpal_Dmain.png';
import DetailHeaderL from '../../../../../components/SoftwareDevelopmentjPage/SoftwareDevelopmentTab2/project/projectDetail/DetailHeaderL';

const CellpalHome: React.FC = () => {
  return (
    <>
      <DetailHeaderL
        tagline="스토어 발주 관리 시스템"
        title="셀팔"
        description1="이커머스 업무를 효율적으로 운영하기 위한 솔루션 플랫폼입니다."
        description2="스토어 API 연동, 엑셀 커스터마이징 및 연결, 그리고 자동 이메일 발송등을 통해 반복적인 업무를 자동화하여 손쉽게 커스터마이징하고 자동 발주할 수 있는 시스템을 구현했습니다."
        hashtags={[
          '발주',
          '관리',
          '발주 관리',
          '스토어',
          '네이버 스토어',
          '이메일',
          '이메일 발송',
          'UI',
          'UX',
          'UI UX',
          'UI UX 디자인',
          'Excel',
          'Excel 추출',
          'Web',
        ]}
        imgSRC={Cellpal}
        client="셀팔"
        service="Web / UI UX 디자인"
        year="2023"
        duration="1주일"
        textColor="#fff"
        lineColor="#432A8F"
      />
    </>
  );
};

export default CellpalHome;
