import React from 'react';

import Ai2 from '../../../../../assets/images/SoftwareDevelopment/SoftwareDevelopmentTab2/projectDetail/Ai/Ai2.png';
import Ai3 from '../../../../../assets/images/SoftwareDevelopment/SoftwareDevelopmentTab2/projectDetail/Ai/Ai3.png';
import Ai4 from '../../../../../assets/images/SoftwareDevelopment/SoftwareDevelopmentTab2/projectDetail/Ai/Ai4.png';
import Ai5 from '../../../../../assets/images/SoftwareDevelopment/SoftwareDevelopmentTab2/projectDetail/Ai/Ai5.png';
import Ai6 from '../../../../../assets/images/SoftwareDevelopment/SoftwareDevelopmentTab2/projectDetail/Ai/Ai6.png';
import Ai7 from '../../../../../assets/images/SoftwareDevelopment/SoftwareDevelopmentTab2/projectDetail/Ai/Ai7.png';
import DetailBody from '../../../../../components/SoftwareDevelopmentjPage/SoftwareDevelopmentTab2/project/projectDetail/DetailBody';

const AiBody: React.FC = () => {
  return (
    <>
      <DetailBody
        service={'WEB'}
        description1={`다소 딱딱할 수 있는 AI라는 주제를 어린 연령들이 좀 더 친근하게 다룰 수 있도록 전체적으로 부드러운 색상을 
        활용하여 거부감을 줄였습니다.`}
        description2={`각 문항 상황에 맞는 삽화를 추가하여 설문조사의
        이해를 도왔습니다.`}
        description3={`설문 결과에 따른 유형에 따라 각각의 캐릭터를 부여해 
        서로 공유하며 같은 유형을 가진 사람들끼리의 결속감을 느낄 수 있는 재미를 제공하였습니다.
        `}
        description4={`설문자의 조건을 조회하여 해당 
        설문자들의 통계를 확인할 수 있습니다.`}
        description5={`각 문항마다 생각나는 자신의 
        의견을 기입하여 AI에 대해 평소에 
        어떻게 생각하는지 공유할 수 있습니다.`}
        description6={`관리자 페이지에서만 보이는 의견 
        공유페이지에서 각 문항마다 설문자들이 적은 의견을 볼 수 있습니다.`}
        imgSRC1={Ai2}
        imgSRC2={Ai3}
        imgSRC3={Ai4}
        imgSRC4={Ai5}
        imgSRC5={Ai6}
        imgSRC6={Ai7}
        imgAlt1="WashCube"
      />
    </>
  );
};

export default AiBody;
