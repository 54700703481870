import { DevelopmentLanguageContainer } from '../../../../assets/css/StyledComponents';
import React from 'react';
import LanguageCard from './LanguageCard';
import objective_c from '../../../../assets/images/SoftwareDevelopment/SoftwareDevelopmentTab1/DevelopmentLanguage/objective-c.png';
import Swift from '../../../../assets/images/SoftwareDevelopment/SoftwareDevelopmentTab1/DevelopmentLanguage/Swift.png';
import SwiftUI from '../../../../assets/images/SoftwareDevelopment/SoftwareDevelopmentTab1/DevelopmentLanguage/SwiftUI.png';
import reactNative from '../../../../assets/images/SoftwareDevelopment/SoftwareDevelopmentTab1/DevelopmentLanguage/reactNative.png';
import Flutter from '../../../../assets/images/SoftwareDevelopment/SoftwareDevelopmentTab1/DevelopmentLanguage/Flutter.png';

function Ios() {
  return (
    <DevelopmentLanguageContainer>
      <LanguageCard imgSrc={objective_c} title="objective-c" />
      <LanguageCard imgSrc={Swift} title="swift" />
      <LanguageCard imgSrc={SwiftUI} title="swiftUI" />
      <LanguageCard imgSrc={reactNative} title="reactNative" />
      <LanguageCard imgSrc={Flutter} title="flutter" />
    </DevelopmentLanguageContainer>
  );
}

export default Ios;
