import React from 'react';

import Footer from '../../../../../components/Footer';

import StoryHome from './StoryHome';
import StoryBody from './StoryBody';
import CommonBanner from '../../../../../components/CommonBanner';
import WebPath from '../../../../../components/WebPath';

const StoryPage: React.FC = () => {
  return (
    <>
      <CommonBanner subtitle="Development" title="포트폴리오" />
      <WebPath subtitle="포트폴리오" />
      <StoryHome />
      <StoryBody />
      <Footer />
    </>
  );
};

export default StoryPage;
