import React, { useState } from 'react';
import Slider from 'react-slick';
import styled from 'styled-components';
import './DevelopmentPortfolio.css';

// 개발 프로필 이미지 임포트
import portfolioApp01 from '../../assets/images/portfolioApp/portfolioApp_01.png';
import portfolioApp02 from '../../assets/images/portfolioApp/portfolioApp_02.png';
import portfolioApp03 from '../../assets/images/portfolioApp/portfolioApp_03.png';
import portfolioApp04 from '../../assets/images/portfolioApp/portfolioApp_04.png';
import portfolioApp05 from '../../assets/images/portfolioApp/portfolioApp_05.png';
import portfolioApp06 from '../../assets/images/portfolioApp/portfolioApp_06.png';
import portfolioApp07 from '../../assets/images/portfolioApp/portfolioApp_07.png';
import portfolioApp08 from '../../assets/images/portfolioApp/portfolioApp_08.png';
import portfolioApp09 from '../../assets/images/portfolioApp/portfolioApp_09.png';
import portfolioApp10 from '../../assets/images/portfolioApp/portfolioApp_10.png';
import Arrow from '../../components/Arrow';
import RightArrow from '../../assets/images/PortfolioArrow.png';
import { useNavigate } from 'react-router-dom';
import {
  SoftwareDevelopmentUrl,
  SoftwareDevelopment_Ai_Url,
  SoftwareDevelopment_Busan_Url,
  SoftwareDevelopment_Company_Url,
  SoftwareDevelopment_FBMS_Url,
  SoftwareDevelopment_Hyodong_Url,
  SoftwareDevelopment_Lotto_Url,
  SoftwareDevelopment_RUGOLF_Url,
  SoftwareDevelopment_Story_Url,
  SoftwareDevelopment_WashCube_Url,
  SoftwareDevelopment_WelcomeKorea_Url,
} from '../../url/LodongUrl';

const DevelopmentPortfolioContainer = styled.div`
  padding: 100px 200px;
  @media (max-width: 968px) {
    padding: 50px 20px;
  }
`;

const Title = styled.h2`
  font-size: 50px;
  color: red;
  font-weight: 700;
  @media (max-width: 968px) {
    text-align: center;
    margin-bottom: 20px;
  }
  @media (max-width: 768px) {
    font-size: 30px;
  }
`;

const BusinessDescription = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 968px) {
    flex-direction: column;
    gap: 10px; // Add gap when stacked
  }
`;

const DescriptionText = styled.p`
  word-break: keep-all;
  flex: 1;
  @media (max-width: 968px) {
    text-align: center; // Center text on smaller screens
  }
`;

function DevelopmentPortfolio() {
  const navigate = useNavigate();
  const [isDragging, setIsDragging] = useState(false);

  const handleMouseDown = () => {
    setIsDragging(false);
  };

  const handleMouseMove = () => {
    setIsDragging(true);
  };

  const handleMouseUp = (link: string) => {
    if (!isDragging) {
      navigate(link);
    }
  };

  const handleClick = () => {
    navigate(SoftwareDevelopmentUrl); // 여기서 원하는 URL로 변경하세요.
  };

  const settings = {
    dots: true,
    infinite: true, // 무한 루프 설정
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3, // 한꺼번에 넘어가는 슬라이드 개수
    swipeToSlide: true, // 드래그로 부드럽게 넘어가게
    centerMode: true,
    centerPadding: '60px',
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2, // 한꺼번에 넘어가는 슬라이드 개수
          centerPadding: '30px',
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1, // 한꺼번에 넘어가는 슬라이드 개수
          centerPadding: '10px',
        },
      },
    ],
  };

  const portfolioImages = [
    { src: portfolioApp01, link: SoftwareDevelopment_FBMS_Url },
    { src: portfolioApp02, link: SoftwareDevelopment_WelcomeKorea_Url },
    { src: portfolioApp03, link: SoftwareDevelopment_Ai_Url },
    { src: portfolioApp04, link: SoftwareDevelopment_Hyodong_Url },
    { src: portfolioApp05, link: SoftwareDevelopment_Busan_Url },
    { src: portfolioApp06, link: SoftwareDevelopment_RUGOLF_Url },
    { src: portfolioApp07, link: SoftwareDevelopment_Story_Url },
    { src: portfolioApp08, link: SoftwareDevelopment_WashCube_Url },
    { src: portfolioApp09, link: SoftwareDevelopment_Lotto_Url },
    { src: portfolioApp10, link: SoftwareDevelopment_Company_Url },
  ];

  return (
    <DevelopmentPortfolioContainer>
      <div style={{ position: 'relative', marginBottom: 50 }}>
        <Title>Portfolio</Title>
        <BusinessDescription>
          <DescriptionText>
            여러가지 소프트웨어 개발과 지속적인 유지보수에 관여합니다.
          </DescriptionText>
          <Arrow img={RightArrow} onClick={handleClick} />
        </BusinessDescription>
      </div>
      <div className="slider-container">
        <Slider {...settings}>
          {portfolioImages.map((image, index) => (
            <div
              key={index}
              className="portfolio-item"
              onMouseDown={handleMouseDown}
              onMouseMove={handleMouseMove}
              onMouseUp={() => handleMouseUp(image.link)} // 각 이미지에 맞는 링크로 이동
            >
              <img
                src={image.src}
                alt={`Project ${index + 1}`}
                style={{ width: '100%', height: 'auto' }}
              />
            </div>
          ))}
        </Slider>
      </div>
    </DevelopmentPortfolioContainer>
  );
}

export default DevelopmentPortfolio;
