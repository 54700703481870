import React from 'react';
import DetailHeaderR from '../../../../../components/SoftwareDevelopmentjPage/SoftwareDevelopmentTab2/project/projectDetail/DetailHeaderR';
import WKorea from '../../../../../assets/images/SoftwareDevelopment/SoftwareDevelopmentTab2/projectDetail/WKorea/korea_Dmain.png';

const WKoraHome: React.FC = () => {
  return (
    <>
      <DetailHeaderR
        tagline="실용적인 한국생활 가이드"
        title="Welcome Korea"
        description1="웰컴 코리아는 한국을 방문하는 외국인, 한국에 장기 채류중인 외국인이 각자에게 현한 언어로, 각자에게 필요한 서비스를 이용할 수 있도록 기획했습니다."
        description2="며칠, 몇년을 지내더라도 편리하게 대한민국이 불편한 나라로 기억되지 않게 하려 합니다."
        hashtags={[
          '실시간',
          '위치',
          '공유',
          '실시간 위치',
          '실시간 위치 공유',
          '문자 발송',
          '이메일 발송',
          'UI',
          'UX',
          'UI  UX',
          '디자인',
          '웹 디자인',
          'UI  UX 디자인',
          '관리자용 웹',
        ]}
        imgSRC={WKorea}
        client="Welcome Korea"
        service="Web / Andriod app"
        year="2022"
        duration="2개월 소요"
        textColor="#84FFE2"
        lineColor="#3BD2AF"
        textLineColor="#000"
      />
    </>
  );
};

export default WKoraHome;
