import React from 'react';
import WebPath from '../components/WebPath';
import CommonBanner from '../components/CommonBanner';
import AboutUs from '../components/AboutUsPage/AboutUs';
import Business from '../components/AboutUsPage/Business';
import BusinessPartner from '../components/BusinessPartner';
import Footer from '../components/Footer';

function AboutUsPage() {
  return (
    <div>
      <CommonBanner subtitle="Lodong Introduction" title="회사소개" />
      <WebPath subtitle="회사소개" />
      <AboutUs />
      <Business />
      <BusinessPartner />
      <Footer />
    </div>
  );
}

export default AboutUsPage;
