import React from 'react';
import Footer from '../../../../../components/Footer';
import CareHome from './CareHome';
import CareBody from './CareBody';
import WebPath from '../../../../../components/WebPath';
import CommonBanner from '../../../../../components/CommonBanner';

const CarePage: React.FC = () => {
  return (
    <>
      <CommonBanner subtitle="Development" title="포트폴리오" />
      <WebPath subtitle="포트폴리오" />
      <CareHome />
      <CareBody />
      <Footer />
    </>
  );
};

export default CarePage;
