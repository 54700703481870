import React, { useState } from 'react';
import styled from 'styled-components';

// TabButton 컴포넌트
const StyledTabButton = styled.button<{ $active: boolean }>`
  font-weight: ${(props) => (props.$active ? 700 : 400)};
  flex: 1;
  font-size: 20px;
  padding: 10px 20px;
  border: none;
  outline: none;
  cursor: pointer;
  background-color: ${(props) => (props.$active ? '#e74c3c' : 'white')};
  color: ${(props) => (props.$active ? 'white' : 'black')};
  border-radius: 999px;
  transition: background-color 0.3s ease;

  @media (max-width: 768px) {
    font-size: 16px;
    padding: 8px 16px;
  }

  @media (max-width: 480px) {
    font-size: 14px;
    padding: 6px 12px;
  }
`;

interface TabButtonProps {
  active: boolean;
  onClick: () => void;
  children: React.ReactNode;
}

const TabButton: React.FC<TabButtonProps> = ({ active, onClick, children }) => {
  return (
    <StyledTabButton $active={active} onClick={onClick}>
      {children}
    </StyledTabButton>
  );
};

// TabContent 컴포넌트
const StyledTabContent = styled.div`
  padding-top: 20px;
  border-radius: 10px;
  margin-top: 10px;

  @media (max-width: 768px) {
    margin-top: 8px;
  }

  @media (max-width: 480px) {
    margin-top: 5px;
    padding-top: 0px;
  }
`;

interface TabContentProps {
  children: React.ReactNode;
}

const TabContent: React.FC<TabContentProps> = ({ children }) => {
  return <StyledTabContent>{children}</StyledTabContent>;
};

// TabComponent 컴포넌트
const TabContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #ccc;
  border-radius: 50px;
  overflow: hidden;
  width: 600px; // 원하는 너비로 설정
  margin: 50px auto;
  padding: 10px 20px;

  @media (max-width: 768px) {
    width: 90%;
    padding: 8px 16px;
    margin: 30px auto;
  }

  @media (max-width: 480px) {
    padding: 6px 12px;
    margin: 20px auto;
  }
`;

interface TabData {
  title: string;
  content: React.ReactNode;
}

interface TabComponentProps {
  tabs: TabData[];
}

const TabComponent: React.FC<TabComponentProps> = ({ tabs }) => {
  const [activeTab, setActiveTab] = useState(tabs[0].title);

  return (
    <div>
      <TabContainer>
        {tabs.map((tab, index) => (
          <TabButton
            key={index}
            active={activeTab === tab.title}
            onClick={() => setActiveTab(tab.title)}
          >
            {tab.title}
          </TabButton>
        ))}
      </TabContainer>
      <TabContent>
        {tabs.map((tab, index) =>
          activeTab === tab.title ? <div key={index}>{tab.content}</div> : null
        )}
      </TabContent>
    </div>
  );
};

export default TabComponent;
