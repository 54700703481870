import React from 'react';
import Busan1 from '../../../../../assets/images/SoftwareDevelopment/SoftwareDevelopmentTab2/projectDetail/Busan/Busan1.png';
import Busan2 from '../../../../../assets/images/SoftwareDevelopment/SoftwareDevelopmentTab2/projectDetail/Busan/Busan2.png';
import DetailBody from '../../../../../components/SoftwareDevelopmentjPage/SoftwareDevelopmentTab2/project/projectDetail/DetailBody';

const BusanBody: React.FC = () => {
  return (
    <>
      <DetailBody
        service={'ANDROID / UI UX 디자인'}
        description1={`공유부산은 적당한 대가를 지불하여 빌려쓰고새로운 물건을 사야한다는 부담을 줄여줍니다`}
        description2={`빌리는 것 뿐만 아니라, 쓰지 않는 중고물품의 판매/구매서비스 또한 제공합니다`}
        imgSRC1={Busan1}
        imgSRC2={Busan2}
        imgAlt1="돌봄대장"
      />
    </>
  );
};

export default BusanBody;
