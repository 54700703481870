import React from 'react';
import Company1 from '../../../../../assets/images/SoftwareDevelopment/SoftwareDevelopmentTab2/projectDetail/Company/Company1.png';
import Company2 from '../../../../../assets/images/SoftwareDevelopment/SoftwareDevelopmentTab2/projectDetail/Company/Company2.png';
import DetailBody from '../../../../../components/SoftwareDevelopmentjPage/SoftwareDevelopmentTab2/project/projectDetail/DetailBody';

const CompanyBody: React.FC = () => {
  return (
    <>
      <DetailBody
        service={'ANDROID / UI UX 디자인'}
        description1={`사용자의 터치만으로 게임이 가능합니다.
        간단한 진행으로 손쉬운 참여를 유도할 수 있습니다.`}
        description2={`각 결과에 맞는 화면이 표시됩니다. 
        다양한 효과와 애니메이션으로 사용자에게 즐거운 경험을 줄 수 있도록 했습니다.`}
        imgSRC1={Company1}
        imgSRC2={Company2}
        imgAlt1="맥키스 컴퍼니"
      />
    </>
  );
};

export default CompanyBody;
