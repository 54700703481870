import React from 'react';
import styled from 'styled-components';
import bg from '../../assets/images/sub_page_background_img.png';
import IntroductionArrow from '../../assets/images/IntroductionArrow.png';
import Arrow from '../Arrow';
import { useNavigate } from 'react-router-dom';
import { AboutUsUrl } from '../../url/LodongUrl';

const IntroductionContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-image: url(${bg});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  color: #fff;
  padding: 50px 20px; // 기본 패딩을 줄여 모바일 화면에 적합하게 조정
  gap: 30px;
  align-items: center;
  justify-content: center;

  & > h1 {
    font-size: 20px; // 모바일 화면에서는 작은 글꼴 크기 적용
    font-weight: 600;
    letter-spacing: 1px;
    text-align: center; // 모바일 화면에서 텍스트 중앙 정렬
    word-break: keep-all;
  }

  @media (min-width: 968px) {
    // 태블릿 및 데스크톱 화면에서 적용할 스타일
    padding: 170px;
    & > h1 {
      font-size: 30px; // 더 큰 화면에서는 글꼴 크기 증가
    }
  }
`;

function IntroductionSection() {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(AboutUsUrl); // 여기서 원하는 URL로 변경하세요.
  };
  return (
    <IntroductionContainer>
      <h1>실패 없는 개발, 소외 없는 사회.</h1>
      <h1>어제 보다 나은 오늘, 오늘 보다 나은 세상.</h1>
      <h1>로동이 함께 걷습니다.</h1>
      <Arrow img={IntroductionArrow} onClick={handleClick} />
    </IntroductionContainer>
  );
}

export default IntroductionSection;
