import React from 'react';
import OF1 from '../../../../../assets/images/SoftwareDevelopment/SoftwareDevelopmentTab2/projectDetail/OF/Onfabric2.png';
import OF2 from '../../../../../assets/images/SoftwareDevelopment/SoftwareDevelopmentTab2/projectDetail/OF/Onfabric3.png';
import DetailBody from '../../../../../components/SoftwareDevelopmentjPage/SoftwareDevelopmentTab2/project/projectDetail/DetailBody';

const OFBody: React.FC = () => {
  return (
    <>
      <DetailBody
        service={'WEB  / 유지보수'}
        description1={`관리자 페이지에서 상품과 게시물을 등록하고 수정을 합니다. `}
        description2={`알고리즘을 통해 접속 통계를 계산하고 그 결과가 보여집니다.
        관리자가 회원정보랑 분류기준도 직접 수정과 등록을 합니다.`}
        imgSRC1={OF1}
        imgSRC2={OF2}
        imgAlt1="onfabric"
      />
    </>
  );
};

export default OFBody;
