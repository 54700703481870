import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

interface ProjectCardProps {
  imageSrc: string;
  projectName: string;
  description: string;
  platforms: string;
  bgColor?: string; // background color prop 추가
  color?: string; // color prop 추가
  bgColorBack?: string; // background color prop 추가
  categoryNames?: string[];
  navigateLink?: string;
}

const ProjectCard: React.FC<ProjectCardProps> = ({
  imageSrc,
  projectName,
  description,
  platforms,
  bgColor = '#cdcdcd', // 기본값으로 #cdcdcd 지정
  color = '#fff', // 기본값으로 #fff 지정
  bgColorBack = '#cdcdcd', // 기본값으로 #cdcdcd 지정
  categoryNames,
  navigateLink = '/',
}) => {
  const [isFlipped, setIsFlipped] = useState(false);

  // 마우스 이벤트 핸들러
  const handleMouseEnter = () => setIsFlipped(true);
  const handleMouseLeave = () => setIsFlipped(false);

  return (
    <Link
      to={navigateLink}
      style={{ textDecoration: 'none', color: 'inherit' }}
    >
      <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
        {isFlipped ? (
          <CardWrapperBack $bgColorBack={bgColorBack}>
            <h4>{projectName}</h4>
            {categoryNames && (
              <CategoryNameBox>
                {categoryNames.map((name, index) => (
                  <span key={index}># {name}</span>
                ))}
              </CategoryNameBox>
            )}
          </CardWrapperBack>
        ) : (
          <CardWrapperFront $bgColor={bgColor} $color={color}>
            <CardContainer>
              <CardImgBox>
                <img src={imageSrc} alt={projectName} />
              </CardImgBox>
              <CardDescBox>
                <ProjectName>{projectName}</ProjectName>
                <Description>{description}</Description>
                <Platforms>{platforms}</Platforms>
              </CardDescBox>
            </CardContainer>
          </CardWrapperFront>
        )}
      </div>
    </Link>
  );
};

const CardWrapperFront = styled.div<{ $bgColor?: string; $color?: string }>`
  box-shadow: 0 5px 10px 5px rgba(0, 0, 0, 0.2); /* 빨간색 은은한 box-shadow */
  background-color: ${(props) => props.$bgColor};
  width: 500px;
  height: 500px;
  padding: 30px 50px;
  display: flex;
  justify-content: center;
  border-radius: 20px;
  color: ${(props) => props.$color};

  @media screen and (max-width: 540px) {
    padding: 30px;
    width: 380px;
    height: 380px;
  }
  @media screen and (max-width: 390px) {
    padding: 30px;
    width: 300px;
    height: 300px;
  }
`;

const CardWrapperBack = styled.div<{ $bgColorBack?: string }>`
  background-color: ${(props) => props.$bgColorBack};
  width: 500px;
  height: 500px;
  padding: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 20px;

  & > h4 {
    color: #fff;
    font-size: 25px;
    letter-spacing: 1.5px;
    margin-bottom: 20px;
  }

  @media screen and (max-width: 540px) {
    padding: 30px;
    width: 380px;
    height: 380px;
    & > h4 {
      font-size: 20px;
    }
  }
  @media screen and (max-width: 390px) {
    padding: 20px;
    width: 300px;
    height: 300px;
  }
`;

const CardContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  gap: 10px;
`;

const CardImgBox = styled.div`
  width: 100%;
  height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 540px) {
    & > img {
      transform: scale(0.9);
      height: 200px;
    }
  }
  @media screen and (max-width: 390px) {
    & > img {
      transform: scale(0.8);
      height: 150px;
    }
  }
`;

const CardDescBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: 15px;
  letter-spacing: 1.5px;

  @media screen and (max-width: 390px) {
    gap: 10px;
  }
`;

const ProjectName = styled.div`
  font-size: 17px;

  @media screen and (max-width: 390px) {
    font-size: 12px;
  }
`;

const Description = styled.div`
  font-size: 20px;
  font-weight: 700;
  letter-spacing: 2px;

  @media screen and (max-width: 540px) {
    font-size: 17px;
  }
  @media screen and (max-width: 390px) {
    font-size: 15px;
  }
`;

const Platforms = styled.div`
  font-size: 15px;

  @media screen and (max-width: 390px) {
    font-size: 12px;
  }
`;

const CategoryNameBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
  color: #fff;

  & > span {
    font-size: 17px;
  }

  @media screen and (max-width: 540px) {
    gap: 10px;
    & > span {
      font-size: 15px;
    }
  }
`;

export default ProjectCard;
