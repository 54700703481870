import React from 'react';
import RUGOLF1 from '../../../../../assets/images/SoftwareDevelopment/SoftwareDevelopmentTab2/projectDetail/RU_GOLF/RUGOLF1.png';
import RUGOLF2 from '../../../../../assets/images/SoftwareDevelopment/SoftwareDevelopmentTab2/projectDetail/RU_GOLF/RUGOLF2.png';
import RUGOLF3 from '../../../../../assets/images/SoftwareDevelopment/SoftwareDevelopmentTab2/projectDetail/RU_GOLF/RUGOLF3.png';
import RUGOLF4 from '../../../../../assets/images/SoftwareDevelopment/SoftwareDevelopmentTab2/projectDetail/RU_GOLF/RUGOLF4.png';
import RUGOLF5 from '../../../../../assets/images/SoftwareDevelopment/SoftwareDevelopmentTab2/projectDetail/RU_GOLF/RUGOLF5.png';
import RUGOLF6 from '../../../../../assets/images/SoftwareDevelopment/SoftwareDevelopmentTab2/projectDetail/RU_GOLF/RUGOLF6.png';
import DetailBody from '../../../../../components/SoftwareDevelopmentjPage/SoftwareDevelopmentTab2/project/projectDetail/DetailBody';

const RuGolfBody: React.FC = () => {
  return (
    <>
      <DetailBody
        service={'ANDROID / UI UX 디자인'}
        service2={'Web / UI UX 디자인'}
        description1={`당일예약으로 진행하며 타석, 시간, 강사별로 
        예약가능한 간편한 앱입니다.`}
        description2={`다양한 강사들에게 레슨을 예약하고`}
        description3={`내정보에서 예약목록들을 확인하고
        레슨과 예약을 관리해보세요.`}
        description4={`예약정보를 웹사트로 불러와 알림이 울리고 카테고리 별로 예약현황을 알 수 있습니다.`}
        description5={`앱에서 예약한 정보를 통해 웹에 알림이 뜹니다.
        웹에서 예약 관리도 함께 할 수 있습니다.`}
        description6={`타석, 시간별로 어떤 고객이 예약했는지 
        정리해주는 페이지를 구현했습니다.`}
        imgSRC1={RUGOLF1}
        imgSRC2={RUGOLF2}
        imgSRC3={RUGOLF3}
        imgSRC4={RUGOLF4}
        imgSRC5={RUGOLF5}
        imgSRC6={RUGOLF6}
        imgAlt1="RU GOLf"
      />
    </>
  );
};

export default RuGolfBody;
