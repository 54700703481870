import React from 'react';
import styled from 'styled-components';
import TitleSection from './AboutUsPageCommon/TitleSection';
import BusinessCard from './AboutUsPageCommon/BusinessCard';
import SellProvideSolutions from '../../assets/images/AboutUs/SellProvideSolutions.png';
import CDN from '../../assets/images/AboutUs/CDN.png';
import SelfService from '../../assets/images/AboutUs/SelfService.png';
import ServerHostingColocation from '../../assets/images/AboutUs/ServerHostingColocation.png';
import SoftwareDevelopment from '../../assets/images/AboutUs/SoftwareDevelopment.png';

const BusinessContainer = styled.div`
  padding: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 40px;
  background-color: #f5f5f5;
  @media (max-width: 768px) {
    padding: 50px 30px;
  }
`;

const Desc = styled.div`
  font-size: 17px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 30px;
  margin-bottom: 50px;
`;

function Business() {
  return (
    <BusinessContainer>
      <TitleSection
        title="Business"
        subtitle="수 많은 경험과 바탕으로 신뢰를 드리는 로동이 되겠습니다. "
      />
      <Desc>
        <BusinessCard title="자체 서비스" imgSrc={SelfService} />
        <BusinessCard
          title="솔루션 판매 & 제공"
          imgSrc={SellProvideSolutions}
        />
        <BusinessCard title="소프트 웨어 개발" imgSrc={SoftwareDevelopment} />
        <BusinessCard
          title="서버 호스팅 & 코로케이션"
          imgSrc={ServerHostingColocation}
        />
        <BusinessCard title="CDN" imgSrc={CDN} />
      </Desc>
    </BusinessContainer>
  );
}

export default Business;
