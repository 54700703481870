import TabComponent from '../../components/TabComponent';
import CommonBanner from '../../components/CommonBanner';
import WebPath from '../../components/WebPath';
import React from 'react';
import SoftwareDevelopmentTabs1 from './SoftwareDevelopmentTabs/SoftwareDevelopmentTabs1';
import SoftwareDevelopmentTabs2 from './SoftwareDevelopmentTabs/SoftwareDevelopmentTabs2';
import Footer from '../../components/Footer';

function SoftwareDevelopmentPage() {
  const tabs = [
    {
      title: '소프트웨어 개발',
      content: <SoftwareDevelopmentTabs1 />,
    },
    {
      title: '포트폴리오',
      content: <SoftwareDevelopmentTabs2 />,
    },
  ];

  return (
    <>
      <CommonBanner subtitle="Lodong Introduction" title="소프트웨어 개발" />
      <WebPath subtitle="소프트웨어 개발" />
      <TabComponent tabs={tabs} />
      <Footer />
    </>
  );
}

export default SoftwareDevelopmentPage;
