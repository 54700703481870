import React from 'react';
import styled from 'styled-components';
import DL360_GEN10 from '../../../assets/images/ServerHosting/ServerHostingTab1/DL360_GEN10.png';
import DL360_GEN9 from '../../../assets/images/ServerHosting/ServerHostingTab1/DL360_GEN9.png';
import DL360_GEN8 from '../../../assets/images/ServerHosting/ServerHostingTab1/DL360_GEN8.png';
import DL380_GEN10 from '../../../assets/images/ServerHosting/ServerHostingTab1/DL380_GEN10.png';
import DL380_GEN9 from '../../../assets/images/ServerHosting/ServerHostingTab1/DL380_GEN9.png';
import DL380_GEN8 from '../../../assets/images/ServerHosting/ServerHostingTab1/DL380_GEN8.png';
import Poweredge_R720xd from '../../../assets/images/ServerHosting/ServerHostingTab1/Poweredge_R720xd.png';
import Poweredge_R730xd from '../../../assets/images/ServerHosting/ServerHostingTab1/Poweredge_R730xd.png';
import ServerCard from './ServerHostingTab1Component/ServerCard';

const SoftwareSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 50px;
`;

const Title = styled.div`
  font-size: 50px;
  font-weight: 700;
  @media (max-width: 1024px) {
    font-size: 40px;
  }
  @media (max-width: 768px) {
    font-size: 30px;
  }
`;

const TitleDescContainer = styled.div`
  font-size: 17px;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;
  letter-spacing: 1px;
  text-align: center;
  word-break: keep-all;

  @media (max-width: 768px) {
    font-size: 16px;
    gap: 20px;
  }
`;

const Desc = styled.div`
  font-size: 17px;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;
  letter-spacing: 1px;
  text-align: center;
  & > p {
    word-break: keep-all;
  }

  @media (max-width: 768px) {
    font-size: 16px;
    gap: 10px;
  }
`;
const ImgContainer = styled.div`
  width: 80%;
  flex-wrap: wrap;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 50px;

  @media (max-width: 768px) {
    width: 100%;
    gap: 20px;
  }
  @media (max-width: 568px) {
    flex-direction: column;
  }
`;

function ServerHostingTab1Section1() {
  return (
    <SoftwareSection>
      <TitleDescContainer>
        <Title>서버 호스팅</Title>
        <Desc>
          <p>국내 프리미엄 회선과 해외 가속 회선으로 제공되는 서버호스팅</p>
        </Desc>
      </TitleDescContainer>
      <ImgContainer>
        <ServerCard
          imageUrl={DL360_GEN8}
          model="DL360 GEN8"
          cpu="8코어 16스레드"
          ram="32GB"
          disk="1T SATA"
          price="150,000원 (월)"
        />
        <ServerCard
          imageUrl={DL380_GEN8}
          model="DL380 GEN8"
          cpu="8코어 16스레드"
          ram="32GB"
          disk="1T SATA"
          price="170,000원 (월)"
        />
        <ServerCard
          imageUrl={DL360_GEN9}
          model="DL360 GEN9"
          cpu="8코어 16스레드"
          ram="32GB"
          disk="1T SATA"
          price="200,000원 (월)"
        />
        <ServerCard
          imageUrl={DL380_GEN9}
          model="DL380 GEN9"
          cpu="8코어 16스레드"
          ram="32GB"
          disk="1T SATA"
          price="220,000원 (월)"
        />
        <ServerCard
          imageUrl={DL360_GEN10}
          model="DL360 GEN10"
          cpu="8코어 16스레드"
          ram="32GB"
          disk="1T SATA"
          price="280,000원 (월)"
        />
        <ServerCard
          imageUrl={DL380_GEN10}
          model="DL380 GEN10"
          cpu="8코어 16스레드"
          ram="32GB"
          disk="1T SATA"
          price="300,000원 (월)"
        />
        <ServerCard
          imageUrl={Poweredge_R720xd}
          model="Poweredge R720xd"
          cpu="8코어 16스레드"
          ram="16GB"
          disk="1T SATA"
          price="160,000원 (월)"
        />
        <ServerCard
          imageUrl={Poweredge_R730xd}
          model="Poweredge R730xd"
          cpu="8코어 16스레드"
          ram="32GB"
          disk="1T SATA"
          price="220,000원 (월)"
        />
      </ImgContainer>
    </SoftwareSection>
  );
}

export default ServerHostingTab1Section1;
