import React from 'react';
import styled from 'styled-components';
import SoftwareCardM from '../../../assets/images/SoftwareDevelopment/SoftwareDevelopmentTab1/DevelopmentProcess/SoftwareCardM.png';
import SoftwareCardPC from '../../../assets/images/SoftwareDevelopment/SoftwareDevelopmentTab1/DevelopmentProcess/SoftwareCardPC.png';

const SoftwareSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 50px;

  @media (max-width: 768px) {
    gap: 30px;
  }
`;

const Title = styled.div`
  font-size: 50px;
  font-weight: 700;
  text-align: center;
  word-break: keep-all;
  @media (max-width: 1024px) {
    font-size: 40px;
  }
  @media (max-width: 768px) {
    font-size: 30px;
  }
`;

const Desc = styled.div`
  font-size: 17px;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;
  letter-spacing: 1px;
  text-align: center;
  & > p {
    word-break: keep-all;
  }

  @media (max-width: 768px) {
    font-size: 16px;
    gap: 10px;
  }
`;

const CardContainerMobile = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  gap: 20px;
`;
const SoftwareCardPCImg = styled.img`
  width: 80%;
  @media (max-width: 968px) {
    display: none;
  }
`;
const SoftwareCardMImg = styled.img`
  width: 70%;
  display: none;
  @media (max-width: 968px) {
    display: block;
  }
  @media (max-width: 568px) {
    width: 80%;
  }
`;

function SoftwareSection2() {
  return (
    <SoftwareSection>
      <Title>소프트웨어 개발 진행과정</Title>
      <CardContainerMobile style={{ marginTop: 30 }}>
        <SoftwareCardPCImg src={SoftwareCardPC} alt="" />
        <SoftwareCardMImg src={SoftwareCardM} alt="" />
      </CardContainerMobile>
      <Desc style={{ marginTop: 20 }}>
        <p>코드의 안정성, 확장성, 유지보수성, 보안을 핵심 가치로 여깁니다.</p>
        <p>
          효과적이고 효율적인 코드 작성을 추구하면서도, 추후 확장성을 고려하여
          설계 단계부터 꼼꼼하게 계획합니다.
        </p>
        <p>
          유지 보수에 유리한 구조와 코드를 작성하고, 주기적인 테스트와 디버깅을
          통해 안정성을 확보합니다.
        </p>
        <p>최신 개발 도구와 기술을 활용하여 품질과 생산성을 향상 시킵니다.</p>
      </Desc>
    </SoftwareSection>
  );
}

export default SoftwareSection2;
