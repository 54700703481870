import React from 'react';
import styled from 'styled-components';
interface DetailBodyProps {
  service: string;
  service2?: string;
  description1?: string;
  description2?: string;
  description3?: string;
  description4?: string;
  description5?: string;
  description6?: string;
  imgSRC1: string;
  imgSRC2?: string;
  imgSRC3?: string;
  imgSRC4?: string;
  imgSRC5?: string;
  imgSRC6?: string;
  imgAlt1?: string;
}

const DetailBody: React.FC<DetailBodyProps> = ({
  service,
  service2,
  description1,
  description2,
  description3,
  description4,
  description5,
  description6,
  imgSRC1,
  imgSRC2,
  imgSRC3,
  imgSRC4,
  imgSRC5,
  imgSRC6,
  imgAlt1,
}) => {
  return (
    <>
      <DetailContainer>
        <Title>{service}</Title>
        {description1 && (
          <AndroidContainer>
            <LeftSection>
              <Description>{description1}</Description>
            </LeftSection>
            <RightSection>
              <img src={imgSRC1} alt={imgAlt1} />
            </RightSection>
          </AndroidContainer>
        )}
        {description2 && (
          <AndroidContainer>
            <RightSection>
              <img src={imgSRC2} alt={imgAlt1} />
            </RightSection>
            <LeftSection>
              <Description>{description2}</Description>
            </LeftSection>
          </AndroidContainer>
        )}
        {description3 && (
          <AndroidContainer>
            <LeftSection>
              <Description>{description3}</Description>
            </LeftSection>
            <RightSection>
              <img src={imgSRC3} alt={imgAlt1} />
            </RightSection>
          </AndroidContainer>
        )}
        {service2 && <Title>{service2}</Title>}
        {description4 && (
          <AndroidContainer>
            <RightSection>
              <img src={imgSRC4} alt={imgAlt1} />
            </RightSection>
            <LeftSection>
              <Description>{description4}</Description>
            </LeftSection>
          </AndroidContainer>
        )}
        {description5 && (
          <AndroidContainer>
            <LeftSection>
              <Description>{description5}</Description>
            </LeftSection>
            <RightSection>
              <img src={imgSRC5} alt={imgAlt1} />
            </RightSection>
          </AndroidContainer>
        )}
        {description6 && (
          <AndroidContainer>
            <RightSection>
              <img src={imgSRC6} alt={imgAlt1} />
            </RightSection>
            <LeftSection>
              <Description>{description6}</Description>
            </LeftSection>
          </AndroidContainer>
        )}
      </DetailContainer>
    </>
  );
};

const DetailContainer = styled.div`
  padding: 50px 100px;

  @media (max-width: 1024px) {
    padding: 50px 20px;
  }
`;

const Title = styled.h1`
  margin-top: 30px;
  font-size: 3rem;
  text-align: center;
  @media (max-width: 768px) {
    font-size: 2rem;
  }
  @media (max-width: 540px) {
    font-size: 20px;
  }
`;

const Description = styled.p`
  font-size: 1.4rem;
  line-height: 1.5;
  padding: 20px 0;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 1024px) {
    font-size: 1.2rem;
  }

  @media (max-width: 540px) {
    font-size: 15px;
  }
`;

const AndroidContainer = styled.div`
  padding: 50px 100px;
  height: 90vh;
  display: flex;
  gap: 50px;
  @media (max-width: 956px) {
    padding: 30px;
  }
  @media (max-width: 786px) {
    flex-direction: column;
    height: auto;
    padding: 0;
    align-items: center;
    gap: 30px;
  }
  @media (max-width: 540px) {
  }
`;

const LeftSection = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 0 80px 0 0;

  @media (max-width: 1024px) {
    padding: 0 40px 0 0;
  }
  @media (max-width: 956px) {
    padding: 0;
  }
  @media (max-width: 786px) {
    padding: 50px;
    width: 100%;
  }
  @media (max-width: 540px) {
    padding: 0px;
    width: 100%;
  }
`;

const RightSection = styled.div`
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  & > img {
    transform: scale(0.8);
  }

  @media (max-width: 1440px) {
    & > img {
      transform: scale(0.7);
    }
  }

  @media (max-width: 1024px) {
    & > img {
      transform: scale(0.5);
    }
  }
  @media (max-width: 540px) {
    width: 100%;
    height: 300px;
    & > img {
      transform: scale(0.3);
    }
  }
`;

export default DetailBody;
