import React from 'react';
import Cellpal1 from '../../../../../assets/images/SoftwareDevelopment/SoftwareDevelopmentTab2/projectDetail/Cellpal/Cellpal1.png';
import Cellpal2 from '../../../../../assets/images/SoftwareDevelopment/SoftwareDevelopmentTab2/projectDetail/Cellpal/Cellpal2.png';
import Cellpal3 from '../../../../../assets/images/SoftwareDevelopment/SoftwareDevelopmentTab2/projectDetail/Cellpal/Cellpal3.png';
import Cellpal4 from '../../../../../assets/images/SoftwareDevelopment/SoftwareDevelopmentTab2/projectDetail/Cellpal/Cellpal4.png';
import DetailBody from '../../../../../components/SoftwareDevelopmentjPage/SoftwareDevelopmentTab2/project/projectDetail/DetailBody';
const CellpalBody: React.FC = () => {
  return (
    <>
      <DetailBody
        service={'WEB'}
        description1={`상품관리 페이지에는 네이버 커머스API를 연동하여 네이버 스토어에 등록이 되어있는 상품목록을 호출하여 상품을 갱신하고 수정과 거래처를 연결할 수 있습니다.`}
        description2={`이메일로 발주서를  전송할 거래처를 등록하고 관리합니다.`}
        description3={`내정보를 보고 수정하는 페이지와 네이버 커머스 API를 사용하기 위한 API키를 등록하고 연동합니다.`}
        description4={`주문수집 페이지는 커머스 API를 연동하여 스토어에 새로 들어온 주문을 수집하고 주문된 상품에 연결된 거래처의 발주서 형식에 따라 발주서를 엑셀로 자동생성합니다. 자동생성된 발주서를 거래처의 담당자 메일로 자동으로 전송이 됩니다.`}
        imgSRC1={Cellpal1}
        imgSRC2={Cellpal2}
        imgSRC3={Cellpal3}
        imgSRC4={Cellpal4}
        imgAlt1="셀팔"
      />
    </>
  );
};

export default CellpalBody;
