import React from 'react';
import styled from 'styled-components';

import Image2 from '../../../../../assets/images/SoftwareDevelopment/SoftwareDevelopmentTab2/projectDetail/Lotto/Lotto2.png';
import Image3 from '../../../../../assets/images/SoftwareDevelopment/SoftwareDevelopmentTab2/projectDetail/Lotto/Lotto3.png';
import Image4 from '../../../../../assets/images/SoftwareDevelopment/SoftwareDevelopmentTab2/projectDetail/Lotto/Lotto4.png';

const DetailContainer = styled.div`
  padding: 5% 10%;

  @media (max-width: 1440px) {
    padding: 5% 5%;
  }
`;

const Title = styled.h1`
  font-size: 3rem;
  text-align: center;

  @media (max-width: 768px) {
    font-size: 2rem;
  }
  @media (max-width: 430px) {
    font-size: 1.5rem;
  }
`;

const AndroidWrapper = styled.div`
  padding: 150px 50px;
  display: flex;
  margin-bottom: 5%;
  gap: 0px 30px;

  @media (max-width: 1440px) {
    padding: 50px 40px;
    margin-bottom: 10%;
  }

  @media (max-width: 1024px) {
    padding: 50px 0px;
    gap: 20px;
  }
  @media (max-width: 768px) {
    padding: 50px 0px;
    gap: 60px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

const LeftSection1 = styled.div`
  width: 50%;
  padding-right: 10%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  h2 {
    font-size: 3rem;
    font-weight: 700;
    line-height: 3;
    width: 100%;
  }

  p {
    font-size: 2rem;
    line-height: 1.5;
    padding: 20px 0;
  }

  @media (max-width: 1920px) {
    padding-top: 3%;
    padding-right: 5%;
    h2 {
      font-size: 2.5rem;
      line-height: 2;
    }

    p {
      font-size: 1.5rem;
    }
  }

  @media (max-width: 1440px) {
    width: 50%;
    padding-right: 0%;
    h2 {
      font-size: 1.7rem;
      margin-top: 0%;
    }

    p {
      font-size: 1.1rem;
      line-height: 1.5;
      letter-spacing: 1.2px;
    }
  }

  @media (max-width: 1024px) {
    padding-top: 5%;

    h2 {
      font-size: 1.7rem;
      margin-top: 0%;
    }

    p {
      font-size: 0.9rem;
      line-height: 1.5;
      letter-spacing: 1.2px;
      padding: 10px 0;
    }
  }
  @media (max-width: 768px) {
    padding-top: 5%;
    width: 100%;
    h2 {
      text-align: center;
      font-size: 1.7rem;
      margin-top: 0%;
    }

    p {
      text-align: center;
      font-size: 0.9rem;
      line-height: 1.5;
      letter-spacing: 1.2px;
      padding: 10px 0;
    }
  }
`;

const RightSection1 = styled.div`
  width: 50%;

  & > img {
    width: 100%;
    position: relative;
    transform: scale(1);
  }

  @media (max-width: 1920px) {
    & > img {
      right: 10%;
    }
  }

  @media (max-width: 1440px) {
    & > img {
      right: 15%;
      top: 5%;
      transform: scale(1);
    }
  }

  @media (max-width: 1024px) {
    & > img {
      width: 100%;
      position: relative;
      right: 15%;
      top: 25%;
      transform: scale(1.2);
    }
  }
`;

const RightSection2 = styled.div`
  width: 50%;
  padding-right: 10%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  h2 {
    font-size: 3rem;
    font-weight: 700;
    line-height: 3;
    width: 100%;
  }

  p {
    font-size: 2rem;
    line-height: 1.5;
    padding: 20px 0;
  }

  @media (max-width: 1920px) {
    padding-top: 3%;
    padding-right: 5%;
    h2 {
      font-size: 2.5rem;
      line-height: 2;
    }

    p {
      font-size: 1.5rem;
    }
  }

  @media (max-width: 1440px) {
    width: 50%;
    padding-right: 0%;
    h2 {
      font-size: 1.7rem;
      margin-top: 0%;
    }

    p {
      font-size: 1.1rem;
      line-height: 1.5;
      letter-spacing: 1.2px;
    }
  }

  @media (max-width: 1024px) {
    padding-top: 5%;

    h2 {
      font-size: 1.7rem;
      margin-top: 0%;
    }

    p {
      font-size: 0.9rem;
      line-height: 1.5;
      letter-spacing: 1.2px;
      padding: 10px 0;
    }
  }

  @media (max-width: 760px) {
    padding-top: 5%;
    width: 100%;
    h2 {
      text-align: center;
      font-size: 1.7rem;
      margin-top: 0%;
    }

    p {
      text-align: center;
      font-size: 0.9rem;
      line-height: 1.5;
      letter-spacing: 1.2px;
      padding: 10px 0;
    }
  }
`;

const LeftSection2 = styled.div`
  width: 50%;

  & > img {
    width: 100%;
    position: relative;
    transform: scale(1);
  }

  @media (max-width: 1920px) {
    & > img {
      left: 10%;
    }
  }

  @media (max-width: 1440px) {
    & > img {
      left: 5%;
      top: 5%;
    }
  }

  @media (max-width: 1024px) {
    & > img {
      width: 100%;
      position: relative;
      left: 15%;
      top: 15%;
      transform: scale(1.2);
    }
  }
`;

const RightSection4 = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: relative;
  gap: 20px;

  & > img {
    width: 80%;
    height: auto;
    display: block;
  }

  p {
    font-size: 2rem;
    line-height: 2.5;
  }

  @media (max-width: 1920px) {
    p {
      font-size: 1.5rem;
      line-height: 2;
    }
  }

  @media (max-width: 1440px) {
    p {
      font-size: 1.1rem;
    }
  }

  @media (max-width: 1024px) {
    & > img {
      width: 100%;
      margin-top: 15%;
      transform: scale(1);
    }
  }
`;

const LottoBody: React.FC = () => {
  return (
    <>
      <DetailContainer>
        <Title>WEB / UI UX 디자인</Title>
        <AndroidWrapper>
          <LeftSection1>
            <h2>설계</h2>
            <p>
              입력한 꿈의 내용에서 키워드를 추출합니다.
              <br />그 키워드에 해당하는 번호를 부여하고
              <br />
              그것이 곳 로또 번호가 됩니다.
            </p>
          </LeftSection1>
          <RightSection1>
            <img src={Image2} alt="Image2" />
          </RightSection1>
        </AndroidWrapper>

        <AndroidWrapper>
          <LeftSection2>
            <img src={Image3} alt="Image2" />
          </LeftSection2>
          <RightSection2>
            <p>
              조합된 번호의 당첨 여부를 바로 확인해보세요.
              <br />
              과거 어떤 번호가 추출되었는지 기록되어있습니다.
            </p>
          </RightSection2>
        </AndroidWrapper>

        <AndroidWrapper>
          <RightSection4>
            <img src={Image4} alt="Image2" />
          </RightSection4>
        </AndroidWrapper>
      </DetailContainer>
    </>
  );
};

export default LottoBody;
