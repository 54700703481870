import React from 'react';
import styled from 'styled-components';
import { phoneNumber } from '../utils/phoneNumber';
import { MdClose } from 'react-icons/md';

// Styled components
const ModalBackdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalView = styled.div`
  width: 387px; // Adjusted to match the image width
  background-color: white;
  border-radius: 15px;
  text-align: center;
`;

const ModalTitleBar = styled.div`
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #e74c3c;
  margin-bottom: 20px;
  color: #fff;
`;

const Title = styled.h2`
  margin: 0;
  font-size: 16px;
`;

const CloseButton = styled.button`
  border: none;
  background-color: transparent;
  font-size: 16px;
  cursor: pointer;
`;

const Message = styled.p`
  font-size: 26px;
  padding: 0 20px;
  margin-top: 10px;
`;

const ConfirmButton = styled.button`
  background-color: #e74c3c;
  border: none;
  padding: 10px 20px;
  font-weight: 700;
  color: #fff;
  border-radius: 999px;
  cursor: pointer;
  margin: 20px 0;
  width: 80%; // To provide sufficient clickable area
  &:hover {
    background-color: #913126;
  }
`;

// Props type definition
interface ModalProps {
  show: boolean;
  onClose: () => void;
}

const Modal: React.FC<ModalProps> = ({ show, onClose }) => {
  if (!show) return null;

  return (
    <ModalBackdrop onClick={onClose}>
      <ModalView onClick={(e) => e.stopPropagation()}>
        <ModalTitleBar>
          <Title>확인 창</Title>
          <CloseButton onClick={onClose}>
            <MdClose style={{ color: 'white' }} />
          </CloseButton>
        </ModalTitleBar>
        <p style={{ fontSize: 18 }}>전화로 문의해주세요!</p>
        <Message style={{ fontWeight: 700 }}>{phoneNumber}</Message>
        <ConfirmButton onClick={onClose}>확인</ConfirmButton>
      </ModalView>
    </ModalBackdrop>
  );
};

export default Modal;
