import React from 'react';

import Footer from '../../../../../components/Footer';

import WashCubeHome from './WashCubeHome';
import WashCubeBody from './WashCubeBody';
import CommonBanner from '../../../../../components/CommonBanner';
import WebPath from '../../../../../components/WebPath';

const WashPage: React.FC = () => {
  return (
    <>
      <CommonBanner subtitle="Development" title="포트폴리오" />
      <WebPath subtitle="포트폴리오" />
      <WashCubeHome />
      <WashCubeBody />
      <Footer />
    </>
  );
};

export default WashPage;
