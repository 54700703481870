import React from 'react';

import Keflavik2 from '../../../../../assets/images/SoftwareDevelopment/SoftwareDevelopmentTab2/projectDetail/Keflavik/Keflavik2.png';
import Keflavik3_1 from '../../../../../assets/images/SoftwareDevelopment/SoftwareDevelopmentTab2/projectDetail/Keflavik/Keflavik3_1.png';
import Keflavik3_2 from '../../../../../assets/images/SoftwareDevelopment/SoftwareDevelopmentTab2/projectDetail/Keflavik/Keflavik3_2.png';
import Keflavik4 from '../../../../../assets/images/SoftwareDevelopment/SoftwareDevelopmentTab2/projectDetail/Keflavik/Keflavik4.png';
import Keflavik5 from '../../../../../assets/images/SoftwareDevelopment/SoftwareDevelopmentTab2/projectDetail/Keflavik/Keflavik5.png';
import DetailBody from '../../../../../components/SoftwareDevelopmentjPage/SoftwareDevelopmentTab2/project/projectDetail/DetailBody';

const KeflavikDetail: React.FC = () => {
  return (
    <>
      <DetailBody
        service={'ANDROID / UI UX 디자인'}
        description1={`대면으로 진행하는 계약과정과 각 시공사의 진행과정을 
        어플을 통해 관리할 수 있도록 설계했습니다. `}
        description2={`날짜, 작업 현황, 시간을 확인하고
        작업 가능한 직원에게 배정할 수 있습니다.`}
        description3={`원하는 작업목록을 생성해
        사진을 업로드할 항목을 선택합니다.`}
        service2={'Web / UI UX 디자인'}
        description5={`날짜별로 담당자를 체크해 
        일정을 한번에 확인하고`}
        description6={`구독 시작, 최근 결제일 등으로 구독자를 관리하고 
        더 나은 서비스를 제안합니다.`}
        imgSRC1={Keflavik2}
        imgSRC2={Keflavik3_1}
        imgSRC3={Keflavik3_2}
        imgSRC5={Keflavik4}
        imgSRC6={Keflavik5}
        imgAlt1="케플라비크"
      />
    </>
  );
};

export default KeflavikDetail;
