import React from 'react';
import TabComponent from '../../components/TabComponent';
import CommonBanner from '../../components/CommonBanner';
import WebPath from '../../components/WebPath';
import Footer from '../../components/Footer';
import CDNServiceTab2 from './cdnServiceTabs/CDNServiceTab2';
import CDNServiceTab1 from './cdnServiceTabs/CDNServiceTab1';

function CDNServicePage() {
  const tabs = [
    {
      title: 'CDN 서비스',
      content: <CDNServiceTab1 />,
    },
    {
      title: '비디오 가속',
      content: <CDNServiceTab2 />,
    },
  ];

  return (
    <>
      <CommonBanner subtitle="CDN Service" title="CDN 서비스" />
      <WebPath subtitle="CDN 서비스" />
      <TabComponent tabs={tabs} />
      <Footer />
    </>
  );
}

export default CDNServicePage;
