import CDNServiceTab2Section2 from '../../../components/cdnService/cdnServiceTab2/CDNServiceTab2Section2';
import CDNServiceTab2Section1 from '../../../components/cdnService/cdnServiceTab2/CDNServiceTab2Section1';
import React from 'react';
import styled from 'styled-components';
import CDNServiceTab2Section3 from '../../../components/cdnService/cdnServiceTab2/CDNServiceTab2Section3';

const CDNServiceTab2Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 20px;
  gap: 80px;
  @media (max-width: 768px) {
    padding-top: 0px;
    gap: 50px;
  }
`;

function CDNServiceTab2() {
  return (
    <CDNServiceTab2Container>
      <CDNServiceTab2Section1 />
      <CDNServiceTab2Section2 />
      <CDNServiceTab2Section3 />
    </CDNServiceTab2Container>
  );
}

export default CDNServiceTab2;
