import React from 'react';

import Footer from '../../../../../components/Footer';
import ShareHome from './ShareHome';
import CommonBanner from '../../../../../components/CommonBanner';
import WebPath from '../../../../../components/WebPath';

const SharePage: React.FC = () => {
  return (
    <>
      <CommonBanner subtitle="Development" title="포트폴리오" />
      <WebPath subtitle="포트폴리오" />
      <ShareHome />
      {/* <CareBodyss /> */}
      <Footer />
    </>
  );
};

export default SharePage;
