import React from 'react';
import DetailHeaderR from '../../../../../components/SoftwareDevelopmentjPage/SoftwareDevelopmentTab2/project/projectDetail/DetailHeaderR';
import Lotto from '../../../../../assets/images/SoftwareDevelopment/SoftwareDevelopmentTab2/projectDetail/Lotto/Lotto1.png';

const LottoHome: React.FC = () => {
  return (
    <>
      <DetailHeaderR
        tagline="인공지능 윤리 설문조사"
        title="내 꿈은 로또 1등"
        description1="그 날 꿨던 꿈을 입력하면 그에 해당하는 로또번호를 추출하는 서비스 입니다."
        description2="과연 오늘 당신의 꿈은 어떤 행운의 번호를 가지고 올까요?"
        hashtags={[
          '인공지능',
          '로또',
          '꿈',
          '키워크 추출',
          '번호 조합',
          'Android',
          '안드로이드',
          'App',
          'Lotto',
          '당첨 여부 확인',
        ]}
        imgSRC={Lotto}
        client="내 꿈은 로또 1등"
        service="Web"
        year="2022"
        duration="1개월 소요"
        textColor="#8F74AB"
        lineColor="#8F74AB"
        textLineColor="#fff"
      />
    </>
  );
};

export default LottoHome;
