import React from 'react';
import WashCube from '../../../../../assets/images/SoftwareDevelopment/SoftwareDevelopmentTab2/projectDetail/WashCube/Wash1.png';
import DetailHeaderL from '../../../../../components/SoftwareDevelopmentjPage/SoftwareDevelopmentTab2/project/projectDetail/DetailHeaderL';

const WashCubeHome: React.FC = () => {
  return (
    <>
      <DetailHeaderL
        tagline="세차장 키오스크 서비스"
        title="WASH CUBE"
        description1="노브러쉬 셀프세차장으로 IT기술을 접목하여 인터넷을 통한"
        description2="무인관리 시스템으로 편하게 세차장을 이용하고 관리할수 있습니다."
        hashtags={[
          '키오스크',
          'Kiosk',
          'RS232',
          'RS485',
          'PLC',
          '결제',
          '셀프 세차',
          '결제 시스템',
          '무인 관리',
          '세차',
          'UI',
          'UX',
        ]}
        imgSRC={WashCube}
        client="WASH CUBE"
        service="KIOSK"
        year="2022"
        duration="2개월 소요"
        textColor="#97CAFF"
        lineColor="#20558C"
        textLineColor="#fff"
      />
    </>
  );
};

export default WashCubeHome;
