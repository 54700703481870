import React from 'react';
import FBMS1 from '../../../../../assets/images/SoftwareDevelopment/SoftwareDevelopmentTab2/projectDetail/FBMS/FBMS1.png';
import FBMS2 from '../../../../../assets/images/SoftwareDevelopment/SoftwareDevelopmentTab2/projectDetail/FBMS/FBMS2.png';
import FBMS4 from '../../../../../assets/images/SoftwareDevelopment/SoftwareDevelopmentTab2/projectDetail/FBMS/FBMS4.png';
import FBMS5 from '../../../../../assets/images/SoftwareDevelopment/SoftwareDevelopmentTab2/projectDetail/FBMS/FBMS5.png';
import FBMS6 from '../../../../../assets/images/SoftwareDevelopment/SoftwareDevelopmentTab2/projectDetail/FBMS/FBMS6.png';
import DetailBody from '../../../../../components/SoftwareDevelopmentjPage/SoftwareDevelopmentTab2/project/projectDetail/DetailBody';

const FBMSBody: React.FC = () => {
  return (
    <>
      <DetailBody
        service={'App UI UX 디자인'}
        service2={'Web UI UX 디자인'}
        description1={`SEBANG의 Battery Management System 앱 대시보드 형태로 이루어져있어, 많은 정보를 보다 쉽고 한눈에 알아볼 수 있습니다.`}
        description2={`이 앱의 핵심 페이지는 이 메인 대시보드 페이지라 생각합니다.
        중요한 정보를 담고 있는 그래프를 한눈에 볼 수 있는 장점을 강조하여 디자인 하였습니다.`}
        description4={`로그인 진행후, 메인 페이지로 넘어가게 됩니다.
        메인 페이지 안에서 많은 정보를 쉽게 볼 수 있도록 디자인하였습니다.`}
        description5={`정보를 깔끔하게 정렬하고 중요한 부분은 포인트 컬러를 사용하여 가독성이 느껴지도록 디자인하였습니다.`}
        description6={`웹과 앱의 차이점은 그래프 디자인에 나타납니다.
        최대한 정보 전달에 포커싱하며 디자인을 진행하였습니다.s`}
        imgSRC1={FBMS1}
        imgSRC2={FBMS2}
        imgSRC4={FBMS4}
        imgSRC5={FBMS5}
        imgSRC6={FBMS6}
        imgAlt1="FBMS"
      />
    </>
  );
};

export default FBMSBody;
