import React from 'react';

import Footer from '../../../../../components/Footer';
import HRDLMSHome from './HRDLMSHome';
import HRDLMSBody from './HRDLMSBody';
import CommonBanner from '../../../../../components/CommonBanner';
import WebPath from '../../../../../components/WebPath';

const HRDLMSPage: React.FC = () => {
  return (
    <>
      <CommonBanner subtitle="Development" title="포트폴리오" />
      <WebPath subtitle="포트폴리오" />
      <HRDLMSHome />
      <HRDLMSBody />
      <Footer />
    </>
  );
};

export default HRDLMSPage;
