import Modal from '../../../../components/Modal';
import React, { useState } from 'react';
import styled from 'styled-components';
import { phoneNumber } from '../../../../utils/phoneNumber';

interface ServiceCardProps {
  title: string;
  unit: string;
  bandwidth: string;
  power: string;
  price: string;
}

const CardContainer = styled.div`
  background: #fff;
  border-radius: 50px;
  padding: 50px;
  box-shadow: 0px 8px 24px 0 rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  width: 300px; /* 카드의 폭 설정 */
  @media (max-width: 968px) {
    width: 90%;
    padding: 50px 30px;
  }
`;

const Title = styled.h2`
  font-weight: 700;
  font-size: 35px;
`;

const Unit = styled.div`
  font-size: 20px;
`;

const Description = styled.div`
  font-size: 16px;
`;

const Price = styled.div`
  font-weight: 700;
  font-size: 24px;
  color: red;
`;

const InquiryButton = styled.button`
  margin-top: 10px;
  border: none;
  outline: 0;
  padding: 8px;
  color: white;
  background-color: #e74c3c;
  border-radius: 999px;
  text-align: center;
  cursor: pointer;
  width: 100%;
  font-size: 18px;
  font-weight: 700;
  &:hover {
    background-color: #913126;
  }
`;

const ColocationCard: React.FC<ServiceCardProps> = ({
  title,
  unit,
  bandwidth,
  power,
  price,
}) => {
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const handleInquiryClick = (): void => {
    const isMobile = /Mobi|Android/i.test(navigator.userAgent);
    if (isMobile) {
      window.location.href = `tel:${phoneNumber}`;
    } else {
      setModalOpen(true); // 모달 열기
    }
  };

  const handleClose = (): void => {
    setModalOpen(false); // 모달 닫기
  };
  return (
    <CardContainer>
      <Title>{title}</Title>
      <Unit>{unit}</Unit>
      <Description>계약 대역폭: {bandwidth}</Description>
      <Description>계약 전력: {power}</Description>
      <Price>{price} (월)</Price>
      <InquiryButton onClick={handleInquiryClick}>문의하기</InquiryButton>
      <Modal show={modalOpen} onClose={handleClose} />
    </CardContainer>
  );
};

export default ColocationCard;
