import React from 'react';
import RCE1 from '../../../../../assets/images/SoftwareDevelopment/SoftwareDevelopmentTab2/projectDetail/RCE/RCE1.png';
import RCE2 from '../../../../../assets/images/SoftwareDevelopment/SoftwareDevelopmentTab2/projectDetail/RCE/RCE2.png';
import RCE3 from '../../../../../assets/images/SoftwareDevelopment/SoftwareDevelopmentTab2/projectDetail/RCE/RCE3.png';
import RCE4 from '../../../../../assets/images/SoftwareDevelopment/SoftwareDevelopmentTab2/projectDetail/RCE/RCE4.png';
import DetailBody from '../../../../../components/SoftwareDevelopmentjPage/SoftwareDevelopmentTab2/project/projectDetail/DetailBody';

const HRDLMSBody: React.FC = () => {
  return (
    <>
      <DetailBody
        service={'WEB'}
        description1={`기관 등록부터 콘텐츠, 강의, 개강, 그리고 훈련생 모집 및 등록 까지 
        all-in -one으로 처리할 수 있도록 CRUD를 구현하였습니다.`}
        description2={`이 프로젝트는 전 세계 분산 서버를 활용한 동영상 스트리밍과 
        CDN 기술로 버퍼링 없는 고화질 시청 경험을 제공합니다. 
        사용자 만족도와 글로벌 접근성을 극대화하며, 
        비용 절감과 보안 강화를 통한 사업 효율성을 증대시킵니다. `}
        description3={`비콘 기술을 활용하여 훈련생들의 출결 상태를 자동으로 기록하고 
        관리하는 시스템을 개발했습니다. 
        이 시스템은 교육청의 요구 사항에 맞춘 엑셀 형식으로 출결 데이터를 자동 추출하여 
        처리 및 관리의 효율성을 크게 향상시켰습니다. 
        기술적 혁신을 통해 교육 관리의 정확성과 편의성을 극대화했습니다.`}
        description4={`본 시스템은 첨단 비콘 기술과 고급 알고리즘을 활용하여 출석 데이터의 
        실시간 모니터링 및 사용자 실수에 대한 직권 수정 기능을 제공합니다. 
        원본 데이터는 보존하되, 수정된 출석 정보는 관리자에 의해 
        엑셀로 분리 추출되어 관리 및 감독의 투명성을 보장합니다. 
        이러한 기술적 접근은 데이터 무결성 유지와 관리 효율성 향상을 동시에 실현합니다.`}
        imgSRC1={RCE1}
        imgSRC2={RCE2}
        imgSRC3={RCE3}
        imgSRC4={RCE4}
        imgAlt1="HRDLMS"
      />
    </>
  );
};

export default HRDLMSBody;
