import React from 'react';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom'; // useLocation 훅을 불러옴

const CenteredContainer = styled.div`
  width: 100%;
  img {
    width: 100%;
  }
`;

// 쿼리 문자열을 파싱하는 함수
const useQuery = () => {
  return new URLSearchParams(useLocation().search); // 쿼리 문자열 파싱
};

const QrPage = () => {
  const query = useQuery(); // 쿼리 파라미터 가져오기
  const url = query.get('url'); // 'url' 쿼리 파라미터 추출

  // 기본 QR 이미지 API 주소

  const baseUrl =
    'https://www.lodong.co.kr/api/qr-Image/file-preview-download?url=';

  return (
    <CenteredContainer>
      {url ? (
        <img src={`${baseUrl}${url}`} alt="QR Code" /> // 파라미터 값을 API URL에 추가
      ) : (
        <p>URL 파라미터가 없습니다.</p> // url이 없을 때 표시할 메시지
      )}
    </CenteredContainer>
  );
};

export default QrPage;
