import React from 'react';
import styled from 'styled-components';
import TitleSection from './AboutUsPageCommon/TitleSection';

const AboutUsContainer = styled.div`
  padding: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
  gap: 40px;
  @media (max-width: 768px) {
    padding: 50px 30px;
  }
`;

const Desc = styled.div`
  font-size: 17px;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;
  margin-bottom: 50px;
  letter-spacing: 1px;
  word-break: keep-all;
  @media (max-width: 768px) {
    font-size: 16px;
    gap: 10px;
  }
`;

function AboutUs() {
  return (
    <AboutUsContainer>
      <TitleSection
        title="About Us"
        subtitle="로동은 개발 회사로서 열정과 노력으로 더 나은 세상을 만들어 갈 것입니다."
      />
      <Desc>
        <p>고객 여러분 안녕하십니까?</p>
        <p>주식회사 로동 대표 조이준입니다.</p>
        <p>홈페이지에 방문해 주셔서 감사합니다.</p>
        <p>
          저희는 실패를 두려워하지 않으며, 성장과 발전의 과정에서도 누구도
          소외되지 않는
        </p>
        <p>사회를 만들어 가는 것이 궁극적인 목표로 나아가고 있습니다.</p>
        <p>이를 위해 우리는 소외된 이웃들과 함께 상생하며 약자를 보호하는,</p>
        <p>더 따뜻하고 인간적인 회사를 지향합니다.</p>
        <p>
          우리는 소프트웨어 개발에 대한 열정과 헌신으로 매 순간 최선을 다하며,
        </p>
        <p>그로 인해 이루어지는 성취감과 보람을 느끼고 있습니다.</p>
        <p>함께 걷는 이 길에서,</p>
        <p>로동은 당신의 곁에 있을 것 입니다.</p>
      </Desc>
    </AboutUsContainer>
  );
}

export default AboutUs;
