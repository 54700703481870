import React from 'react';
import KP1 from '../../../../../assets/images/SoftwareDevelopment/SoftwareDevelopmentTab2/projectDetail/KP/Kp1.png';
import DetailHeaderL from '../../../../../components/SoftwareDevelopmentjPage/SoftwareDevelopmentTab2/project/projectDetail/DetailHeaderL';

const KpHome: React.FC = () => {
  return (
    <>
      <DetailHeaderL
        tagline="인적성 검사 / 상담신청 시스템"
        title="KP 한국 인적성 검사"
        description1="기업과 개인 대상으로 웹페이지 내에서 인적성 검사를 실시합니다."
        description2="그 검사에 대한 결과값을 알고리즘으로 계산을 하여 사용자에서 전달합니다."
        hashtags={[
          'KP',
          '한국',
          '인적성',
          '인적성 검사',
          '검사',
          '연구소',
          '한국 인적성 연구소',
          '유지보수',
          '기능',
          'Web',
          'Web 기능',
        ]}
        imgSRC={KP1}
        client="효동항업 주식회사"
        service="Web"
        year="2023"
        duration="1주일 소요"
        textColor="#fff"
        lineColor="#1F262C"
        textLineColor="#fff"
      />
    </>
  );
};

export default KpHome;
