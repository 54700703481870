import React from 'react';
import styled from 'styled-components';
import subpage_software_img from '../../../assets/images/subpage_software_img.png';

const SoftwareSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 50px;
  & img {
    width: 35%;
  }
  @media (max-width: 768px) {
    gap: 30px;

    & img {
      width: 90%;
    }
  }
`;

const Title = styled.div`
  font-size: 50px;
  font-weight: 700;
  @media (max-width: 1024px) {
    font-size: 40px;
  }
  @media (max-width: 768px) {
    font-size: 30px;
  }
`;

const TitleDescContainer = styled.div`
  font-size: 17px;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;
  letter-spacing: 1px;
  text-align: center;
  word-break: keep-all;

  @media (max-width: 768px) {
    font-size: 16px;
    gap: 10px;
  }
`;

const Desc = styled.div`
  font-size: 17px;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;
  letter-spacing: 1px;
  text-align: center;
  & > p {
    word-break: keep-all;
  }

  @media (max-width: 768px) {
    font-size: 16px;
    gap: 10px;
  }
`;

function SoftwareSection1() {
  return (
    <SoftwareSection>
      <TitleDescContainer>
        <Title>소프트웨어 개발</Title>
        <Desc>
          <p>웹, 앱 등 여러가지 소프트웨어 개발은 라이프 사이클 동안</p>
          <p>변경 사항을 정의, 개발, 테스트, 배포, 관리하는 프로세스입니다.</p>
          <p>소프트웨어 제품의 제작 및 유지보수 또는 시스템이나</p>
          <p>
            제품에 포함된 소프트웨어의 탄생부터 출시, 지속적인 유지보수에
            관여하는 프로세스가 포함됩니다.
          </p>
        </Desc>
      </TitleDescContainer>
      <img src={subpage_software_img} alt="" />
    </SoftwareSection>
  );
}

export default SoftwareSection1;
