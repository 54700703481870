import React, { useState } from 'react';
import styled from 'styled-components';
import Modal from '../../../../components/Modal';
import { phoneNumber } from '../../../../utils/phoneNumber';

type ServerCardProps = {
  imageUrl: string;
  model: string;
  cpu: string;
  ram: string;
  disk: string;
  price: string;
};

const ServerCard: React.FC<ServerCardProps> = ({
  imageUrl,
  model,
  cpu,
  ram,
  disk,
  price,
}) => {
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const handleInquiryClick = (): void => {
    const isMobile = /Mobi|Android/i.test(navigator.userAgent);
    if (isMobile) {
      window.location.href = `tel:${phoneNumber}`;
    } else {
      setModalOpen(true); // 모달 열기
    }
  };

  const handleClose = (): void => {
    setModalOpen(false); // 모달 닫기
  };

  return (
    <Card>
      <ServerImage src={imageUrl} alt="Server" />
      <CardContent>
        <ModelTitle>{model}</ModelTitle>
        <Specs>{cpu}</Specs>
        <Specs>{ram}</Specs>
        <Specs>{disk}</Specs>
        <Price>{price}</Price>
        <InquiryButton onClick={handleInquiryClick}>문의하기</InquiryButton>
        <Modal show={modalOpen} onClose={handleClose} />
      </CardContent>
    </Card>
  );
};

const Card = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-end;
  box-shadow: 0px 8px 24px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  border-radius: 50px;
  width: 300px;
  height: 450px;
  background-color: #fff;
  margin: 10px;
  padding: 20px;
  gap: 40px;
  @media (max-width: 468px) {
    height: 400px;
    gap: 20px;
    width: 90%;
  }
`;

const ServerImage = styled.img`
  padding: 10px;
  width: 100%;
`;

const CardContent = styled.div`
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 8px;
`;

const ModelTitle = styled.h4`
  font-weight: bold;
  font-size: 24px;
`;

const Specs = styled.p`
  margin: 5px 0;
  font-size: 16px;
`;

const Price = styled.p`
  font-weight: 700;
  font-size: 24px;
  color: #e74c3c;
`;

const InquiryButton = styled.button`
  margin-top: 10px;
  border: none;
  outline: 0;
  padding: 8px;
  color: white;
  background-color: #e74c3c;
  border-radius: 999px;
  text-align: center;
  cursor: pointer;
  width: 100%;
  font-size: 18px;
  font-weight: 700;
  &:hover {
    background-color: #913126;
  }
`;

export default ServerCard;
