// src/styles/GlobalStyle.js
import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  body, button {
    margin: 0;
    padding: 0;
    font-family: 'Noto Sans KR', sans-serif; // 원하는 폰트로 변경하세요
  }
`;

export default GlobalStyle;
