import React from 'react';
import styled from 'styled-components';

interface BusinessCardProps {
  imgSrc: string;
  title: string;
}

const BusinessCardContainer = styled.div`
  height: 350px;
  width: 300px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); /* 드롭쉐도우 추가 */
  padding: 20px;
  text-align: center;
  & p {
    word-break: keep-all;
    font-size: 20px;
    font-weight: 700;
  }

  @media (max-width: 768px) {
    width: 100%;
    height: 300px;
  }
`;

const BusinessCard: React.FC<BusinessCardProps> = ({ imgSrc, title }) => {
  return (
    <BusinessCardContainer>
      <img src={imgSrc} alt={title} />
      <p>{title}</p>
    </BusinessCardContainer>
  );
};

export default BusinessCard;
