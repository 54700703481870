import React from 'react';
import styled from 'styled-components';
import WebServerCard from './ServerHostingTab2Component/WebServerCard';
import WebServerCard2 from './ServerHostingTab2Component/WebServerCard2';

const SoftwareSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 50px;
  @media (max-width: 768px) {
    gap: 30px;
  }
`;

const Title = styled.div`
  font-size: 50px;
  font-weight: 700;
  @media (max-width: 1024px) {
    font-size: 40px;
  }
  @media (max-width: 768px) {
    font-size: 30px;
  }
`;

const TitleDescContainer = styled.div`
  font-size: 17px;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;
  letter-spacing: 1px;
  text-align: center;
  word-break: keep-all;

  @media (max-width: 768px) {
    font-size: 16px;
    gap: 20px;
  }
`;

const Desc = styled.div`
  font-size: 17px;
  padding: 0px 20px;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;
  letter-spacing: 1px;
  text-align: center;
  word-break: keep-all;
  & > p {
    word-break: keep-all;
  }

  @media (max-width: 768px) {
    font-size: 16px;
    gap: 10px;
  }
`;
const ServerCardContainer = styled.div`
  width: 100%;
  flex-wrap: wrap;
  display: flex;
  align-items: center;
  justify-content: center;

  gap: 70px;

  @media (max-width: 1440px) {
    width: 100%;
    gap: 30px;
  }
  @media (max-width: 768px) {
    gap: 20px;
  }
`;

function ServerHostingTab2Section1() {
  return (
    <SoftwareSection>
      <TitleDescContainer>
        <Title>웹 호스팅</Title>
        <Desc>
          <p>
            자체 서버를 구축해서 운영하는 것과는 비교할 수 없이 저렴한 비용에
          </p>
          <p>전문 엔지니어의 관리로 보다 안정적인 웹사이트 운영이 가능</p>
        </Desc>
      </TitleDescContainer>

      <ServerCardContainer>
        <WebServerCard
          name="일반"
          desc="소형 사이트 및 커뮤니티 운영 추천"
          cpu="2 vCore"
          memory="10GB"
          disk="20GB (SSD)"
          publicIP="1개"
          traffic="500GB (월)"
          price="25,000원"
        />
        <WebServerCard
          name="비지니스"
          desc="중형 사이트 및 커뮤니티 운영 추천"
          cpu="4 vCore"
          memory="14GB"
          disk="40GB (HDD)"
          publicIP="1개"
          traffic="500GB (월)"
          price="50,000원"
        />
        <WebServerCard
          name="고급"
          desc="중/대형 사이트 및 커뮤니티 운영 추천"
          cpu="6 vCore"
          memory="20GB"
          disk="60GB (HDD)"
          publicIP="1개"
          traffic="500GB (월)"
          price="90,000원"
        />
      </ServerCardContainer>
      <WebServerCard2 />
    </SoftwareSection>
  );
}

export default ServerHostingTab2Section1;
