// src/components/StyledComponents.js
import styled from 'styled-components';

export const DevelopmentLanguageContainer = styled.div`
  font-size: 17px;
  flex-wrap: wrap;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 50px;
  letter-spacing: 1px;
  text-align: center;
  word-break: keep-all;
  background-color: #fff;
  padding: 40px;
  border-radius: 20px;
  box-shadow: 0 0 20px 10px rgba(255, 0, 0, 0.5); /* 빨간색 은은한 box-shadow */

  @media (max-width: 1200px) {
    gap: 40px;
  }

  @media (max-width: 992px) {
    gap: 30px;
  }

  @media (max-width: 768px) {
    gap: 20px;
  }

  @media (max-width: 576px) {
    gap: 10px;
    padding: 20px;
  }
`;
