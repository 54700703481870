import Footer from '../../components/Footer';
import CommonBanner from '../../components/CommonBanner';
import WebPath from '../../components/WebPath';
import React from 'react';
import ColocationSection1 from '../../components/colocation/ColocationSection1/ColocationSection1';
import ColocationSection2 from '../../components/colocation/colocationSection2/ColocationSection2';

function ColocationPage() {
  return (
    <div>
      <CommonBanner subtitle="Colocation" title="코로케이션" />
      <WebPath subtitle="코로케이션" />
      <ColocationSection1 />
      <ColocationSection2 />
      <Footer />
    </div>
  );
}

export default ColocationPage;
