import React from 'react';

import Footer from '../../../../../components/Footer';
import HyodongHome from './HyodongHome';
import HyodongBody from './HyodongBody';
import CommonBanner from '../../../../../components/CommonBanner';
import WebPath from '../../../../../components/WebPath';

const HyodongPage: React.FC = () => {
  return (
    <>
      <CommonBanner subtitle="Development" title="포트폴리오" />
      <WebPath subtitle="포트폴리오" />
      <HyodongHome />
      <HyodongBody />
      <Footer />
    </>
  );
};

export default HyodongPage;
