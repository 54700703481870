import React, { useState, useEffect } from 'react';
import { TiArrowUpThick } from 'react-icons/ti';

const GoToTop: React.FC = () => {
  const [isVisible, setIsVisible] = useState(false);

  const handleScroll = () => {
    const scrollTop = window.scrollY;
    if (scrollTop > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <button
      onClick={scrollToTop}
      style={{
        width: 50,
        height: 50,
        position: 'fixed',
        right: '20px',
        bottom: '20px',
        display: isVisible ? 'block' : 'none',
        padding: '10px',
        fontSize: '16px',
        backgroundColor: 'red',
        color: 'white',
        border: 'none',
        borderRadius: '9999px',
        cursor: 'pointer',
        zIndex: 1000,
      }}
    >
      <TiArrowUpThick style={{ fontSize: 30 }} />
    </button>
  );
};

export default GoToTop;
