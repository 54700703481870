import React from 'react';
import Footer from '../../../../../components/Footer';
import FBMSHome from './FBMSHome';
import FBMSBody from './FBMSBody';
import CommonBanner from '../../../../../components/CommonBanner';
import WebPath from '../../../../../components/WebPath';

const FBMSPage: React.FC = () => {
  return (
    <>
      <CommonBanner subtitle="Development" title="포트폴리오" />
      <WebPath subtitle="포트폴리오" />
      <FBMSHome />
      <FBMSBody />
      <Footer />
    </>
  );
};

export default FBMSPage;
