import React from 'react';
import Share from '../../../../../assets/images/SoftwareDevelopment/SoftwareDevelopmentTab2/projectDetail/Share/Share1.png';
import DetailHeaderL from '../../../../../components/SoftwareDevelopmentjPage/SoftwareDevelopmentTab2/project/projectDetail/DetailHeaderL';

const ShareHome: React.FC = () => {
  return (
    <>
      <DetailHeaderL
        tagline="심리상담 교육센터"
        title="정나눔"
        description1="건강한 정신을 위한 긍정적인 심라상담교육을 중심으로 비포스쿨, 위클래스 프로그램을 운용하고 있는 정나눔의 웹사이트입니다."
        description2="심리검사, 검사도구 구매 등의 기능을 구현하여 정나눔의 미션에 동참하였습니다."
        hashtags={[
          '심리',
          '상담',
          'UI',
          'UX',
          'UI UX',
          'UI UX 디자인',
          'Web',
          '교육',
          '교육센터',
        ]}
        imgSRC={Share}
        client="정나눔"
        service="Web"
        year="-"
        duration="-"
        textColor="#CFD96D"
        lineColor="#CFD96D"
        textLineColor="#000"
      />
    </>
  );
};

export default ShareHome;
