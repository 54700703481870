import React from 'react';

import Image1 from '../../../../../assets/images/SoftwareDevelopment/SoftwareDevelopmentTab2/projectDetail/Keflavik/Keflavik1.png';
import DetailHeaderR from '../../../../../components/SoftwareDevelopmentjPage/SoftwareDevelopmentTab2/project/projectDetail/DetailHeaderR';

const KeflavikHome: React.FC = () => {
  return (
    <>
      <DetailHeaderR
        tagline="시공부터 결제까지 한 번에 해결!"
        title="케플라비크"
        description1="오프라인박람회와 함께 온라인박람회를 개최해 직접 박람회를 방문하지 않고 시공사 정보를 알아볼 수 있습니다."
        description2="종이계약서보다 번거롭지 않고 바로바로 기록이 남는 전자계약서로 편리한 계약시스템을 이용할 수 있습니다."
        hashtags={[
          '채팅',
          '소켓통신',
          'FCM 알람',
          'ERP',
          '문자발송',
          '이메일 발송',
          '견적서 발송',
          '결제기능',
          '서명기능',
          '전자계약서',
          '계약 시스템',
        ]}
        imgSRC={Image1}
        client="케플라비크"
        service="Web / Andriod app"
        year="2022"
        duration="12개월 소요"
        textColor="#FFCF54"
        lineColor="#FFC700"
        textLineColor="#000"
      />
    </>
  );
};

export default KeflavikHome;
