import React from 'react';
import styled from 'styled-components';

import SoftwareSection1 from '../../../components/SoftwareDevelopmentjPage/SoftwareDevelopmentTab1/SoftwareSection1';
import SoftwareSection2 from '../../../components/SoftwareDevelopmentjPage/SoftwareDevelopmentTab1/SoftwareSection2';
import SoftwareSection3 from '../../../components/SoftwareDevelopmentjPage/SoftwareDevelopmentTab1/SoftwareSection3';

const SoftwareDevelopmentTabsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-top: 20px;
  gap: 80px;
  @media (max-width: 768px) {
    padding: 30px 0;
  }
`;

function SoftwareDevelopmentTabs1() {
  return (
    <SoftwareDevelopmentTabsContainer>
      <SoftwareSection1 />
      <SoftwareSection2 />
      <SoftwareSection3 />
    </SoftwareDevelopmentTabsContainer>
  );
}

export default SoftwareDevelopmentTabs1;
