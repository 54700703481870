import { DevelopmentLanguageContainer } from '../../../../assets/css/StyledComponents';
import React from 'react';
import LanguageCard from './LanguageCard';
import node from '../../../../assets/images/SoftwareDevelopment/SoftwareDevelopmentTab1/DevelopmentLanguage/node.png';
import php from '../../../../assets/images/SoftwareDevelopment/SoftwareDevelopmentTab1/DevelopmentLanguage/php.png';
import java from '../../../../assets/images/SoftwareDevelopment/SoftwareDevelopmentTab1/DevelopmentLanguage/java.png';
import python from '../../../../assets/images/SoftwareDevelopment/SoftwareDevelopmentTab1/DevelopmentLanguage/python.png';
import c from '../../../../assets/images/SoftwareDevelopment/SoftwareDevelopmentTab1/DevelopmentLanguage/c.png';
import Cp from '../../../../assets/images/SoftwareDevelopment/SoftwareDevelopmentTab1/DevelopmentLanguage/C++.png';
import Cs from '../../../../assets/images/SoftwareDevelopment/SoftwareDevelopmentTab1/DevelopmentLanguage/C#.png';
import Kotlin from '../../../../assets/images/SoftwareDevelopment/SoftwareDevelopmentTab1/DevelopmentLanguage/Kotlin.png';

function BackEnd() {
  return (
    <DevelopmentLanguageContainer>
      <LanguageCard imgSrc={node} title="node.js" />
      <LanguageCard imgSrc={php} title="php" />
      <LanguageCard imgSrc={java} title="java" />
      <LanguageCard imgSrc={python} title="python" />
      <LanguageCard imgSrc={c} title="c" />
      <LanguageCard imgSrc={Cp} title="c++" />
      <LanguageCard imgSrc={Cs} title="c#" />
      <LanguageCard imgSrc={Kotlin} title="Kotlin" />
    </DevelopmentLanguageContainer>
  );
}

export default BackEnd;
