import React from 'react';

import Footer from '../../../../../components/Footer';
import OFHome from './OFHome';
import OFBody from './OFBody';
import CommonBanner from '../../../../../components/CommonBanner';
import WebPath from '../../../../../components/WebPath';

const OFPage: React.FC = () => {
  return (
    <>
      <CommonBanner subtitle="Development" title="포트폴리오" />
      <WebPath subtitle="포트폴리오" />
      <OFHome />
      <OFBody />
      <Footer />
    </>
  );
};

export default OFPage;
