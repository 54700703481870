export const homeUrl = '/'; //메이페이지
export const AboutUsUrl = '/about-us'; //회사소개
export const SoftwareDevelopmentUrl = '/software-development'; //소프트웨어 개발
export const CDNServiceUrl = '/cdn-service'; //CDN 서비스
export const ServerHostingUrl = '/server'; //서버 호스팅
export const ColocationUrl = '/colocation'; //서버 호스팅
export const BrochureUrl = '/brochure'; //서버 호스팅
export const QRUrl = '/QR'; //서버 호스팅

//프로젝트 디테일화면
export const SoftwareDevelopment_Keflavik_Url =
  '/software-development/projects/keflavik';
export const SoftwareDevelopment_RUGOLF_Url =
  '/software-development/projects/rugolf';
export const SoftwareDevelopment_WashCube_Url =
  '/software-development/projects/washcube';
export const SoftwareDevelopment_Care_Url =
  '/software-development/projects/care';
export const SoftwareDevelopment_Ai_Url = '/software-development/projects/ai';
export const SoftwareDevelopment_Lotto_Url =
  '/software-development/projects/lotto';
export const SoftwareDevelopment_FBMS_Url =
  '/software-development/projects/fbms';
export const SoftwareDevelopment_WelcomeKorea_Url =
  '/software-development/projects/welcome-korea';
export const SoftwareDevelopment_Hyodong_Url =
  '/software-development/projects/hyodong';
export const SoftwareDevelopment_Busan_Url =
  '/software-development/projects/busan';
export const SoftwareDevelopment_Story_Url =
  '/software-development/projects/story';
export const SoftwareDevelopment_Company_Url =
  '/software-development/projects/company';
export const SoftwareDevelopment_RCE_Url = '/software-development/projects/rce';
export const SoftwareDevelopment_HRDLMS_Url =
  '/software-development/projects/hrdlms';
export const SoftwareDevelopment_HRDLMSAPP_Url =
  '/software-development/projects/hrdlms-app';
export const SoftwareDevelopment_Share_Url =
  '/software-development/projects/share';
export const SoftwareDevelopment_Onfabric_Url =
  '/software-development/projects/onfabric';
export const SoftwareDevelopment_KP_Url = '/software-development/projects/kp';
export const SoftwareDevelopment_Cellpal_Url =
  '/software-development/projects/cellpal';
