import React from 'react';
import HRDLMS_App1 from '../../../../../assets/images/SoftwareDevelopment/SoftwareDevelopmentTab2/projectDetail/HRDLMS_APP/HRDLMS-App1.png';
import HRDLMS_App2 from '../../../../../assets/images/SoftwareDevelopment/SoftwareDevelopmentTab2/projectDetail/HRDLMS_APP/HRDLMS-App2.png';
import HRDLMS_App3 from '../../../../../assets/images/SoftwareDevelopment/SoftwareDevelopmentTab2/projectDetail/HRDLMS_APP/HRDLMS-App3.png';
import DetailBody from '../../../../../components/SoftwareDevelopmentjPage/SoftwareDevelopmentTab2/project/projectDetail/DetailBody';
const HRDLMSAPPBody: React.FC = () => {
  return (
    <>
      <DetailBody
        service={'Andriod app / iOS app / UI UX 디자인'}
        description1={`전자출결 다운받고 로그인을 하면 자동으로 사용자의 위치를 
        사용을 동의를 받고 동의가 되면 블루투스로 비콘과 연결이 됩니다.`}
        description2={`수업이 시작하는 시간 10분전부터 
        버튼을 터치하면시간이 자동으로 등록이 됩니다.`}
        description3={`등록이된 시간은 출결현황을 보시면 교시와 시간을 보실수 있습니다.
        사용자가 보고 바로 이해되는 직관적인 UI / UX를 사용하였습니다.`}
        imgSRC1={HRDLMS_App1}
        imgSRC2={HRDLMS_App2}
        imgSRC3={HRDLMS_App3}
        imgAlt1="U 전자출결"
      />
    </>
  );
};

export default HRDLMSAPPBody;
