import React from 'react';
import Company from '../../../../../assets/images/SoftwareDevelopment/SoftwareDevelopmentTab2/projectDetail/Company/company_Dmain.png';
import DetailHeaderR from '../../../../../components/SoftwareDevelopmentjPage/SoftwareDevelopmentTab2/project/projectDetail/DetailHeaderR';
const CompanyHome: React.FC = () => {
  return (
    <>
      <DetailHeaderR
        tagline="주류 판 촉 사다리게임"
        title="맥키스 컴퍼니"
        description1="“이제우린 & 선양” 소주를 콘텐츠로 가벼운 게임을 제작하였습니다."
        hashtags={[
          '동영상',
          '음악',
          'UI',
          'UX',
          'UI  UX',
          '디자인',
          'UI  UX 디자인',
          '게임',
          '이젠우린',
          '선얀',
          '애니메이션',
          '애니메이션 게임',
          '주류',
          '판촉',
          '주류판촉',
        ]}
        imgSRC={Company}
        client="맥키스 컴퍼니"
        service="Android app"
        year="2023"
        duration="1주 소요"
        textColor="#8d8d8d"
        lineColor="#8d8d8d"
        textLineColor="#000"
      />
    </>
  );
};

export default CompanyHome;
