import React from 'react';
import HRDLMS_APP from '../../../../../assets/images/SoftwareDevelopment/SoftwareDevelopmentTab2/projectDetail/HRDLMS_APP/HRDLMS-App_Dmain.png';
import DetailHeaderL from '../../../../../components/SoftwareDevelopmentjPage/SoftwareDevelopmentTab2/project/projectDetail/DetailHeaderL';

const HRDLMSAPPHome: React.FC = () => {
  return (
    <>
      <DetailHeaderL
        tagline="비콘 전자출결 시스템"
        title="U 전자출결"
        description1="HRDLMS웹과 연동이 되어있는 안드로이드 IOS 비콘전자출결 시스템입니다.
        블루투스와 비콘정보를 사용하여 개발을 하였습니다."
        hashtags={[
          'Beacon',
          '비콘',
          '블루투스',
          'Bluetooth',
          '전자출결',
          '출결',
          '위치',
          '위치 기반',
          'Android',
          '안드로이드',
          'iOS',
          'App',
        ]}
        imgSRC={HRDLMS_APP}
        client="U 전자출결"
        service="Android app / iOS / UI UX 디자인"
        year="2024"
        duration="1주일"
        textColor="#fff"
        lineColor="#737373"
      />
    </>
  );
};

export default HRDLMSAPPHome;
