import React from 'react';
import styled from 'styled-components';

// CardContainer 스타일 컴포넌트
const CardContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 15px;
  & > h3 {
    color: #333333;
    font-weight: 700;
    font-size: 24px;

    @media (max-width: 768px) {
      font-size: 20px;
    }

    @media (max-width: 480px) {
      font-size: 16px;
    }
  }
`;

const ResponsiveImage = styled.img`
  width: 100%;
  max-width: 180px;
  height: auto;

  @media (max-width: 768px) {
    max-width: 150px;
  }

  @media (max-width: 480px) {
    max-width: 120px;
  }
`;

interface CardProps {
  imgSrc: string;
  title: string;
}

const LanguageCard: React.FC<CardProps> = ({ imgSrc, title }) => {
  return (
    <CardContainer>
      <ResponsiveImage src={imgSrc} alt="Card Image" />
      <h3>{title}</h3>
    </CardContainer>
  );
};

export default LanguageCard;
