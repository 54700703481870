import React from 'react';

import Footer from '../../../../../components/Footer';
import HRDLMSAPPHome from './HRDLMSAPPHome';
import HRDLMSAPPBody from './HRDLMSAPPBody';
import CommonBanner from '../../../../../components/CommonBanner';
import WebPath from '../../../../../components/WebPath';

const HRDLMSAppPage: React.FC = () => {
  return (
    <>
      <CommonBanner subtitle="Development" title="포트폴리오" />
      <WebPath subtitle="포트폴리오" />
      <HRDLMSAPPHome />
      <HRDLMSAPPBody />
      <Footer />
    </>
  );
};

export default HRDLMSAppPage;
