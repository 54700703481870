import React from 'react';
import Hyodong1 from '../../../../../assets/images/SoftwareDevelopment/SoftwareDevelopmentTab2/projectDetail/Hyodong/Hyodong1.png';
import Hyodong2 from '../../../../../assets/images/SoftwareDevelopment/SoftwareDevelopmentTab2/projectDetail/Hyodong/Hyodong2.png';
import Hyodong3 from '../../../../../assets/images/SoftwareDevelopment/SoftwareDevelopmentTab2/projectDetail/Hyodong/Hyodong3.png';
import Hyodong4 from '../../../../../assets/images/SoftwareDevelopment/SoftwareDevelopmentTab2/projectDetail/Hyodong/Hyodong4.png';
import Hyodong5 from '../../../../../assets/images/SoftwareDevelopment/SoftwareDevelopmentTab2/projectDetail/Hyodong/Hyodong5.png';
import Hyodong6 from '../../../../../assets/images/SoftwareDevelopment/SoftwareDevelopmentTab2/projectDetail/Hyodong/Hyodong6.png';
import DetailBody from '../../../../../components/SoftwareDevelopmentjPage/SoftwareDevelopmentTab2/project/projectDetail/DetailBody';

const HyodongBody: React.FC = () => {
  return (
    <>
      <DetailBody
        service={'ANDROID / UI UX 디자인'}
        service2={'Web / UI UX 디자인'}
        description1={`사용자가 편리하게 로그인 페이지에 불필요한 정보는 제외하고
        필요한 정보만 보이도록 기획을 하고 개발을 하였습니다.`}
        description2={`사용자가 선박 일정에 대한 정보를 수집할 수 있고, 
        보다 더 자세한 정보를 한눈에 볼 수 있도록 하였습니다.`}
        description3={`검사 일정 등, 다른 페이지들에서 일정을 확실하게 알 수 있고 정보를 수집할 수 있습니다.
        레이아웃을 깔끔하게 디자인하여 가독성이 잘 느껴지도록 디자인하였습니다.`}
        description4={`관리자는 보다 편리하게 웹에서 편리하게 사용할수 있게 하였습니다.`}
        description5={`선박일정도 선박별 UI UX가 달라 필요한 정보만 입력할수있게 기획하고
        디자인과 개발을 하였습니다.`}
        description6={`선박일정도 선박별 UI UX가 달라 필요한 정보만 입력할수있게 기획하고
        디자인과 개발을 하였습니다.`}
        imgSRC1={Hyodong1}
        imgSRC2={Hyodong2}
        imgSRC3={Hyodong3}
        imgSRC4={Hyodong4}
        imgSRC5={Hyodong5}
        imgSRC6={Hyodong6}
        imgAlt1="효동"
      />
    </>
  );
};

export default HyodongBody;
