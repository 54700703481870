import React from 'react';
import ResponsiveHeader from './ResponsiveHeader';
import sub_page_background_img from '../assets/images/sub_page_background_img.png';
import styled from 'styled-components';

const HeaderBannerContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-image: url(${sub_page_background_img});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
`;

const BannerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 50px;
  gap: 50px;
  width: 100%;
  color: #fff;
  padding-top: 100px;
  height: 640px;
  @media (max-width: 968px) {
    height: 440px;
    gap: 30px;
    padding: 30px;
  }
`;

const SubtitleTop = styled.p`
  color: #ff2525;
  font-size: 30px;
  text-align: center;
  @media (max-width: 768px) {
    font-size: 20px;
  }
`;

const Title = styled.h1`
  font-size: 60px;
  font-weight: 700;
  text-align: center;
  word-break: keep-all; // Keep words together, break at full words for Korean

  @media (max-width: 1024px) {
    font-size: 60px;
  }

  @media (max-width: 768px) {
    font-size: 40px;
  }
`;

interface CommonBannerProps {
  subtitle: string;
  title: string;
}

const CommonBanner: React.FC<CommonBannerProps> = ({ subtitle, title }) => {
  return (
    <HeaderBannerContainer>
      <ResponsiveHeader />
      <BannerContainer>
        <SubtitleTop>{subtitle}</SubtitleTop>
        <Title>{title}</Title>
      </BannerContainer>
    </HeaderBannerContainer>
  );
};

export default CommonBanner;
