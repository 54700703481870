import React from 'react';

import Footer from '../../../../../components/Footer';
import RCEHome from './RCEHome';
import RCEBody from './RCEBody';
import CommonBanner from '../../../../../components/CommonBanner';
import WebPath from '../../../../../components/WebPath';

const RCEPage: React.FC = () => {
  return (
    <>
      <CommonBanner subtitle="Development" title="포트폴리오" />
      <WebPath subtitle="포트폴리오" />
      <RCEHome />
      <RCEBody />
      <Footer />
    </>
  );
};

export default RCEPage;
