import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import LogoImg from '../assets/images/404E.png';
import { homeUrl } from '../url/LodongUrl';

const CenteredContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  flex-direction: column;
  text-align: center;
  gap: 20px;

  & > img {
    width: 300px;
    margin-bottom: 50px;
    @media (max-width: 768px) {
      width: 200px;
    }
  }
  & > h1 {
    font-weight: 700;
    font-size: 50px;
    margin-bottom: 20px;
    @media (max-width: 768px) {
      font-size: 32px;
      margin-bottom: 10px;
    }
  }
`;

const StyledButton = styled.button`
  padding: 10px 20px;
  font-size: 16px;
  color: white;
  background-color: #007bff; // 부트스트랩 'primary' 색상
  border: none;
  border-radius: 5px;
  cursor: pointer;
  &:hover {
    background-color: #0056b3;
  }
  @media (max-width: 768px) {
    padding: 8px 16px;
    font-size: 14px;
  }
`;

const NotFoundPage = () => {
  const navigate = useNavigate();

  const goToHome = () => {
    navigate(homeUrl); // 메인 페이지 URL로 이동
  };

  return (
    <CenteredContainer>
      <h1>404 Error</h1>
      <img src={LogoImg} alt="로동 로고" />
      <p style={{ fontSize: 20 }}>존재하지 않는 URL 주소 입니다.</p>
      <StyledButton onClick={goToHome}>메인페이지로 가기</StyledButton>
    </CenteredContainer>
  );
};

export default NotFoundPage;
