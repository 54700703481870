import React from 'react';
import styled from 'styled-components';

//이미지
import BusinessPartnerImg from '../assets/images/subpage_partner.jpg';

import KAIST from '../assets/images/businessPartner/KAIST.png';
import Hyodong from '../assets/images/businessPartner/Hyodong.png';
import Keflavik from '../assets/images/businessPartner/Keflavik.png';
import Korea_Electric_Power_Corporation from '../assets/images/businessPartner/Korea_Electric_Power_Corporation.png';
import Seoul_Research_Institute from '../assets/images/businessPartner/Seoul_Research_Institute.png';
import golf from '../assets/images/businessPartner/golf.png';
import intel_pixel from '../assets/images/businessPartner/intel_pixel.png';
import memorial_hall from '../assets/images/businessPartner/memorial_hall.png';
import microtech_system from '../assets/images/businessPartner/microtech_system.png';
import nongshim from '../assets/images/businessPartner/nongshim.png';
import office_of_Education from '../assets/images/businessPartner/office_of_Education.png';
import Brother_community from '../assets/images/businessPartner/Brother_community.png';
import Deep_Insight from '../assets/images/businessPartner/Deep_Insight.png';
import kosmeLogo from '../assets/images/businessPartner/kosmeLogo2.png';
import extenb from '../assets/images/businessPartner/extenb.png';

const BusinessPartnerContainer = styled.div`
  padding: 100px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url(${BusinessPartnerImg}); /* 배경 이미지 위에 어두운 색상 레이어를 추가합니다. */
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
`;

const Title = styled.h2`
  font-size: 50px;
  color: red;
  font-weight: 700;
  @media (max-width: 768px) {
    font-size: 30px;
  }
`;

const DescriptionText = styled.p`
  margin-top: 20px;
  font-size: 17px;
  color: #fff;
  letter-spacing: 1px;
  word-break: keep-all;
  padding: 0px 10px;
  text-align: center;
`;

// Styled Components
const ImgContainer = styled.div`
  margin-top: 50px;
  width: 80%;
  display: flex;
  gap: 50px;
  align-items: center;
  justify-content: space-evenly;
  flex-wrap: wrap;

  & > img {
    width: 200px;
  }

  @media (max-width: 540px) {
    gap: 30px;
    margin-top: 30px;
    & > img {
      width: 120px;
    }
  }

  /* 스크롤바 숨기기 코드 */
  ::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera */
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
`;

function BusinessPartner() {
  return (
    <BusinessPartnerContainer>
      <Title>Business Partner</Title>
      <DescriptionText>다양한 영역에서의 전문성을 제공합니다.</DescriptionText>
      <ImgContainer>
        <img src={KAIST} alt="카이스트" />
        <img src={nongshim} alt="농심" />
        <img src={Korea_Electric_Power_Corporation} alt="한국전력공사" />
        <img src={office_of_Education} alt="경기도 교육청" />
        <img src={intel_pixel} alt="인텔 픽셀" />
        <img src={Seoul_Research_Institute} alt="서울 연구원" />
        <img src={Brother_community} alt="형제커뮤니티" />
        <img src={memorial_hall} alt="매헌 윤봉길의사 기념관" />
        <img src={Deep_Insight} alt="DEEP IN SIGHT" />
        <img src={Hyodong} alt="효동항업주식회사" />
        <img src={Keflavik} alt="케플라비크" />
        <img src={golf} alt="RU GOLF" />
        <img src={microtech_system} alt="마이크로텍 시스템" />
        <img src={extenb} alt="엑스텐비" />
        <img src={kosmeLogo} alt="청년창업사관학교" />
      </ImgContainer>
    </BusinessPartnerContainer>
  );
}

export default BusinessPartner;
