import React from 'react';

import Footer from '../../../../../components/Footer';
import WKoraHome from './WKoraHome';
import WKoraBody from './WKoraBody';
import CommonBanner from '../../../../../components/CommonBanner';
import WebPath from '../../../../../components/WebPath';

const WKoreaPage: React.FC = () => {
  return (
    <>
      <CommonBanner subtitle="Development" title="포트폴리오" />
      <WebPath subtitle="포트폴리오" />
      <WKoraHome />
      <WKoraBody />
      <Footer />
    </>
  );
};

export default WKoreaPage;
