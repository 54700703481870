import React from 'react';
import RCE from '../../../../../assets/images/SoftwareDevelopment/SoftwareDevelopmentTab2/projectDetail/RCE/RCE_Dmain.png';
import DetailHeaderL from '../../../../../components/SoftwareDevelopmentjPage/SoftwareDevelopmentTab2/project/projectDetail/DetailHeaderL';

const RCEHome: React.FC = () => {
  return (
    <>
      <DetailHeaderL
        tagline="중장비 최저가 공급 시스템"
        title="RCE"
        description1="RCE는 한국, 일본, 중국에서 검수된 장비를 최저가에 공급하는 서비스입니다.
        고객이 원하는 정보를, 한눈에 볼 수 있도록 접근성 좋은 웹"
        hashtags={[
          'ERP',
          '알람',
          '노티피케이션',
          'ZOOM',
          'ZOOM 연동',
          '거래',
          '공급',
          'Web',
        ]}
        imgSRC={RCE}
        client="RCE"
        service="Web"
        year="2023"
        duration="2개월 소요"
        textColor="#C3D0FF"
        lineColor="#2A376A"
      />
    </>
  );
};

export default RCEHome;
