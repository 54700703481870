import React from 'react';
import Footer from '../../../../../components/Footer';

import KeflavikHome2 from './KeflavikHome';
import KeflavikDetail2 from './KeflavikDetail';
import CommonBanner from '../../../../../components/CommonBanner';
import WebPath from '../../../../../components/WebPath';

const KeflavikPage: React.FC = () => {
  return (
    <>
      <CommonBanner subtitle="Development" title="포트폴리오" />
      <WebPath subtitle="포트폴리오" />
      <KeflavikHome2 />
      <KeflavikDetail2 />
      <Footer />
    </>
  );
};

export default KeflavikPage;
