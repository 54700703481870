import React, { useState } from 'react';
import styled from 'styled-components';
import cdnP1 from '../../../assets/images/cdnService/cdnServiceTab1/cdnP1.png';
import cdnP2 from '../../../assets/images/cdnService/cdnServiceTab1/cdnP2.png';
import cdnP3 from '../../../assets/images/cdnService/cdnServiceTab1/cdnP3.png';
import Modal from '../../../components/Modal';
import { phoneNumber } from '../../../utils/phoneNumber';

const SoftwareSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 50px;
  padding: 100px 0px;
  background-color: #f5f5f5;
  width: 100%;
  @media (max-width: 768px) {
    padding: 50px;
  }
`;

const Title = styled.div`
  word-break: keep-all;
  font-size: 50px;
  font-weight: 700;
  @media (max-width: 1024px) {
    font-size: 40px;
  }
  @media (max-width: 768px) {
    font-size: 30px;
  }
`;

const TitleDescContainer = styled.div`
  font-size: 17px;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;
  letter-spacing: 1px;
  text-align: center;
  word-break: keep-all;

  @media (max-width: 768px) {
    font-size: 16px;
    gap: 10px;
  }
`;

const ImgContainer = styled.div`
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 50px;
  & > img {
    width: 20%;
    &:hover {
      transform: scale(1.1); // 10% 확대
    }
  }
  @media (max-width: 768px) {
    margin-top: 10px;
    flex-direction: column;
    gap: 20px;
    & > img {
      width: 90%;
      &:hover {
        transform: scale(1.02); // 10% 확대
      }
    }
  }
`;

function CDNServiceTab1Section2() {
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const handleInquiryClick = (): void => {
    const isMobile = /Mobi|Android/i.test(navigator.userAgent);
    if (isMobile) {
      window.location.href = `tel:${phoneNumber}`;
    } else {
      setModalOpen(true); // 모달 열기
    }
  };

  const handleClose = (): void => {
    setModalOpen(false); // 모달 닫기
  };
  return (
    <SoftwareSection>
      <TitleDescContainer>
        <Title>CDN 플랜 안내</Title>
        <ImgContainer>
          <img src={cdnP1} alt="cdn 플랜 Basic" onClick={handleInquiryClick} />
          <img src={cdnP2} alt="cdn 플랜 Pro" onClick={handleInquiryClick} />
          <img
            src={cdnP3}
            alt="cdn 플랜 최저가 약정"
            onClick={handleInquiryClick}
          />
          <Modal show={modalOpen} onClose={handleClose} />
        </ImgContainer>
      </TitleDescContainer>
    </SoftwareSection>
  );
}

export default CDNServiceTab1Section2;
