import React from 'react';
import styled from 'styled-components';
import pad from '../../../assets/images/cdnService/cdnServiceTab2/pad.png';
import phone from '../../../assets/images/cdnService/cdnServiceTab2/phone.png';
import pc from '../../../assets/images/cdnService/cdnServiceTab2/pc.png';
import tv from '../../../assets/images/cdnService/cdnServiceTab2/tv.png';

const SoftwareSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 50px;
`;

const Title = styled.div`
  font-size: 50px;
  font-weight: 700;
  word-break: keep-all;
  @media (max-width: 1024px) {
    font-size: 40px;
  }
  @media (max-width: 768px) {
    font-size: 30px;
  }
  @media (max-width: 568px) {
    font-size: 20px;
  }
`;

const TitleDescContainer = styled.div`
  font-size: 17px;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;
  letter-spacing: 1px;
  text-align: center;

  @media (max-width: 768px) {
    font-size: 16px;
    gap: 10px;
  }
`;

const ImgContainer = styled.div`
  padding: 100px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;

  @media (max-width: 768px) {
    flex-direction: column;
    padding: 0;
    gap: 0px;
  }
`;
const ImgBox = styled.div`
  padding: 100px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  & > img {
    width: 45%;
  }
  @media (max-width: 768px) {
    padding: 0;
    gap: 0px;
  }
`;

function CDNServiceTab2Section2() {
  return (
    <SoftwareSection>
      <TitleDescContainer>
        <Title>다양한 디바이스를 동시에 서비스</Title>
        <ImgContainer>
          <ImgBox>
            <img src={tv} alt="tv" />
            <img src={pc} alt="pc" />
          </ImgBox>
          <ImgBox>
            <img src={pad} alt="pad" />
            <img src={phone} alt="phone" />
          </ImgBox>
        </ImgContainer>
      </TitleDescContainer>
    </SoftwareSection>
  );
}

export default CDNServiceTab2Section2;
