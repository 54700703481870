import React from 'react';
import styled, { keyframes } from 'styled-components';

interface ArrowProps {
  img: string;
  onClick: () => void; // 클릭 이벤트 핸들러를 props로 받습니다.
}

const arrowAnimation = keyframes`
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(20px); /* 오른쪽으로 20px 이동 */
  }
  100% {
    transform: translateX(0);
  }
`;

const ArrowContainer = styled.div`
  margin-top: 20px;
  transition: transform 1s ease-in-out;

  &:hover {
    animation: ${arrowAnimation} 1s ease-in-out infinite; /* 무한 반복 */
  }
`;

function Arrow({ img, onClick }: ArrowProps) {
  return (
    <ArrowContainer onClick={onClick}>
      <img src={img} alt="화살표" />
    </ArrowContainer>
  );
}

export default Arrow;
