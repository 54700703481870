import TabComponent from '../../components/TabComponent';
import CommonBanner from '../../components/CommonBanner';
import WebPath from '../../components/WebPath';
import React from 'react';
import Footer from '../../components/Footer';
import ServerHostingTab1 from './ServerHostingTabs/ServerHostingTab1';
import ServerHostingTab2 from './ServerHostingTabs/ServerHostingTab2';

function ServerHostingPage() {
  const tabs = [
    {
      title: '서버 호스팅',
      content: <ServerHostingTab1 />,
    },
    {
      title: '웹 호스팅',
      content: <ServerHostingTab2 />,
    },
  ];

  return (
    <>
      <CommonBanner subtitle="Hosting" title="서버 호스팅" />
      <WebPath subtitle="서버 호스팅" />
      <TabComponent tabs={tabs} />
      <Footer />
    </>
  );
}

export default ServerHostingPage;
