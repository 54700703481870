import ResponsiveHeader from '../../components/ResponsiveHeader';
import styled, { keyframes } from 'styled-components';

const hoverAnimation = keyframes`
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0);
  }
`;

const HeaderBannerContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  height: 100vh;
  overflow: hidden;
`;

const BackgroundVideo = styled.video`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -2;
  pointer-events: none; /* 비디오에 포인터 이벤트를 무시 */
`;

const DarkOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* 검정색 반투명 오버레이 */
  z-index: -1;
`;

const BannerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 50px;
  gap: 50px;
  width: 100%;
  color: #fff;
  padding-top: 100px;
  height: 100vh;
  @media (max-width: 968px) {
    height: calc(100vh - 120px);
    gap: 30px;
    padding: 30px;
  }
`;

const ScrollIndicator = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 5px;
  position: absolute;
  bottom: 0;
  animation: ${hoverAnimation} 1s ease-in-out infinite;
`;

const ScrollBar = styled.div`
  width: 2px;
  height: 100px;
  background-color: #fff;

  @media (max-width: 768px) {
    height: 50px;
  }
`;

const SubtitleTop = styled.p`
  color: #ff2525;
  font-size: 30px;
  text-align: center;
  @media (max-width: 768px) {
    font-size: 20px;
  }
`;

const Title = styled.h1`
  font-size: 80px;
  font-weight: 700;
  text-align: center;
  word-break: keep-all; // Keep words together, break at full words for Korean

  @media (max-width: 1024px) {
    font-size: 60px;
  }

  @media (max-width: 768px) {
    font-size: 40px;
  }
`;

const SubtitleBottom = styled.p`
  font-weight: 700;
  font-size: 30px;
  margin-bottom: 50px;
  text-align: center; // Center the subtitle text
  @media (max-width: 968px) {
    font-size: 20px;
    margin-bottom: 30px;
  }
`;

const HeaderBanner = () => {
  return (
    <HeaderBannerContainer>
      <BackgroundVideo autoPlay loop muted>
        <source
          src={`${process.env.PUBLIC_URL}/videos/background_video.mp4`}
          type="video/mp4"
        />
        Your browser does not support the video tag.
      </BackgroundVideo>
      <DarkOverlay />
      <ResponsiveHeader />
      <BannerContainer>
        <SubtitleTop>NOTHING IS IMPOSSIBLE KEEP GOING</SubtitleTop>
        <Title>로동이 시작한 이상 실패는 없다</Title>
        <SubtitleBottom>Developing Digital Solutions</SubtitleBottom>
        <ScrollIndicator>
          <p>scroll</p>
          <ScrollBar />
        </ScrollIndicator>
      </BannerContainer>
    </HeaderBannerContainer>
  );
};

export default HeaderBanner;
