import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import service from '../../assets/images/business/service.png';
import solution from '../../assets/images/business/solution.png';
import development from '../../assets/images/business/development.png';
import hosting from '../../assets/images/business/hosting.png';
import CDN from '../../assets/images/business/CDN.png';

const BusinessContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #000;
  padding-bottom: 70px;
  @media (max-width: 768px) {
    padding-bottom: 50px;
  }
`;

const DevelopmentPortfolioContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 100px 200px;
  @media (max-width: 968px) {
    padding: 50px 20px;
    gap: 0px;
  }
`;

const Title = styled.h2`
  font-size: 50px;
  color: red;
  font-weight: 700;
  @media (max-width: 968px) {
    text-align: center;
    margin-bottom: 20px;
  }
  @media (max-width: 768px) {
    font-size: 30px;
  }
`;

const BusinessDescription = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #fff;
  @media (max-width: 968px) {
    flex-direction: column;
    gap: 10px; // Add gap when stacked
  }
`;

const DescriptionText = styled.p`
  word-break: keep-all;
  flex: 1;
  @media (max-width: 968px) {
    text-align: center; // Center text on smaller screens
  }
`;

const ServicesContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 0px;
  scroll-behavior: smooth;
  flex-wrap: wrap;
  overflow-x: visible;
  scroll-snap-type: none;

  @media (max-width: 1024px) {
    flex-wrap: nowrap;
    overflow-x: auto;
    justify-content: flex-start;
    scroll-snap-type: x mandatory;
  }
`;

const ServiceCircle = styled.div<{ isMobile: boolean }>`
  position: relative;
  overflow: hidden;
  min-width: ${(props) => (props.isMobile ? '100%' : '200px')};
  height: 70vh;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  scroll-snap-align: start;
  @media (max-width: 968px) {
    width: 100%; // Adjusted to show 1 image on mobile
  }
`;

const BackgroundImage = styled.div<{ $backgroundImage: string }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  background-image: url(${(props) => props.$backgroundImage});
  transition: transform 0.3s ease;
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    z-index: 1;
  }
`;

const ServiceContent = styled.div`
  z-index: 2;
`;

const ServiceTitle = styled.p`
  z-index: 3;
  font-size: 26px;
  line-height: 35px;
  font-weight: 700;
  color: #fff;
  transition: font-size 0.3s ease;
  @media (max-width: 968px) {
    font-size: 16px;
    line-height: 22px;
  }
`;

const ServiceCircleHover = styled(ServiceCircle)`
  &:hover ${BackgroundImage} {
    transform: scale(1.1);
  }

  &:hover ${ServiceTitle} {
    font-size: 30px;
  }
`;

const serviceData = [
  { title: '자체 서비스', image: service },
  { title: '솔루션 제공 & 백업', image: solution },
  { title: '소프트웨어 개발', image: development },
  { title: '서버 호스팅 & 코로케이션', image: hosting },
  { title: 'CDN', image: CDN },
];

function BusinessSection() {
  const containerRef = useRef<HTMLDivElement>(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (isMobile) {
      const intervalId = setInterval(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % serviceData.length);
      }, 3000); // Adjust interval timing as needed

      return () => clearInterval(intervalId);
    }
  }, [isMobile]);

  const displayedServices = isMobile
    ? [serviceData[currentIndex]]
    : serviceData.slice(0, 5);

  return (
    <BusinessContainer>
      <DevelopmentPortfolioContainer>
        <Title>Business</Title>
        <BusinessDescription>
          <DescriptionText>
            수 많은 경험과 바탕으로 신뢰를 드리는 로동이 되겠습니다.
          </DescriptionText>
        </BusinessDescription>
      </DevelopmentPortfolioContainer>
      <ServicesContainer ref={containerRef}>
        {displayedServices.map((service, index) => (
          <ServiceCircleHover key={index} isMobile={isMobile}>
            <BackgroundImage $backgroundImage={service.image} />
            <ServiceContent>
              <ServiceTitle>{service.title}</ServiceTitle>
            </ServiceContent>
          </ServiceCircleHover>
        ))}
      </ServicesContainer>
    </BusinessContainer>
  );
}

export default BusinessSection;
