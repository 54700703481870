import React from 'react';
import styled from 'styled-components';
import LodongLogo from '../assets/images/LodongLogo.png';

// Styled components for the footer
const StyledFooter = styled.footer`
  background-color: #262626; // Dark background
  color: #ffffff; // White text color
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 12px; // Smaller font size for mobile
  line-height: 1.5;
  padding: 20px;
  text-align: center;

  @media (min-width: 968px) {
    flex-direction: row;
    justify-content: space-evenly;
    padding: 50px;
    text-align: left;
  }
`;

const FooterLeft = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  margin: 20px 0;
  & img {
    width: 150px;
  }

  @media (min-width: 968px) {
    width: 30%;
    margin-left: 30px;
    & img {
      width: 250px;
    }
  }
`;

const FooterRight = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;

  @media (min-width: 968px) {
    width: 70%;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 20px;
    margin-right: 30px;
    font-size: 16px;
  }
`;

const FooterTop = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  font-size: 14px;
  font-weight: 700;

  @media (min-width: 968px) {
    flex-direction: row;
    justify-content: flex-end;
    gap: 50px;
    font-size: 20px;
  }
  @media (min-width: 1440px) {
    gap: 150px;
  }
`;

const FooterLine = styled.div`
  background-color: #ffffff;
  height: 2px;
  width: 80%;

  @media (min-width: 968px) {
    width: 100%;
  }
`;

const FooterMidle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  font-size: 12px;

  @media (min-width: 968px) {
    flex-direction: row;
    justify-content: flex-end;
    gap: 30px;
    font-size: 14px;
  }
`;

const FooterBottom = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  font-size: 12px;
  margin-bottom: 20px;

  @media (min-width: 968px) {
    font-size: 14px;
    flex-direction: row;
    justify-content: flex-end;
    gap: 20px;
  }
`;

// Footer component
const Footer = () => {
  return (
    <StyledFooter>
      <FooterLeft>
        <img src={LodongLogo} alt="Logo" />
      </FooterLeft>
      <FooterRight>
        <FooterTop>
          <span>이용약관</span>
          <span>개인정보처리방침</span>
          <span>문의하기</span>
          <span>공지사항</span>
        </FooterTop>
        <FooterLine></FooterLine>
        <FooterMidle>
          <span>주식회사 로동</span>
          <span style={{ wordBreak: 'keep-all' }}>
            주소 : 경기도 안양시 만안구덕천로 152번길 25 아이에즈비즈타워 B동
            1506호
          </span>
          <span>대표 : 조이준</span>
          <span>사업자 등록번호 : 487-88-02557</span>
        </FooterMidle>
        <FooterBottom>
          <span>TEL : 010-6575-1161 / 031-444-1165</span>
          <span>FAX : 031-460-8553</span>
          <span>Email : cyj@lodong.co.kr</span>
        </FooterBottom>
      </FooterRight>
    </StyledFooter>
  );
};

export default Footer;
