import React from 'react';
import DetailHeaderR from '../../../../../components/SoftwareDevelopmentjPage/SoftwareDevelopmentTab2/project/projectDetail/DetailHeaderR';
import RUGOLF from '../../../../../assets/images/SoftwareDevelopment/SoftwareDevelopmentTab2/projectDetail/RU_GOLF/RUGOLF_Dmain.png';

const RuGOLFHome: React.FC = () => {
  return (
    <>
      <DetailHeaderR
        tagline="시공부터 결제까지 한 번에 해결!"
        title="RU GOLF"
        description1="스크린 골프 아카데이로 당일 예약을 원칙으로 타석별, 강사별로 예약할수 있는 서비스입니다."
        description2="사용자가 원하는 시간에 예약하면 관리자용 웹으로 소리와 함께 알림이 떠 바로 확인이 가능합니다."
        hashtags={[
          '노티피케이션',
          '알람',
          '실시간 확인',
          '실시간 예약',
          '예약 시스템',
          '간편 예약',
          'App 예약',
          '관리자용 웹',
          'UI',
          'UX',
          'UI UX',
        ]}
        imgSRC={RUGOLF}
        client="RU GOLF"
        service="Web / Andriod app"
        year="2022"
        duration="2개월 소요"
        textColor="#8DFCB8"
        lineColor="#084520"
        textLineColor="#fff"
      />
    </>
  );
};

export default RuGOLFHome;
