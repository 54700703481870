import React from 'react';
import Footer from '../../../../../components/Footer';

import RuGolfHome from './RuGolfHome';
import RuGolfBody from './RuGolfBody';
import CommonBanner from '../../../../../components/CommonBanner';
import WebPath from '../../../../../components/WebPath';

const RuGolfPage: React.FC = () => {
  return (
    <>
      <CommonBanner subtitle="Development" title="포트폴리오" />
      <WebPath subtitle="포트폴리오" />
      <RuGolfHome />
      <RuGolfBody />
      <Footer />
    </>
  );
};

export default RuGolfPage;
