import React from 'react';

import ProjectCard from '../../../components/SoftwareDevelopmentjPage/SoftwareDevelopmentTab2/ProjectCard';

//이미지
import Keflavik from '../../../assets/images/SoftwareDevelopment/SoftwareDevelopmentTab2/projectCard/Keflavik.png';
import RUGOLF from '../../../assets/images/SoftwareDevelopment/SoftwareDevelopmentTab2/projectCard/RUGOLF.png';
import Wash from '../../../assets/images/SoftwareDevelopment/SoftwareDevelopmentTab2/projectCard/wash.png';
import Care from '../../../assets/images/SoftwareDevelopment/SoftwareDevelopmentTab2/projectCard/care.png';
import Ai from '../../../assets/images/SoftwareDevelopment/SoftwareDevelopmentTab2/projectCard/Ai.png';
import Lotto from '../../../assets/images/SoftwareDevelopment/SoftwareDevelopmentTab2/projectCard/lotto.png';
import FBMS from '../../../assets/images/SoftwareDevelopment/SoftwareDevelopmentTab2/projectCard/FBMS.png';
import Korea from '../../../assets/images/SoftwareDevelopment/SoftwareDevelopmentTab2/projectCard/korea.png';
import Hyodong from '../../../assets/images/SoftwareDevelopment/SoftwareDevelopmentTab2/projectCard/Hyodong.png';
import Busan from '../../../assets/images/SoftwareDevelopment/SoftwareDevelopmentTab2/projectCard/busan.png';
import Story from '../../../assets/images/SoftwareDevelopment/SoftwareDevelopmentTab2/projectCard/Story.png';
import Company from '../../../assets/images/SoftwareDevelopment/SoftwareDevelopmentTab2/projectCard/company.png';
import RCE from '../../../assets/images/SoftwareDevelopment/SoftwareDevelopmentTab2/projectCard/RCE.png';
import HRDLMS from '../../../assets/images/SoftwareDevelopment/SoftwareDevelopmentTab2/projectCard/HRDLMS.png';
import HRDLMS_App from '../../../assets/images/SoftwareDevelopment/SoftwareDevelopmentTab2/projectCard/HRDLMS-app.png';
import Share from '../../../assets/images/SoftwareDevelopment/SoftwareDevelopmentTab2/projectCard/share.png';
import Onfabric from '../../../assets/images/SoftwareDevelopment/SoftwareDevelopmentTab2/projectCard/Onfabric.png';
import KP from '../../../assets/images/SoftwareDevelopment/SoftwareDevelopmentTab2/projectCard/kp.png';
import Cellpal from '../../../assets/images/SoftwareDevelopment/SoftwareDevelopmentTab2/projectCard/cellpal.png';
import styled from 'styled-components';

import {
  SoftwareDevelopment_HRDLMSAPP_Url,
  SoftwareDevelopment_Cellpal_Url,
  SoftwareDevelopment_KP_Url,
  SoftwareDevelopment_Onfabric_Url,
  SoftwareDevelopment_Share_Url,
  SoftwareDevelopment_HRDLMS_Url,
  SoftwareDevelopment_RCE_Url,
  SoftwareDevelopment_Company_Url,
  SoftwareDevelopment_Story_Url,
  SoftwareDevelopment_Busan_Url,
  SoftwareDevelopment_Hyodong_Url,
  SoftwareDevelopment_WelcomeKorea_Url,
  SoftwareDevelopment_FBMS_Url,
  SoftwareDevelopment_Lotto_Url,
  SoftwareDevelopment_Ai_Url,
  SoftwareDevelopment_Care_Url,
  SoftwareDevelopment_WashCube_Url,
  SoftwareDevelopment_RUGOLF_Url,
  SoftwareDevelopment_Keflavik_Url,
} from '../../../url/LodongUrl';

const projects = [
  {
    imageSrc: Keflavik,
    projectName: '케플라비크',
    description: '시공부터 결제까지 한번에 해결',
    platforms: 'Web / Android app / iOS app',
    bgColor: '#FFB800',
    bgColorBack: '#805C00',
    color: '#000',
    categoryNames: [
      '채팅',
      '소캣통신',
      'FCM 알람',
      'ERP',
      '문자발송',
      '이메일 발송',
      '견적서발송',
      '결제기능',
      '성명기능',
      '전자계약서',
      '계약 시스템',
    ],
    navigateLink: SoftwareDevelopment_Keflavik_Url,
  },
  {
    imageSrc: RUGOLF,
    projectName: 'RU GOLF',
    description: '스크린골프 예약 시스템',
    platforms: 'Web / Android app / iOS app',
    bgColor: '#084520',
    bgColorBack: '#042310',
    categoryNames: [
      '노티피게이션',
      '알람',
      'FCM 알람',
      '실시간 확인',
      '실시간 예약',
      '예약 시스템',
      '간편 예약',
      'App 예약',
      '관리자용 웹',
      'UI',
      'UX',
      'UI  UX',
    ],
    navigateLink: SoftwareDevelopment_RUGOLF_Url,
  },
  {
    imageSrc: Wash,
    projectName: 'Wash Cube',
    description: '셀프 세차장 키오스크 서비스',
    platforms: 'Kiosk',
    bgColor: '#20558C',
    bgColorBack: '#102B46',
    categoryNames: [
      '키오스크',
      'Kiosk',
      'RS232',
      'RS485',
      'PLC',
      '결제',
      '셀프 세차',
      '결제 시스템',
      '무인 관리',
      '세차',
      'UI',
      'UX',
      'UI  UX',
    ],
    navigateLink: SoftwareDevelopment_WashCube_Url,
  },
  {
    imageSrc: Care,
    projectName: '돌봄대장',
    description: '시니어 돌봄 및 생활편의 서비스',
    platforms: 'Web / Android app',
    bgColor: '#0076B5',
    bgColorBack: '#003B5B',
    categoryNames: [
      '상담사 연결',
      '회원관리',
      'ERP',
      '알람',
      '노티피케이션',
      'Android',
      '안드로이드',
      'App',
    ],

    navigateLink: SoftwareDevelopment_Care_Url,
  },

  {
    imageSrc: Ai,
    projectName: 'AI도시를 부탁해!',
    description: '인공지능 윤리 설문조사',
    platforms: 'Web / 유지보수',
    bgColor: '#F6EFE0',
    bgColorBack: '#7B7870',
    color: '#712121',
    categoryNames: [
      '설문',
      '설문조사',
      '인공지능',
      '인공',
      'UI',
      'UX',
      'UI  UX',
      'Web',
      '교육',
      '윤리',
      'AI',
      '유지 보수',
    ],
    navigateLink: SoftwareDevelopment_Ai_Url,
  },
  {
    imageSrc: Lotto,
    projectName: '내 꿈은 로또 1등',
    description: '꿈을 통한 로또 번호 추출',
    platforms: 'Android app',
    bgColor: '#8F74AB',
    bgColorBack: '#483A56',
    categoryNames: [
      '인공지능',
      '로또',
      '꿈',
      '키워크 추출',
      '번호 조합',
      'Android',
      '안드로이드',
      'App',
      'Lotto',
      '당첨 여부 확인',
    ],
    navigateLink: SoftwareDevelopment_Lotto_Url,
  },
  {
    imageSrc: FBMS,
    projectName: 'F-BMS',
    description: '지게차 배터리 관리 시스템',
    platforms: 'UI  / UX 디자인',
    bgColor: '#223139',
    bgColorBack: '#11191D',
    categoryNames: [
      '베터리',
      '지게차',
      'UI',
      'UX',
      'UI  UX',
      '디자인',
      '웹 디자인',
      'UI  UX 디자인',
    ],
    navigateLink: SoftwareDevelopment_FBMS_Url,
  },
  {
    imageSrc: Korea,
    projectName: 'Welcome Korea',
    description: '실용적인 한국생활 가이드',
    platforms: 'Web / Android app / iOS app',
    bgColor: '#3BD2AF',
    bgColorBack: '#1E6958',
    categoryNames: [
      '실시간',
      '위치',
      '공유',
      '실시간 위치',
      '실시간 위치 공유',
      '문자 발송',
      '이메일 발송',
      'UI',
      'UX',
      'UI  UX',
      '디자인',
      '웹 디자인',
      'UI  UX 디자인',
      '관리자용 웹',
    ],
    navigateLink: SoftwareDevelopment_WelcomeKorea_Url,
  },
  {
    imageSrc: Hyodong,
    projectName: '효동항업 주식회사',
    description: '선박 일정 관리 시스템',
    platforms: 'Web / Android app / 유지보수',
    bgColor: '#3A5F96',
    bgColorBack: '#1D304B',
    categoryNames: [
      'ERP',
      '선박',
      '선박일정',
      'FCM 알람',
      '알람',
      '반응형',
      '직관적',
      '유지보수',
      'UI',
      'UX',
      'UI  UX',
      '디자인',
      '웹 디자인',
      'UI  UX 디자인',
      '관리자용 웹',
      'Excel 추출',
      'Excel',
    ],
    navigateLink: SoftwareDevelopment_Hyodong_Url,
  },
  {
    imageSrc: Busan,
    projectName: '공유부산',
    description: '부산 관내 물건 대여 시스템',
    platforms: 'Android app',
    bgColor: '#244299',
    bgColorBack: '#12214D',
    categoryNames: [
      '실시간',
      '위치',
      '공유',
      '실시간 위치',
      '실시간 위치 공유',
      '중고',
      '거래',
      '중고 거래',
      'Android',
      '안드로이드',
      'App',
    ],
    navigateLink: SoftwareDevelopment_Busan_Url,
  },
  {
    imageSrc: Story,
    projectName: '스토리 상품권',
    description: '상품권 사용 시스템',
    platforms: 'Android app',
    bgColor: '#20558C',
    bgColorBack: '#246580',
    categoryNames: [
      '포인트',
      '결제',
      '상품권',
      '교환',
      'Android',
      '안드로이드',
      'App',
    ],
    navigateLink: SoftwareDevelopment_Story_Url,
  },
  {
    imageSrc: Company,
    projectName: '맥키스 컴퍼니',
    description: '주류 판촉 사다리게임',
    platforms: 'Android app',
    bgColor: '#fff',
    bgColorBack: '#808080',
    color: '#000',
    categoryNames: [
      '동영상',
      '음악',
      'UI',
      'UX',
      'UI  UX',
      '디자인',
      'UI  UX 디자인',
      '게임',
      '이젠우린',
      '선얀',
      '애니메이션',
      '애니메이션 게임',
      '주류',
      '판촉',
      '주류판촉',
    ],
    navigateLink: SoftwareDevelopment_Company_Url,
  },
  {
    imageSrc: RCE,
    projectName: 'RCE',
    description: '중장비 최저가 공급 시스템',
    platforms: 'Web',
    bgColor: '#2A376A',
    bgColorBack: '#151C35',
    categoryNames: [
      'ERP',
      '알람',
      '노티피케이션',
      'ZOOM',
      'ZOOM 연동',
      '거래',
      '공급',
      'Web',
    ],
    navigateLink: SoftwareDevelopment_RCE_Url,
  },
  {
    imageSrc: HRDLMS,
    projectName: 'HRDLMS',
    description: 'LMS(학습관리 시스템), ERP',
    platforms: 'Web',
    bgColor: '#B5A396',
    bgColorBack: '#5B524B',
    categoryNames: [
      '실존하는 대부분의 기능',
      'ERP',
      '문자발송',
      '이메일 발송',
      '결제',
      '결제 시스템',
      '통신',
      '전자출결',
      'Excel 추출',
      'Excel',
    ],
    navigateLink: SoftwareDevelopment_HRDLMS_Url,
  },
  {
    imageSrc: HRDLMS_App,
    projectName: 'U 전자출결',
    description: '비콘 전자출결 시스템',
    platforms: 'Android app / iOS app',
    bgColor: '#737373',
    bgColorBack: '#3A3A3A',
    categoryNames: [
      'Beacon',
      '비콘',
      '블루투스',
      'Bluetooth',
      '전자출결',
      '출결',
      '위치',
      '위치 기반',
      'Android',
      '안드로이드',
      'iOS',
      'App',
    ],
    navigateLink: SoftwareDevelopment_HRDLMSAPP_Url,
  },
  {
    imageSrc: Share,
    projectName: '정나눔',
    description: '심리상담 교육센터',
    platforms: 'Web',
    bgColor: '#D0D96D',
    bgColorBack: '#686D37',
    color: '#464E00',
    categoryNames: [
      '심리',
      '상담',
      'UI',
      'UX',
      'UI  UX',
      '디자인',
      'UI  UX 디자인',
      'Web',
      '교육',
      '교육센터',
    ],
    navigateLink: SoftwareDevelopment_Share_Url,
  },

  {
    imageSrc: Onfabric,
    projectName: 'Onfabric',
    description: '원단 수출 시스템',
    platforms: 'Web / 유지보수',
    bgColor: '#EC864B',
    bgColorBack: '#764326',
    categoryNames: [
      '원단',
      '수출',
      '원단 수출',
      'Web',
      '유지보수',
      '기능',
      'Web',
      ' Web 기능',
    ],
    navigateLink: SoftwareDevelopment_Onfabric_Url,
  },
  {
    imageSrc: KP,
    projectName: 'KP 한국 인적성 연구소',
    description: '인적성 검사 / 상담신청 시스템',
    platforms: 'Web / 유지보수',
    bgColor: '#1F262C',
    bgColorBack: '#101316',
    categoryNames: [
      'KP',
      '한국',
      '인적성',
      '인적성 검사',
      '검사',
      '연구소',
      '한국 인적성 연구소',
      '유지보수',
      '기능',
      'Web',
      'Web 기능',
    ],
    navigateLink: SoftwareDevelopment_KP_Url,
  },
  {
    imageSrc: Cellpal,
    projectName: '셀팔',
    description: '스토어 발주 관리 시스템',
    platforms: 'Web',
    bgColor: '#432A8F',
    bgColorBack: '#221548',
    categoryNames: [
      '발주',
      '관리',
      '발주 관리',
      '스토어',
      '네이버 스토어',
      '이메일',
      '이메일 발송',
      'UI',
      'UX',
      'UI  UX',
      '디자인',
      'UI  UX 디자인',
      'Excel 추출',
      'Excel',
      'Web',
    ],
    navigateLink: SoftwareDevelopment_Cellpal_Url,
  },
];
const SoftwareDevelopmentTabs2: React.FC = () => {
  return (
    <ProjectCardContiner>
      {projects.map((project, index) => (
        <ProjectCard
          key={index}
          imageSrc={project.imageSrc}
          projectName={project.projectName}
          description={project.description}
          platforms={project.platforms}
          bgColor={project.bgColor}
          color={project.color}
          categoryNames={project.categoryNames}
          bgColorBack={project.bgColorBack}
          navigateLink={project.navigateLink}
        />
      ))}
    </ProjectCardContiner>
  );
};

const ProjectCardContiner = styled.div`
  margin-top: 50px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 40px;
  padding-bottom: 120px;

  @media screen and (max-width: 1920px) {
    justify-content: space-evenly;
  }
  @media screen and (max-width: 968px) {
    gap: 20px;
    padding-bottom: 50px;
  }
`;

export default SoftwareDevelopmentTabs2;
