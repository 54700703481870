import React from 'react';
import Footer from '../../../../../components/Footer';

import KpHome from './KpHome';
import CommonBanner from '../../../../../components/CommonBanner';
import WebPath from '../../../../../components/WebPath';

const KPPage: React.FC = () => {
  return (
    <>
      <CommonBanner subtitle="Development" title="포트폴리오" />
      <WebPath subtitle="포트폴리오" />
      <KpHome />
      {/* <CareBody /> */}
      <Footer />
    </>
  );
};

export default KPPage;
