import React from 'react';
import styled from 'styled-components';
import RACK from '../../../assets/images/colocation/colocationSection1/RACK.png';
import Frame1U from '../../../assets/images/colocation/colocationSection1/Frame1U.png';
import ColocationCard from './colocationComponent/ColocationCard';

const SoftwareSection = styled.div`
  padding: 100px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  @media (max-width: 768px) {
    padding: 40px 10px;
  }
`;

const Title = styled.div`
  font-size: 50px;
  font-weight: 700;
  word-break: keep-all;
  @media (max-width: 1024px) {
    font-size: 40px;
  }
  @media (max-width: 768px) {
    font-size: 30px;
  }
  @media (max-width: 568px) {
    font-size: 25px;
  }
`;

const TitleDescContainer = styled.div`
  font-size: 17px;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;
  letter-spacing: 1px;
  text-align: center;
  word-break: keep-all;

  @media (max-width: 768px) {
    font-size: 16px;
    gap: 20px;
  }
`;

const Desc = styled.div`
  font-size: 17px;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;
  letter-spacing: 1px;
  text-align: center;
  & > p {
    word-break: keep-all;
  }

  @media (max-width: 768px) {
    font-size: 16px;
    gap: 10px;
  }
`;
const ColocationBox = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 100px 100px 0px 100px;
  width: 100%;

  @media (max-width: 1440px) {
    padding: 50px 50px 0px 50px;
    align-items: center;
  }
  @media (max-width: 768px) {
    padding: 0;
  }
`;
const RackImgContainer = styled.div`
  width: 30%;
  @media (max-width: 1440px) {
    width: 40%;
  }
  @media (max-width: 1024px) {
    display: none;
  }
`;
const RACKImg = styled.img`
  width: 80%;
  @media (max-width: 1800px) {
    width: 100%;
  }
`;

const Frame1UImg = styled.img`
  display: none;
  width: 100%;
  @media (max-width: 1024px) {
    display: block;
    width: 90%;
  }
`;

const ServiceContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 100px;
  padding: 0px 30px 50px 30px;
  width: 70%;

  @media (max-width: 1440px) {
    gap: 50px;
  }
  @media (max-width: 1024px) {
    width: 100%;
  }
  @media (max-width: 768px) {
    width: 100%;
    padding: 50px 0px 0px 0px;
  }
`;
const ServiceTitle = styled.h1`
  width: 100%;
  font-weight: 700;
  font-size: 40px;
  text-align: center;
  word-break: keep-all;

  @media (max-width: 1440px) {
    font-size: 35px;
  }
  @media (max-width: 1024px) {
    font-size: 30px;
  }
  @media (max-width: 768px) {
    font-size: 25px;
  }
`;

const ServiceImgContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  width: 100%;
  gap: 50px;
`;
const VAT = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 0px 50px;
  gap: 8px;
  @media (max-width: 768px) {
    margin-top: 30px;
    align-items: flex-start;
  }
`;

function ColocationSection1() {
  return (
    <SoftwareSection>
      <TitleDescContainer>
        <Title>코로케이션</Title>
        <Desc>
          <p>보유하고 있는 서버를 로동에 위탁한 서비스</p>
          <p>국내 최고 시설의 IDC에 입주하여 안정적인 서비스를 제공</p>
        </Desc>
      </TitleDescContainer>
      <ColocationBox>
        <RackImgContainer>
          <RACKImg src={RACK} alt="RACK" />
        </RackImgContainer>
        <ServiceContainer>
          <ServiceTitle>코로케이션 서비스 제공 내역</ServiceTitle>
          <Frame1UImg src={Frame1U} alt="Frame1U" />
          <ServiceImgContainer>
            <ColocationCard
              title="1U"
              unit="1U 1대"
              bandwidth="10Mbps"
              power="2.5A / 220V"
              price="100,000원"
            />
            <ColocationCard
              title="2U"
              unit="2U 1대"
              bandwidth="10Mbps"
              power="2.5A / 220V"
              price="150,000원"
            />
            <ColocationCard
              title="4U"
              unit="4U 1대"
              bandwidth="10Mbps"
              power="2.5A / 220V"
              price="200,000원"
            />
            <ColocationCard
              title="1/4 RACK"
              unit="1U 6대"
              bandwidth="10Mbps"
              power="2.5A / 220V"
              price="300,000원"
            />
            <ColocationCard
              title="1/2 RACK"
              unit="1U 12대"
              bandwidth="10Mbps"
              power="2.5A / 220V"
              price="600,000원"
            />
            <ColocationCard
              title="FULL RACK"
              unit="1U 24대"
              bandwidth="10Mbps"
              power="2.5A / 220V"
              price="1,200,000원"
            />
          </ServiceImgContainer>
        </ServiceContainer>
      </ColocationBox>
      <VAT>
        <p>*VAT 별도</p>
        <p>*대여폭과 전력은 추가비용이 발생할 수도 있습니다.</p>
      </VAT>
    </SoftwareSection>
  );
}

export default ColocationSection1;
