import React from 'react';
import Footer from '../../../../../components/Footer';
import CellpalHome from './CellpalHome';
import CellpalBody from './CellpalBody';
import CommonBanner from '../../../../../components/CommonBanner';
import WebPath from '../../../../../components/WebPath';

const CellpalPage: React.FC = () => {
  return (
    <>
      <CommonBanner subtitle="Development" title="포트폴리오" />
      <WebPath subtitle="포트폴리오" />
      <CellpalHome />
      <CellpalBody />
      <Footer />
    </>
  );
};

export default CellpalPage;
