import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import v_Service from '../../../assets/images/cdnService/cdnServiceTab2/v_Service.png';
import v_Service_m from '../../../assets/images/cdnService/cdnServiceTab2/v_Service_m.png';

const SoftwareSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 50px;
`;

const Title = styled.div`
  font-size: 50px;
  font-weight: 700;
  word-break: keep-all;

  @media (max-width: 1024px) {
    font-size: 40px;
  }
  @media (max-width: 768px) {
    font-size: 30px;
  }
  @media (max-width: 568px) {
    font-size: 20px;
  }
`;

const TitleDescContainer = styled.div`
  font-size: 17px;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;
  letter-spacing: 1px;
  text-align: center;

  @media (max-width: 768px) {
    font-size: 16px;
    gap: 10px;
  }
`;

const ImgContainer = styled.div`
  padding: 100px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  & > img {
    width: 90%;
  }
  @media (max-width: 768px) {
    font-size: 16px;
    padding: 50px 0;
  }
`;

function CDNServiceTab2Section3() {
  const [currentImage, setCurrentImage] = useState(v_Service);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setCurrentImage(v_Service_m);
      } else {
        setCurrentImage(v_Service);
      }
    };

    window.addEventListener('resize', handleResize);
    // 초기 로딩시 이미지 설정
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <SoftwareSection>
      <TitleDescContainer>
        <Title>서비스 종류</Title>
        <ImgContainer>
          <img src={currentImage} alt="Service Visual" />
        </ImgContainer>
      </TitleDescContainer>
    </SoftwareSection>
  );
}

export default CDNServiceTab2Section3;
