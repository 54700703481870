import React from 'react';
import Care from '../../../../../assets/images/SoftwareDevelopment/SoftwareDevelopmentTab2/projectDetail/Care/Care1.png';
import DetailHeaderR from '../../../../../components/SoftwareDevelopmentjPage/SoftwareDevelopmentTab2/project/projectDetail/DetailHeaderR';

const CareHome: React.FC = () => {
  return (
    <>
      <DetailHeaderR
        tagline="시니어 돌봄 및 생활편의 서비스"
        title="돌봄대장"
        description1="집에 거주하는 부모님이 필요로 하는 모든 서비스를 제공하는 지역사회통합돌봄 서비스입니다. "
        description2="부모님이 살던 곳에서 편안한 노후를 보낼 수 있는 개인 맞춤형 노후지원서비스 돌봄대장 입니다."
        hashtags={[
          '상담사 연결',
          '회원관리',
          'ERP',
          '알람',
          '노티피 케이션',
          'Android',
          '안드로이드',
          'App',
        ]}
        imgSRC={Care}
        client="형제커뮤니티"
        service="Web / Andriod app"
        year="2022"
        duration="1개월 소요"
        textColor="#0076B5"
        lineColor="#0076B5"
        textLineColor="#fff"
      />
    </>
  );
};

export default CareHome;
