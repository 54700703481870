import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import MobileLogo from '../assets/images/LodongLogoMobile.png';
import DesktopLogo from '../assets/images/LodongLogo.png';
import { FaBars, FaGlobe, FaTimes } from 'react-icons/fa';
import { Link, useLocation } from 'react-router-dom';
import {
  AboutUsUrl,
  BrochureUrl,
  CDNServiceUrl,
  ColocationUrl,
  ServerHostingUrl,
  SoftwareDevelopmentUrl,
  homeUrl,
} from '../url/LodongUrl';

interface NavProps {
  datashow: string;
}

const HeaderContainer = styled.header<{ scrolled: string }>`
  position: fixed;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 40px;
  color: #fff;
  background-color: ${(props) =>
    props.scrolled === 'true' ? '#000' : 'transparent'};
  transition: background-color 0.3s ease;
  z-index: 1000;
  @media (max-width: 968px) {
    padding: 15px 25px;
  }
  @media (max-width: 568px) {
    padding: 6px 16px;
  }
`;

const Logo = styled(Link)`
  @media (max-width: 968px) {
    padding: 10px;
  }
`;

const Nav = styled.nav<NavProps>`
  display: flex;
  align-items: center;
  width: 50%;
  gap: 10px;
  @media (max-width: 1440px) {
    width: 70%;
  }
  @media (max-width: 968px) {
    width: calc(100% - 100px);
    flex-direction: column;
    position: fixed;
    top: 0;
    left: 100px;
    bottom: 0;
    padding: 100px 20px;
    display: ${(props) => (props.datashow === 'true' ? 'flex' : 'none')};
    background-color: #262626;
    z-index: 1050;
    justify-content: flex-start;
  }
`;

const NavLink = styled(Link)<{ $active: boolean }>`
  font-size: 20px;
  color: #fff;
  text-decoration: none;
  padding: 20px 0;
  width: 100%;
  text-align: center;
  white-space: nowrap;
  ${({ $active }) =>
    $active &&
    css`
      color: red;
      font-weight: 700;
    `}
  @media (max-width: 968px) {
    border-bottom: 1px solid #333;
  }
`;

const RightContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const LanguageSwitcher = styled.button`
  display: flex;
  gap: 8px;
  align-items: center;
  background: none;
  border: none;
  color: #fff;
  cursor: pointer;
  font-size: 16px;
  display: flex;
  align-items: center;
  @media (max-width: 968px) {
    margin-top: auto;
  }
`;

const MenuButton = styled.button`
  background: none;
  border: none;
  display: none;
  align-items: center;
  font-size: 24px;
  cursor: pointer;
  @media (max-width: 968px) {
    display: flex;
  }
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  color: #fff;
  font-size: 24px;
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 20px;
  display: none;
  @media (max-width: 968px) {
    display: flex;
  }
`;

const Overlay = styled.div<{ datashow: string }>`
  display: none;
  @media (max-width: 968px) {
    display: ${(props) => (props.datashow === 'true' ? 'block' : 'none')};
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1040;
  }
`;

const BrochureLink = styled(Link)`
  color: #fff;
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: 8px;
`;

const ResponsiveHeader: React.FC = () => {
  const [showMenu, setShowMenu] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const location = useLocation();

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  const handleNavLinkClick = (to: string) => {
    if (location.pathname === to) {
      window.scrollTo(0, 0);
    }
    setShowMenu(false);
  };

  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 100;
      setScrolled(isScrolled);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const isActive = (path: string) => location.pathname === path;

  return (
    <>
      <HeaderContainer scrolled={scrolled.toString()}>
        <Logo to={homeUrl}>
          <img
            src={window.innerWidth <= 968 ? MobileLogo : DesktopLogo}
            alt="Logo"
            height={window.innerWidth <= 968 ? 50 : 80}
          />
        </Logo>
        <Overlay datashow={showMenu.toString()} onClick={toggleMenu} />
        <Nav datashow={showMenu.toString()}>
          <CloseButton onClick={toggleMenu}>
            <FaTimes />
          </CloseButton>
          <NavLink
            to={AboutUsUrl}
            $active={isActive(AboutUsUrl)}
            onClick={() => handleNavLinkClick(AboutUsUrl)}
          >
            회사소개
          </NavLink>
          <NavLink
            to={SoftwareDevelopmentUrl}
            $active={isActive(SoftwareDevelopmentUrl)}
            onClick={() => handleNavLinkClick(SoftwareDevelopmentUrl)}
          >
            소프트웨어 개발
          </NavLink>
          <NavLink
            to={CDNServiceUrl}
            $active={isActive(CDNServiceUrl)}
            onClick={() => handleNavLinkClick(CDNServiceUrl)}
          >
            CDN 서비스
          </NavLink>
          <NavLink
            to={ServerHostingUrl}
            $active={isActive(ServerHostingUrl)}
            onClick={() => handleNavLinkClick(ServerHostingUrl)}
          >
            서버 호스팅
          </NavLink>
          <NavLink
            to={ColocationUrl}
            $active={isActive(ColocationUrl)}
            onClick={() => handleNavLinkClick(ColocationUrl)}
          >
            코로케이션
          </NavLink>
        </Nav>
        <RightContainer>
          <LanguageSwitcher>
            <BrochureLink to={BrochureUrl}>회사소개서</BrochureLink>
            |
            <FaGlobe />
            <span>KOR</span>
          </LanguageSwitcher>
          <MenuButton onClick={toggleMenu}>
            <FaBars color="#fff" style={{ fontSize: '24px' }} />
          </MenuButton>
        </RightContainer>
      </HeaderContainer>
    </>
  );
};

export default ResponsiveHeader;
