import React from 'react';
import WKorea1 from '../../../../../assets/images/SoftwareDevelopment/SoftwareDevelopmentTab2/projectDetail/WKorea/korea_1.png';
import WKorea2 from '../../../../../assets/images/SoftwareDevelopment/SoftwareDevelopmentTab2/projectDetail/WKorea/korea_2.png';
import WKorea3 from '../../../../../assets/images/SoftwareDevelopment/SoftwareDevelopmentTab2/projectDetail/WKorea/korea_3.png';
import WKorea4 from '../../../../../assets/images/SoftwareDevelopment/SoftwareDevelopmentTab2/projectDetail/WKorea/korea_4.png';
import WKorea5 from '../../../../../assets/images/SoftwareDevelopment/SoftwareDevelopmentTab2/projectDetail/WKorea/korea_5.png';
import DetailBody from '../../../../../components/SoftwareDevelopmentjPage/SoftwareDevelopmentTab2/project/projectDetail/DetailBody';

const WKoraBody: React.FC = () => {
  return (
    <>
      <DetailBody
        service={'ANDROID / iOS / UI UX 디자인'}
        service2={`Web / UI UX 디자인`}
        description1={`언어 선택은 물론,
        혼자서도 의·식·주 를 해결할 수 있도록!!단순 번역 서비스를 넘어,
        외국인을 위한 생활에 필요할 서비스를 준비했습니다.`}
        description2={`마트는 멀고, 냉장고는 비었다면?
        마켓을 통해 필요한 물품을 손쉽게 주문해 보세요.`}
        description3={`색재료가 아니더라도, 맛집의 요리를 집으로
        받아볼 수 있습니다.`}
        description4={`관리자용으로 부동산과 마켓등
        기록등 관리하는 웹페이지 입니다. `}
        description5={`사용자가 편리하게 관리할수있게 간편한 UI로
        디자인을 하고 필요한 기능들을 추가하였습니다.`}
        imgSRC1={WKorea1}
        imgSRC2={WKorea2}
        imgSRC3={WKorea3}
        imgSRC4={WKorea4}
        imgSRC5={WKorea5}
        imgAlt1="Welcome Korea"
      />
    </>
  );
};

export default WKoraBody;
