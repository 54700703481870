import React from 'react';
import RCE1 from '../../../../../assets/images/SoftwareDevelopment/SoftwareDevelopmentTab2/projectDetail/RCE/RCE1.png';
import RCE2 from '../../../../../assets/images/SoftwareDevelopment/SoftwareDevelopmentTab2/projectDetail/RCE/RCE2.png';
import RCE3 from '../../../../../assets/images/SoftwareDevelopment/SoftwareDevelopmentTab2/projectDetail/RCE/RCE3.png';
import RCE4 from '../../../../../assets/images/SoftwareDevelopment/SoftwareDevelopmentTab2/projectDetail/RCE/RCE4.png';
import DetailBody from '../../../../../components/SoftwareDevelopmentjPage/SoftwareDevelopmentTab2/project/projectDetail/DetailBody';

const RCEBody: React.FC = () => {
  return (
    <>
      <DetailBody
        service={'WEB  / UI UX 디자인'}
        description1={`사용자가 편리하게 디자인과 필요한 정보를 한눈에 볼수있도록,
        접근성이 좋게 기획을 하였습니다.`}
        description2={`합리적인 가격과
        어떤 고객에게도 다가갈 수 있도록다국어 서비스를 지원합니다.`}
        description3={`상담을 신청하시면 알림서비스가 있어 관리자가 편리하게 확인이 가능합니다.
        ZOOM이 연동이 되어있어 화상상담도 가능합니다.`}
        description4={`구매 후에도 주문 정보, 결제 정보, 배송 진행 상황까지.
        고객이 필요한 정보를 한곳에서 볼 수 있도록 합니다`}
        imgSRC1={RCE1}
        imgSRC2={RCE2}
        imgSRC3={RCE3}
        imgSRC4={RCE4}
        imgAlt1="RCE"
      />
    </>
  );
};

export default RCEBody;
