import React from 'react';
import styled from 'styled-components';
import ServerHostingTab2Section1 from '../../../components/serverHosting/ServerHostingTab2/ServerHostingTab1Section1';

const ServerHostingTab1Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 20px;
  padding-bottom: 100px;
  gap: 80px;
  @media (max-width: 768px) {
    padding-top: 30px;
    padding-bottom: 50px;
    gap: 30px;
  }
`;

function ServerHostingTab2() {
  return (
    <ServerHostingTab1Container>
      <ServerHostingTab2Section1 />
    </ServerHostingTab1Container>
  );
}

export default ServerHostingTab2;
