import React from 'react';
import styled from 'styled-components';
import v1 from '../../../assets/images/cdnService/cdnServiceTab2/v1.png';
import v2 from '../../../assets/images/cdnService/cdnServiceTab2/v2.png';
import v3 from '../../../assets/images/cdnService/cdnServiceTab2/v3.png';
import v4 from '../../../assets/images/cdnService/cdnServiceTab2/v4.png';
import v5 from '../../../assets/images/cdnService/cdnServiceTab2/v5.png';

const SoftwareSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 50px;
`;

const Title = styled.div`
  font-size: 50px;
  font-weight: 700;
  word-break: keep-all;
  @media (max-width: 1024px) {
    font-size: 40px;
  }
  @media (max-width: 768px) {
    font-size: 30px;
  }
  @media (max-width: 568px) {
    font-size: 25px;
  }
`;

const TitleDescContainer = styled.div`
  font-size: 17px;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;
  letter-spacing: 1px;
  text-align: center;
  word-break: keep-all;

  @media (max-width: 768px) {
    font-size: 16px;
    gap: 20px;
  }
`;

const Desc = styled.div`
  font-size: 17px;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;
  letter-spacing: 1px;
  text-align: center;
  & > p {
    word-break: keep-all;
  }

  @media (max-width: 768px) {
    font-size: 16px;
    gap: 10px;
  }
`;
const ImgContainer = styled.div`
  padding: 100px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  @media (max-width: 768px) {
    flex-direction: column;
    padding: 0;
    gap: 0px;
  }
`;
const ImgBox = styled.div`
  padding: 100px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
  & > img {
    width: 15%;
  }
  @media (max-width: 768px) {
    padding: 0;
    gap: 0px;
    & > img {
      width: 30%;
    }
  }
`;

function CDNServiceTab2Section1() {
  return (
    <SoftwareSection>
      <TitleDescContainer>
        <Title>비디오 가속</Title>
        <Desc>
          <p>
            동영상 플랫폼을 통해 전세계에서 동영상 시청에 있어 최적의 시청
            환경을 제공하기 위한 최적의 솔루션을 제공하고 있습니다.
          </p>
          <p>로동은 전세계에서 가장 빠르고 안전한 네트워크를 활용하여</p>
          <p>
            전세계 시청자의 모든 스크린과 기기로 동영상을 서비스 할 수 있는
            솔루션을 제공합니다.
          </p>
        </Desc>
      </TitleDescContainer>

      <ImgContainer>
        <ImgBox>
          <img src={v1} alt="tv" />
          <img src={v2} alt="pc" />
          <img src={v3} alt="pc" />
          <img src={v4} alt="pad" />
          <img src={v5} alt="phone" />
        </ImgBox>
      </ImgContainer>
    </SoftwareSection>
  );
}

export default CDNServiceTab2Section1;
