import React from 'react';

import Footer from '../../../../../components/Footer';

import LottoHome from './LottoHome';
import LottoBody from './LottoBody';
import WebPath from '../../../../../components/WebPath';
import CommonBanner from '../../../../../components/CommonBanner';

const LottoPage: React.FC = () => {
  return (
    <>
      <CommonBanner subtitle="Development" title="포트폴리오" />
      <WebPath subtitle="포트폴리오" />
      <LottoHome />
      <LottoBody />
      <Footer />
    </>
  );
};

export default LottoPage;
