import React from 'react';
import Busan from '../../../../../assets/images/SoftwareDevelopment/SoftwareDevelopmentTab2/projectDetail/Busan/Busan_Dmain.png';
import DetailHeaderL from '../../../../../components/SoftwareDevelopmentjPage/SoftwareDevelopmentTab2/project/projectDetail/DetailHeaderL';

const BusanHome: React.FC = () => {
  return (
    <>
      <DetailHeaderL
        tagline="부산 관내 물건 대여 시스템"
        title="공유부산"
        description1="필요한 물건이 있는데 며칠, 잠깐만 필요할 때. 구매하기는 아까울 때,물건을 가지고 있는 사람에게 필요한 만큼 빌리는 서비스입니다."
        hashtags={[
          '실시간',
          '위치',
          '공유',
          '실시간 위치',
          '실시간 위치 공유',
          '중고',
          '거래',
          '중고 거래',
          'Android',
          '안드로이드',
          'App',
        ]}
        imgSRC={Busan}
        client="공유부산"
        service=" Android app"
        year="2022"
        duration="1개월 소요"
        textColor="#C2D2FF"
        lineColor="#244299"
      />
    </>
  );
};

export default BusanHome;
