import React from 'react';
import DetailHeaderR from '../../../../../components/SoftwareDevelopmentjPage/SoftwareDevelopmentTab2/project/projectDetail/DetailHeaderR';
import Story from '../../../../../assets/images/SoftwareDevelopment/SoftwareDevelopmentTab2/projectDetail/Story/Story_Dmain.png';

const StoryHome: React.FC = () => {
  return (
    <>
      <DetailHeaderR
        tagline="상품권 사용 시스템"
        title="스토리 상품권"
        description1="필요한 물건이 있는데 며칠, 잠깐만 필요할 때. 구매하기는 아까울 때,물건을 가지고 있는 사람에게 필요한 만큼 빌리는 서비스입니다"
        hashtags={[
          '포인트',
          '결제',
          '상품권',
          '교환',
          'Android',
          '안드로이드',
          'App',
        ]}
        imgSRC={Story}
        client="스토리 상품권"
        service="Android app"
        year="2022"
        duration="2주 소요"
        textColor="#47CAFF"
        lineColor="#47CAFF"
      />
    </>
  );
};

export default StoryHome;
