import React from 'react';
import Footer from '../../../../../components/Footer';
import BusanHome from './BusanHome';
import BusanBody from './BusanBody';
import CommonBanner from '../../../../../components/CommonBanner';
import WebPath from '../../../../../components/WebPath';

const BusanPage: React.FC = () => {
  return (
    <>
      <CommonBanner subtitle="Development" title="포트폴리오" />
      <WebPath subtitle="포트폴리오" />
      <BusanHome />
      <BusanBody />
      <Footer />
    </>
  );
};

export default BusanPage;
