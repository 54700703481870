import React from 'react';
import styled from 'styled-components';

interface TitleSectionProps {
  subtitle: string;
  title: string;
}

const TitleContainer = styled.div`
  padding: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 20px;
  @media (max-width: 768px) {
    padding: 0px 0px 20px 0px;
    text-align: center;
  }
`;

const Title = styled.div`
  color: red;
  font-size: 50px;
  font-weight: 700;
  @media (max-width: 768px) {
    font-size: 30px;
  }
`;

const SubTitle = styled.p`
  font-size: 20px;
  letter-spacing: 1.5px;
  word-break: keep-all;
  @media (max-width: 768px) {
    font-size: 18px;
  }
`;

const TitleSection: React.FC<TitleSectionProps> = ({ subtitle, title }) => {
  return (
    <TitleContainer>
      <Title>{title}</Title>
      <SubTitle>{subtitle}</SubTitle>
    </TitleContainer>
  );
};

export default TitleSection;
