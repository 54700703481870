import React from 'react';
import styled from 'styled-components';

interface DetailHeaderRProps {
  tagline: string;
  title: string;
  client: string;
  description1: string;
  description2?: string;
  description3?: string;
  hashtags: string[];
  imgSRC: string;
  service: string;
  year: string;
  duration: string;
  textColor: string;
  lineColor: string;
  textLineColor?: string;
}

const DetailHeaderR: React.FC<DetailHeaderRProps> = ({
  tagline,
  title,
  description1,
  description2,
  description3,
  hashtags,
  imgSRC,
  client,
  service,
  year,
  duration,
  textColor = '#fff',
  lineColor = '#fff',
  textLineColor = '#fff',
}) => {
  return (
    <>
      <DetailContainer>
        <LeftSection>
          <TaglineText $textcolor={textColor}>{tagline}</TaglineText>
          <Title>{title}</Title>
          <Description>
            {description1}
            <br />
            {description2}
            <br />
            {description3}
          </Description>
          <HashtagContainer>
            <ul>
              {hashtags.map((tag, index) => (
                <li key={index}># {tag}</li>
              ))}
            </ul>
          </HashtagContainer>
        </LeftSection>
        <RightSection>
          <img src={imgSRC} alt={title} />
        </RightSection>
      </DetailContainer>
      <DetailFooterInfo $linecolor={lineColor} $textlinecolor={textLineColor}>
        <InfoDetail>
          <DetailType>Client</DetailType>
          <DetailDesc>{client}</DetailDesc>
        </InfoDetail>
        <InfoDetail>
          <DetailType>Type of service</DetailType>
          <DetailDesc>{service}</DetailDesc>
        </InfoDetail>
        <InfoDetail>
          <DetailType>Project Year</DetailType>
          <DetailDesc>{year}</DetailDesc>
        </InfoDetail>
        <InfoDetail>
          <DetailType>Duration</DetailType>
          <DetailDesc>{duration}</DetailDesc>
        </InfoDetail>
      </DetailFooterInfo>
    </>
  );
};

const DetailContainer = styled.div`
  display: flex;
  height: 70vh;
  align-items: center;
  justify-content: center;
  padding-top: 100px;
  padding-bottom: 20px;

  @media (max-width: 1920px) {
    padding-top: 100px;
    gap: 50px;
    padding: 50px;
  }

  @media (max-width: 1440px) {
    flex-direction: row;
    height: auto;
    padding: 150px 50px;
    gap: 50px;
  }

  @media (max-width: 1024px) {
    flex-direction: column;
    height: auto;
    padding-top: 100px;
    gap: 50px;
  }

  @media (max-width: 768px) {
    gap: 30px;
    padding: 100px 0px;
  }

  @media (max-width: 430px) {
    gap: 30px;
    padding: 50px 0 0 0;
  }
`;

const LeftSection = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0px 150px 0;
  gap: 30px;

  @media (max-width: 1920px) {
    padding: 80px 70px 0 120px;
  }

  @media (max-width: 1024px) {
    padding: 80px 70px 0 120px;
  }
  @media (max-width: 1440px) {
    padding: 0px;
    margin-top: 50px;
  }
  @media (max-width: 956px) {
    padding: 0px 20px 0 20px;
    width: 100%;
  }
  @media (max-width: 540px) {
    padding: 0px 20px 0 20px;
  }
`;

const RightSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  & > img {
    width: 100%;
    transform: scale(0.6);
  }

  @media (max-width: 1440px) {
    width: 80%;
  }

  @media (max-width: 768px) {
    & > img {
      transform: scale(0.9);
    }
  }
`;

const TaglineText = styled.span<{ $textcolor?: string }>`
  font-size: 1.2rem;
  color: ${(props) => props.$textcolor}; // 프롭스로 받은 bgColor 사용
`;

const Title = styled.h1`
  font-size: 4rem;

  @media (max-width: 1024px) {
    font-size: 2.5rem;
  }
  @media (max-width: 956px) {
    font-size: 2rem;
  }
`;
const Description = styled.p`
  font-size: 1.4rem;
  line-height: 1.5;

  @media (max-width: 1024px) {
    font-size: 1rem;
  }
`;

const HashtagContainer = styled.div`
  ul {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
  }

  @media (max-width: 1024px) {
    ul {
      gap: 10px;
    }
  }
`;

const DetailFooterInfo = styled.div<{
  $linecolor?: string;
  $textlinecolor?: string;
}>`
  background-color: ${(props) =>
    props.$linecolor}; // 프롭스로 받은 bgColor 사용
  height: 85px;
  color: ${(props) => props.$textlinecolor}; // 프롭스로 받은 bgColor 사용
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 100px;
  z-index: 999;

  @media (max-width: 1024px) {
    gap: 60px;
  }
  @media (max-width: 956px) {
    flex-direction: column;
    gap: 20px;
    height: auto;
    padding: 20px;
  }
`;

const InfoDetail = styled.div``;

const DetailType = styled.span`
  font-weight: 700;
`;
const DetailDesc = styled.span`
  padding: 0 20px;

  @media (max-width: 1024px) {
    padding: 0 10px;
  }
`;

export default DetailHeaderR;
