import React from 'react';
import styled from 'styled-components';
import cndG from '../../../assets/images/cdnService/cdnServiceTab1/cdnG.png';
import cdnK from '../../../assets/images/cdnService/cdnServiceTab1/cdnK.png';

const SoftwareSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 50px;
`;

const Title = styled.div`
  font-size: 50px;
  font-weight: 700;

  word-break: keep-all;
  @media (max-width: 1024px) {
    font-size: 40px;
  }
  @media (max-width: 768px) {
    font-size: 30px;
  }
`;

const TitleDescContainer = styled.div`
  font-size: 17px;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;
  letter-spacing: 1px;
  text-align: center;

  @media (max-width: 768px) {
    font-size: 16px;
    gap: 20px;
  }
`;

const Desc = styled.div`
  font-size: 17px;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;
  letter-spacing: 1px;
  text-align: center;
  & > p {
    word-break: keep-all;
  }

  @media (max-width: 768px) {
    font-size: 16px;
    gap: 10px;
  }
`;
const ImgContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 50px;
  & > img {
    width: 30%;
  }
  @media (max-width: 768px) {
    flex-direction: column;
    gap: 20px;
    & > img {
      width: 90%;
    }
  }
`;

function CDNServiceTab1Section1() {
  return (
    <SoftwareSection>
      <TitleDescContainer>
        <Title>CDN 서비스</Title>
        <Desc>
          <p>
            CDN (콘텐츠 전송 네트워크)란 여러 지역에 분산되는 서버 네트워크를
            의미합니다.
          </p>
          <p>
            다양한 웹 콘텐츠를 사용자와 가까운 서버에 캐싱하여 콘텐츠 로딩시간을
            단축하고, 트래픽 폭주 시 분산된
          </p>
          <p>서버를 통해 안정적으로 콘텐츠를 전송할 수 있습니다.</p>
        </Desc>
      </TitleDescContainer>
      <ImgContainer>
        <img src={cndG} alt="cdn 글로벌" />
        <img src={cdnK} alt="cdn 국내" />
      </ImgContainer>
    </SoftwareSection>
  );
}

export default CDNServiceTab1Section1;
