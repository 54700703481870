import React from 'react';
import Hyodong from '../../../../../assets/images/SoftwareDevelopment/SoftwareDevelopmentTab2/projectDetail/Hyodong/Hyodong_Dmain.png';
import DetailHeaderL from '../../../../../components/SoftwareDevelopmentjPage/SoftwareDevelopmentTab2/project/projectDetail/DetailHeaderL';

const HyodongHome: React.FC = () => {
  return (
    <>
      <DetailHeaderL
        tagline="선박 운항 일정 관리 시스템"
        title="효동항업 주식회사"
        description1="선박 운항 일정에 대한 정보를 수집할 수 있고, 검사 일정 등, 다른 페이지들에서 일정을 확실하게 알 수 있고 정보를 등록 수집할 수 있게 기획을 했습니다."
        description2="관리자와 사용자가 편리하게 레이아웃을 깔끔하게 디자인하여 가독성이 좋게 느껴지도록 했습니다."
        hashtags={[
          'ERP',
          '선박',
          '선박일정',
          'FCM 알람',
          '알람',
          '반응형',
          '직관적',
          '유지보수',
          'UI',
          'UX',
          'UI  UX',
          '디자인',
          '웹 디자인',
          'UI  UX 디자인',
          '관리자용 웹',
          'Excel 추출',
          'Excel',
        ]}
        imgSRC={Hyodong}
        client="효동항업 주식회사"
        service="Web / Android app"
        year="2023"
        duration="1개월 소요"
        textColor="#6CA7FF"
        lineColor="#3A5F96"
      />
    </>
  );
};

export default HyodongHome;
