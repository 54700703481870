import React from 'react';
import styled from 'styled-components';
import Image2 from '../../../../../assets/images/SoftwareDevelopment/SoftwareDevelopmentTab2/projectDetail/WashCube/Wash2.png';
import Image3 from '../../../../../assets/images/SoftwareDevelopment/SoftwareDevelopmentTab2/projectDetail/WashCube/Wash3.png';
import Image3BG from '../../../../../assets/images/SoftwareDevelopment/SoftwareDevelopmentTab2/projectDetail/WashCube/Wash3BG.png';
import Image4 from '../../../../../assets/images/SoftwareDevelopment/SoftwareDevelopmentTab2/projectDetail/WashCube/Wash4.png';
import Image5 from '../../../../../assets/images/SoftwareDevelopment/SoftwareDevelopmentTab2/projectDetail/WashCube/Wash5.png';

const DetailContainer = styled.div`
  padding: 5% 15%;

  @media (max-width: 1024px) {
    padding: 5% 15%;
  }
  @media (max-width: 768px) {
    padding: 2% 10%;
  }
`;

const Title = styled.h1`
  font-size: 3rem;
  text-align: center;

  @media (max-width: 768px) {
    padding-top: 20px;
    font-size: 2rem;
  }
  @media (max-width: 430px) {
    padding-top: 20px;
    font-size: 1.2rem;
  }
`;

const AndroidWrapper = styled.div`
  padding: 50px 0;
  display: flex;
  gap: 50px;
  @media (max-width: 1440px) {
    padding: 50px 40px;
  }

  @media (max-width: 1024px) {
    padding: 50px 0;
    gap: 10px;
  }
  @media (max-width: 768px) {
    padding: 50px 0;
    gap: 50px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

const AndroidWrapperBG = styled.div`
  padding: 50px 0;
  display: flex;
  gap: 50px;

  background-image: url(${Image3BG});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  @media (max-width: 1440px) {
    padding: 50px 40px;
  }

  @media (max-width: 1024px) {
    padding: 50px 0;
    gap: 10px;
  }
  @media (max-width: 768px) {
    padding: 50px 0;
    gap: 50px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

const FirstLeftSection = styled.div`
  width: 50%;
  padding-right: 15%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  h2 {
    font-size: 2rem;
    font-weight: 700;
    line-height: 4;
  }

  p {
    font-size: 1.5rem;
    line-height: 1.5;
    padding: 20px 0;
  }

  @media (max-width: 1920px) {
    p {
      font-size: 1.2rem;
    }
  }

  @media (max-width: 1440px) {
    h2 {
      font-size: 1.7rem;
      margin-top: 40%;
    }

    p {
      font-size: 0.9rem;
      line-height: 1.2;
      letter-spacing: 1.2px;
    }
  }

  @media (max-width: 1024px) {
    width: 35%;
    padding-top: 8%;

    h2 {
      font-size: 1.7rem;
      margin-top: 30%;
    }

    p {
      font-size: 0.9rem;
      line-height: 1.5;
      letter-spacing: 1.2px;
      padding: 10px 0;
    }
  }

  @media (max-width: 768px) {
    width: 100%;
    padding-top: 0%;
    h2 {
      text-align: center;
      font-size: 1.7rem;
      margin-top: 0%;
    }

    p {
      text-align: center;
      font-size: 0.9rem;
      line-height: 1.5;
      letter-spacing: 1.2px;
      padding: 10px 0;
    }
  }
`;

const FirstRightSection = styled.div`
  width: 50%;

  @media (max-width: 1920px) {
    width: 50%;
  }

  @media (max-width: 1440px) {
    width: 60%;
    img {
      width: 100%;
    }
  }
`;

const SecondLeftSection = styled.div`
  width: 50%;
  padding-top: 1%;

  img {
    width: 100%;
  }

  @media (max-width: 1920px) {
    width: 50%;
  }

  @media (max-width: 1440px) {
    width: 50%;
  }

  @media (max-width: 1024px) {
    width: 35%;
  }
`;

const SecondRightSection = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  h2 {
    font-size: 2rem;
    font-weight: 700;
    line-height: 4;
  }

  p {
    font-size: 1.5rem;
    line-height: 1.5;
    padding: 20px 0;
  }

  @media (max-width: 1920px) {
    p {
      font-size: 1.2rem;
    }
  }

  @media (max-width: 1440px) {
    h2 {
      font-size: 1.7rem;
    }

    p {
      font-size: 0.9rem;
      line-height: 1.2;
      letter-spacing: 1.2px;
    }
  }

  @media (max-width: 1024px) {
    width: 35%;
    padding-top: 8%;

    h2 {
      font-size: 1.7rem;
      margin-top: 30%;
    }

    p {
      font-size: 0.9rem;
      line-height: 1.5;
      letter-spacing: 1.2px;
      padding: 10px 0;
    }
  }
  @media (max-width: 768px) {
    width: 100%;
    padding-top: 8%;

    h2 {
      text-align: center;
      font-size: 1.7rem;
      margin-top: 30%;
    }

    p {
      text-align: center;
      font-size: 0.9rem;
      line-height: 1.5;
      letter-spacing: 1.2px;
      padding: 10px 0;
    }
  }
`;

const ThirdLeftSection = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  p {
    font-size: 1.5rem;
    line-height: 1.5;
    padding: 20px 0;
  }

  @media (max-width: 1920px) {
    p {
      font-size: 1.2rem;
    }
  }

  @media (max-width: 1440px) {
    p {
      font-size: 0.9rem;
      line-height: 1.2;
      letter-spacing: 1.2px;
    }
  }

  @media (max-width: 1024px) {
    p {
      font-size: 0.9rem;
      line-height: 1.5;
      letter-spacing: 1.2px;
      padding: 10px 0;
    }
  }
  @media (max-width: 768px) {
    width: 100%;
    p {
      text-align: center;
      font-size: 0.9rem;
      line-height: 1.5;
      letter-spacing: 1.2px;
      padding: 10px 0;
    }
  }
`;

const ThirdRightSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 50%;
`;

const AndroidSection = styled.div`
  padding: 50px 0;
  text-align: center;
  img {
    width: 100%;
  }

  @media (max-width: 1920px) {
    img {
      width: 100%;
    }
  }

  @media (max-width: 1440px) {
    img {
      width: 100%;
    }
  }

  @media (max-width: 1024px) {
    padding: 50px 0px;
    img {
      width: 100%;
    }
  }
`;

const WebContainer = styled.div`
  padding: 50px 0;
`;

const OuterContainer = styled.div``;

const WashCubeBody: React.FC = () => {
  return (
    <>
      <DetailContainer>
        <Title>KIOSK / UI UX 디자인</Title>
        <WebContainer>
          <AndroidWrapper>
            <FirstLeftSection>
              <h2>설계</h2>
              <p>
                운전자의 연령층이 다양하다는 것을 감안하여
                <br />
                간결한 UI로 사용성을 높였습니다.
              </p>
            </FirstLeftSection>
            <FirstRightSection>
              <img src={Image2} alt="Image2" />
            </FirstRightSection>
          </AndroidWrapper>
          <AndroidWrapperBG>
            <SecondLeftSection>
              <img src={Image3} alt="Image2" />
            </SecondLeftSection>
            <SecondRightSection>
              <h2>설계</h2>
              <p>
                간결한 결제 시스템으로 누구나 헤메지 않고
                <br />
                결제를 진행할 수 있습니다.
              </p>
            </SecondRightSection>
          </AndroidWrapperBG>
          <AndroidWrapper>
            <ThirdLeftSection>
              <p>
                키오스크의 진행 과정을 상세히 알려주어
                <br />
                고객이 의문점을 가지게 하지 않습니다.
              </p>
            </ThirdLeftSection>
            <ThirdRightSection>
              <img src={Image4} alt="Image2" />
            </ThirdRightSection>
          </AndroidWrapper>
        </WebContainer>
      </DetailContainer>
      <OuterContainer>
        <Title>현장 사용 이미지</Title>
        <AndroidSection>
          <img src={Image5} alt="Image2" />
        </AndroidSection>
      </OuterContainer>
    </>
  );
};

export default WashCubeBody;
