import React from 'react';
import styled from 'styled-components';
import ColocationLine from '../../../assets/images/colocation/colocationSection2/ColocationLine.png';
import ColocationLineCard from './colocationComponent/ColocationLineCard';

const SoftwareSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 50px;
  padding: 100px 0px;
  background-color: #f5f5f5;
  width: 100%;
  @media (max-width: 768px) {
    padding: 50px;
  }
`;

const Title = styled.div`
  word-break: keep-all;
  font-size: 50px;
  font-weight: 700;
  @media (max-width: 1024px) {
    font-size: 40px;
  }
  @media (max-width: 768px) {
    font-size: 30px;
  }
`;

const TitleDescContainer = styled.div`
  font-size: 17px;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 50px;
  letter-spacing: 1px;
  text-align: center;
  word-break: keep-all;
  width: 100%;

  @media (max-width: 768px) {
    font-size: 16px;
  }
`;

const ImgContainerPC = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 50px;
  & > img {
    width: 90%;
  }
  @media (max-width: 968px) {
    display: none;
  }
`;
const ImgContainerM = styled.div`
  display: none;
  @media (max-width: 968px) {
    width: 80%;
    gap: 20px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;
  }
  @media (max-width: 768px) {
    width: 90%;
    justify-content: space-between;
  }
`;

function ColocationSection2() {
  return (
    <SoftwareSection>
      <TitleDescContainer>
        <Title>코로케이션 회선추가</Title>
        <ImgContainerPC>
          <img src={ColocationLine} alt="ColocationLine" />
        </ImgContainerPC>
        <ImgContainerM>
          <ColocationLineCard
            bandwidth="10Mbps"
            port="1G port"
            label="별도협의"
          />
          <ColocationLineCard
            bandwidth="100Mbps"
            port="1G port"
            label="별도협의"
          />
          <ColocationLineCard
            bandwidth="1Gbps"
            port="1G/10G port"
            label="별도협의"
          />
          <ColocationLineCard
            bandwidth="10Gbps"
            port="10Mbps"
            label="별도협의"
          />
        </ImgContainerM>
      </TitleDescContainer>
    </SoftwareSection>
  );
}

export default ColocationSection2;
