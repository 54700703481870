import React from 'react';
import styled from 'styled-components';

interface InfoCardProps {
  bandwidth: string;
  port: string;
  label: string;
}

const Card = styled.div`
  background-color: #fcb1b1; /* 연한 분홍색 */
  padding: 20px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
  font-size: 16px;
  width: 250px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  @media (max-width: 677px) {
    width: 100%;
  }
`;

const Bandwidth = styled.h1`
  font-size: 25px;
  font-weight: bold;
`;
const Row = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const Label = styled.h3`
  font-size: 16px;
  font-weight: bold;
`;

const ColocationLineCard: React.FC<InfoCardProps> = ({
  bandwidth,
  port,
  label,
}) => {
  return (
    <Card>
      <Bandwidth>{bandwidth}</Bandwidth>
      <Row>
        <Label>제공회선</Label>
        <p>{port}</p>
      </Row>
      <Row>
        <Label>설치비</Label>
        <p>{label}</p>
      </Row>
      <Row>
        <Label>월 이용료</Label>
        <p>{label}</p>
      </Row>
    </Card>
  );
};

export default ColocationLineCard;
