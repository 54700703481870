import React from 'react';
import styled from 'styled-components';

const Card = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  background: #fff;
  border-radius: 50px;
  padding: 50px;
  box-shadow: 0px 8px 24px 0 rgba(0, 0, 0, 0.2);
  width: 50%;
  @media (max-width: 1440px) {
    gap: 20px;
  }
  @media (max-width: 1024px) {
    width: 90%;
  }
  @media (max-width: 768px) {
    padding: 40px;
    gap: 20px;
  }
`;

const NameContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
`;
const Name = styled.h1`
  font-weight: 700;
  font-size: 24px;
  color: #e63946;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Label = styled.span`
  font-weight: 700;
`;

const Value = styled.span`
  color: #333;
`;

const WebServerCard2: React.FC = ({}) => {
  return (
    <Card>
      <NameContainer>
        <Name>추가 트래픽 과금</Name>
      </NameContainer>
      <Row>
        <Label>1GB-10TB 초과</Label>
        <Value>113.72원(GB당)</Value>
      </Row>
      <Row>
        <Label>10TB-50TB 초과</Label>
        <Value>107.40원(GB당)</Value>
      </Row>
      <Row>
        <Label>50TB-150TB 초과</Label>
        <Value>88.45원(GB당)</Value>
      </Row>
      <Row>
        <Label>150TB이상 초과</Label>
        <Value>63.18원(GB당)</Value>
      </Row>
    </Card>
  );
};

export default WebServerCard2;
