import React from 'react';
import Story1 from '../../../../../assets/images/SoftwareDevelopment/SoftwareDevelopmentTab2/projectDetail/Story/Story_1.png';
import Story2 from '../../../../../assets/images/SoftwareDevelopment/SoftwareDevelopmentTab2/projectDetail/Story/Story_2.png';
import Story3 from '../../../../../assets/images/SoftwareDevelopment/SoftwareDevelopmentTab2/projectDetail/Story/Story_3.png';
import DetailBody from '../../../../../components/SoftwareDevelopmentjPage/SoftwareDevelopmentTab2/project/projectDetail/DetailBody';

const StoryBody: React.FC = () => {
  return (
    <>
      <DetailBody
        service={'ANDROID / UI UX 디자인'}
        description1={`일반 결제부터 스캔으로 이뤄지는 간단한 QR 결제까지
        스토리 상품권으로 이용해보세요.`}
        description2={`고마운 마음을 상품권으로 전달해보세요.
        받는 분의 휴대폰 번호만 알아도 손쉽게 전달이 가능합니다!`}
        description3={`상품권을 어디서 사용할 수 있을까?
        내 가게에서 상품권을 사용할 수는 없을까?
        
        어플에서 가맹점을 등록하고 조회를 하면 손쉽게 해결하실 수 있습니다.`}
        imgSRC1={Story1}
        imgSRC2={Story2}
        imgSRC3={Story3}
        imgAlt1="스토리 상품권"
      />
    </>
  );
};

export default StoryBody;
