import React from 'react';
import HRLDMS from '../../../../../assets/images/SoftwareDevelopment/SoftwareDevelopmentTab2/projectDetail/HRLDMS/HRDLMS_Dmain.png';
import DetailHeaderL from '../../../../../components/SoftwareDevelopmentjPage/SoftwareDevelopmentTab2/project/projectDetail/DetailHeaderL';

const HRDLMSHome: React.FC = () => {
  return (
    <>
      <DetailHeaderL
        tagline="LMS(학습관리 시스템), ERP"
        title="HRDLMS"
        description1="LMS(학습관리 시스템)을 더욱더 간편하게 회원등록을 하고 
        출석은 어플로 하여 들어온 데이터값으로 엑셀로 뽑을수 있게 값이 들어와 
        사용자가 편리하게 기획하고 개발을 하였습니다."
        hashtags={[
          '실존하는 대부분의 기능',
          'ERP',
          '문자발송',
          '이메일 발송',
          '결제',
          '결제 시스템',
          '통신',
          '전자출결',
          'Excel 추출',
          'Excel',
        ]}
        imgSRC={HRLDMS}
        client="HRDLMS"
        service="Web"
        year="2023 / 2024"
        duration="현재 개발중"
        textColor="#fff"
        lineColor="#B5A396"
        textLineColor="#000"
      />
    </>
  );
};

export default HRDLMSHome;
