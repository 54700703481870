import React from 'react';
import styled from 'styled-components';
import Image2 from '../../../../../assets/images/SoftwareDevelopment/SoftwareDevelopmentTab2/projectDetail/Care/Care2.png';
import Image3 from '../../../../../assets/images/SoftwareDevelopment/SoftwareDevelopmentTab2/projectDetail/Care/Care3.png';
import Image4 from '../../../../../assets/images/SoftwareDevelopment/SoftwareDevelopmentTab2/projectDetail/Care/Care4.png';
import Image5 from '../../../../../assets/images/SoftwareDevelopment/SoftwareDevelopmentTab2/projectDetail/Care/Care5.png';
import Image6 from '../../../../../assets/images/SoftwareDevelopment/SoftwareDevelopmentTab2/projectDetail/Care/Care6.png';

const DetailContainer = styled.div`
  padding: 5% 15%;

  @media (max-width: 1440px) {
    padding: 5% 5%;
  }
`;

const Title = styled.h1`
  font-size: 3rem;
  text-align: center;

  @media (max-width: 768px) {
    font-size: 2rem;
  }
  @media (max-width: 430px) {
    font-size: 1.5rem;
  }
`;

const AndroidWrapper = styled.div`
  padding: 50px 0 10%;
  display: flex;
  gap: 50px;

  @media (max-width: 1440px) {
    padding: 50px 40px;
  }

  @media (max-width: 1024px) {
    padding: 50px 0px;
    gap: 20px;
  }

  @media (max-width: 768px) {
    padding: 50px 0px;
    gap: 40px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

const LeftSection1 = styled.div`
  width: 50%;
  padding-right: 10%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  h2 {
    font-size: 3rem;
    font-weight: 700;
    line-height: 4;
    padding-top: 18%;
    width: 100%;
  }

  p {
    font-size: 2rem;
    line-height: 1.5;
    padding: 20px 0;
  }

  @media (max-width: 1920px) {
    padding-top: 5%;
    padding-right: 5%;
    h2 {
      font-size: 2rem;
    }

    p {
      font-size: 1.2rem;
    }
  }

  @media (max-width: 1440px) {
    width: 50%;
    padding-right: 0%;
    h2 {
      font-size: 1.7rem;
      margin-top: 0%;
    }

    p {
      font-size: 1.1rem;
      line-height: 1.2;
      letter-spacing: 1.2px;
    }
  }

  @media (max-width: 1024px) {
    padding-top: 5%;

    h2 {
      font-size: 1.7rem;
      margin-top: 0%;
    }

    p {
      font-size: 0.9rem;
      line-height: 1.5;
      letter-spacing: 1.2px;
      padding: 10px 0;
    }
  }

  @media (max-width: 768px) {
    padding-top: 0%;
    width: 80%;
    h2 {
      text-align: center;
      font-size: 1.7rem;
      margin-top: 0%;
    }

    p {
      text-align: center;
      font-size: 0.9rem;
      line-height: 1.5;
      letter-spacing: 1.2px;
      padding: 10px 0;
    }
  }
`;

const RightSection1 = styled.div`
  width: 50%;

  & > img {
    width: 100%;
    position: relative;
    right: 10%;
    top: 20%;
    transform: scale(1.5);
  }

  @media (max-width: 1920px) {
    & > img {
      width: 100%;
      position: relative;
      right: 10%;
      top: 20%;
      transform: scale(1.4);
    }
  }

  @media (max-width: 1440px) {
    & > img {
      width: 100%;
      position: relative;
      right: 15%;
      top: 20%;
      transform: scale(1.3);
    }
  }

  @media (max-width: 1024px) {
    & > img {
      width: 100%;
      position: relative;
      right: 15%;
      top: 25%;
      transform: scale(1.2);
    }
  }
`;

const LeftSection2 = styled.div`
  width: 50%;
  padding-top: 1%;
  padding-left: 10%;
  & > img {
    transform: scale(1.25);
  }

  @media (max-width: 1920px) {
    position: relative;
    padding-left: 3.5%;
    & > img {
      transform: scale(1);
    }
  }

  @media (max-width: 1440px) {
    & > img {
      width: 100%;
      transform: scale(1.1);
    }
  }

  @media (max-width: 1024px) {
    & > img {
      width: 100%;
      position: relative;
      right: 0%;
      top: 0%;
      transform: scale(1);
    }
  }
`;

const RightSection2 = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  h2 {
    font-size: 3rem;
    font-weight: 700;
    line-height: 4;
  }

  p {
    font-size: 2rem;
    line-height: 1.5;
    padding: 20px 0;
  }

  @media (max-width: 1920px) {
    h2 {
      font-size: 2rem;
    }

    p {
      font-size: 1.2rem;
    }
  }

  @media (max-width: 1440px) {
    h2 {
      font-size: 1.7rem;
    }

    p {
      font-size: 1.1rem;
      line-height: 1.2;
      letter-spacing: 1.2px;
    }
  }

  @media (max-width: 1024px) {
    padding-top: 8%;

    h2 {
      font-size: 1.7rem;
      margin-top: 30%;
    }

    p {
      font-size: 0.9rem;
      line-height: 1.5;
      letter-spacing: 1.2px;
      padding: 10px 0;
    }
  }

  @media (max-width: 768px) {
    padding-top: 8%;
    width: 70%;
    h2 {
      text-align: center;
      font-size: 1.7rem;
      margin-top: 30%;
    }

    p {
      text-align: center;
      font-size: 0.9rem;
      line-height: 1.5;
      letter-spacing: 1.2px;
      padding: 10px 0;
    }
  }
`;

const LeftSection3 = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  p {
    font-size: 2rem;
    line-height: 1.5;
    padding: 20px 0;
  }

  @media (max-width: 1920px) {
    p {
      font-size: 1.2rem;
    }
  }

  @media (max-width: 1440px) {
    p {
      font-size: 1.1rem;
      line-height: 1.2;
      letter-spacing: 1.2px;
    }
  }

  @media (max-width: 1024px) {
    p {
      font-size: 0.9rem;
      line-height: 1.5;
      letter-spacing: 1.2px;
      padding: 10px 0;
    }
  }

  @media (max-width: 768px) {
    p {
      text-align: center;
      font-size: 0.9rem;
      line-height: 1.5;
      letter-spacing: 1.2px;
      padding: 10px 0;
    }
  }
`;

const RightSection3 = styled.div`
  width: 50%;

  @media (max-width: 1440px) {
    & > img {
      width: 100%;
      transform: scale(1.1);
    }
  }
  @media (max-width: 1024px) {
    & > img {
      width: 100%;
      position: relative;
      right: 0%;
      top: 0%;
      transform: scale(1);
    }
  }
  @media (max-width: 1920px) {
    position: relative;
    padding-left: 3.5%;
    & > img {
      transform: scale(1);
    }
  }
`;

const RightSection4 = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: relative;
  gap: 50px;
  & > img {
    width: 100%;
    height: auto;
    display: block;
  }

  p {
    position: absolute;
    top: 80%;
    right: 10%;
    transform: translate(0, -50%);
    margin: 0;
    font-size: 2rem;
    line-height: 2;
  }

  @media (max-width: 1440px) {
    p {
      font-size: 1.1rem;
    }
  }
  @media (max-width: 1024px) {
    p {
      font-size: 1.1rem;
      right: 0%;
    }
  }
  @media (max-width: 768px) {
    p {
      text-align: center;
      font-size: 1.1rem;
      position: relative;
      right: 0%;
    }
  }
`;

const AndroidSection = styled.div`
  padding: 50px 0 0;
  text-align: center;

  h1 {
    line-height: 3;
  }

  img {
    width: 100%;
    transform: scale(1);
  }
`;

// const OuterContainer = styled.div``;

const CareBody: React.FC = () => {
  return (
    <>
      <DetailContainer>
        <Title>ANDROID / UI UX 디자인 </Title>
        <AndroidWrapper>
          <LeftSection1>
            <h2>설계</h2>
            <p>
              전자기기가 익숙하지 않은 어르신들을 위해 간단하게 함축시킨 UI를
              사용하여 한 눈에 보이도록 설계했습니다.
            </p>
          </LeftSection1>
          <RightSection1>
            <img src={Image2} alt="Image2" />
          </RightSection1>
        </AndroidWrapper>

        <AndroidWrapper>
          <LeftSection2>
            <img src={Image3} alt="Image2" />
          </LeftSection2>
          <RightSection2>
            <p>
              사용자가 버튼을 눌러 전화를 하면 받는 사람에게 회원정보가 뜹니다.
            </p>
          </RightSection2>
        </AndroidWrapper>

        <AndroidWrapper>
          <LeftSection3>
            <p>회원에 대한 정보를 리스트화하여 정리하였습니다.</p>
          </LeftSection3>
          <RightSection3>
            <img src={Image4} alt="Image2" />
          </RightSection3>
        </AndroidWrapper>

        <AndroidWrapper>
          <RightSection4>
            <img src={Image5} alt="Image2" />
            <p>
              배너, 상담, 회원정보를
              <br />
              관리자앱에서 등록, 관리 가능합니다.
            </p>
          </RightSection4>
        </AndroidWrapper>
        <AndroidSection>
          <h1>관련기사</h1>
          <img src={Image6} alt="Image2" />
        </AndroidSection>
      </DetailContainer>
    </>
  );
};

export default CareBody;
