import React from 'react';
import Footer from '../../../../../components/Footer';
import CompanyHome from './CompanyHome';
import CompanyBody from './CompanyBody';
import CommonBanner from '../../../../../components/CommonBanner';
import WebPath from '../../../../../components/WebPath';

const CompanyPage: React.FC = () => {
  return (
    <>
      <CommonBanner subtitle="Development" title="포트폴리오" />
      <WebPath subtitle="포트폴리오" />
      <CompanyHome />
      <CompanyBody />
      <Footer />
    </>
  );
};

export default CompanyPage;
