import React from 'react';
import { DevelopmentLanguageContainer } from '../../../../assets/css/StyledComponents';
import LanguageCard from './LanguageCard';

import java from '../../../../assets/images/SoftwareDevelopment/SoftwareDevelopmentTab1/DevelopmentLanguage/java.png';
import Kotlin from '../../../../assets/images/SoftwareDevelopment/SoftwareDevelopmentTab1/DevelopmentLanguage/Kotlin.png';
import c from '../../../../assets/images/SoftwareDevelopment/SoftwareDevelopmentTab1/DevelopmentLanguage/c.png';
import Cp from '../../../../assets/images/SoftwareDevelopment/SoftwareDevelopmentTab1/DevelopmentLanguage/C++.png';
import Cs from '../../../../assets/images/SoftwareDevelopment/SoftwareDevelopmentTab1/DevelopmentLanguage/C#.png';
import reactNative from '../../../../assets/images/SoftwareDevelopment/SoftwareDevelopmentTab1/DevelopmentLanguage/reactNative.png';
import Flutter from '../../../../assets/images/SoftwareDevelopment/SoftwareDevelopmentTab1/DevelopmentLanguage/Flutter.png';

function Android() {
  return (
    <DevelopmentLanguageContainer>
      <LanguageCard imgSrc={java} title="java" />
      <LanguageCard imgSrc={Kotlin} title="Kotlin" />
      <LanguageCard imgSrc={c} title="c" />
      <LanguageCard imgSrc={Cp} title="c++" />
      <LanguageCard imgSrc={Cs} title="c#" />
      <LanguageCard imgSrc={reactNative} title="React Native" />
      <LanguageCard imgSrc={Flutter} title="Flutter" />
    </DevelopmentLanguageContainer>
  );
}

export default Android;
