import { DevelopmentLanguageContainer } from '../../../../assets/css/StyledComponents';
import React from 'react';
import LanguageCard from './LanguageCard';
import java from '../../../../assets/images/SoftwareDevelopment/SoftwareDevelopmentTab1/DevelopmentLanguage/java.png';
import python from '../../../../assets/images/SoftwareDevelopment/SoftwareDevelopmentTab1/DevelopmentLanguage/python.png';
import c from '../../../../assets/images/SoftwareDevelopment/SoftwareDevelopmentTab1/DevelopmentLanguage/c.png';
import Cp from '../../../../assets/images/SoftwareDevelopment/SoftwareDevelopmentTab1/DevelopmentLanguage/C++.png';

function EmbeddedSystem() {
  return (
    <DevelopmentLanguageContainer>
      <LanguageCard imgSrc={java} title="java" />
      <LanguageCard imgSrc={python} title="python" />
      <LanguageCard imgSrc={c} title="c" />
      <LanguageCard imgSrc={Cp} title="c++" />
    </DevelopmentLanguageContainer>
  );
}

export default EmbeddedSystem;
