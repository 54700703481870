import Modal from '../../../../components/Modal';
import React, { useState } from 'react';
import styled from 'styled-components';
import { phoneNumber } from '../../../../utils/phoneNumber';

interface ServerProps {
  name: string;
  desc: string;
  cpu: string;
  memory: string;
  disk: string;
  publicIP: string;
  price: string;
  traffic: string;
}

const Card = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  background: #fff;
  border-radius: 50px;
  padding: 50px;
  box-shadow: 0px 8px 24px 0 rgba(0, 0, 0, 0.2);
  width: 470px;
  @media (max-width: 1440px) {
    width: 400px;
    gap: 20px;
  }
  @media (max-width: 1024px) {
    width: 90%;
    gap: 20px;
  }
  @media (max-width: 768px) {
    width: 90%;
    padding: 40px;
    gap: 20px;
  }
`;

const NameContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
const Name = styled.h1`
  font-weight: 700;
  font-size: 24px;
`;
const Desc = styled.p`
  display: flex;
  flex-direction: column;
  word-break: keep-all;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Label = styled.span`
  font-weight: 700;
`;

const Value = styled.span`
  color: #333;
`;
const PriceContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  @media (max-width: 468px) {
    flex-direction: column;
    gap: 20px;
  }
`;

const Price = styled.div`
  font-size: 24px;
  color: #e63946;
  text-align: center;
  font-weight: 600;
`;

const ActionButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #e63946;
  color: white;
  border: none;
  font-weight: 700;
  padding: 8px 24px;
  border-radius: 999px;
  cursor: pointer;
  font-size: 18px;
  &:hover {
    background-color: #c02734;
  }
  @media (max-width: 468px) {
    width: 100%;
  }
`;

const WebServerCard: React.FC<ServerProps> = ({
  name,
  desc,
  cpu,
  memory,
  disk,
  publicIP,
  price,
  traffic,
}) => {
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const handleInquiryClick = (): void => {
    const isMobile = /Mobi|Android/i.test(navigator.userAgent);
    if (isMobile) {
      window.location.href = `tel:${phoneNumber}`;
    } else {
      setModalOpen(true); // 모달 열기
    }
  };

  const handleClose = (): void => {
    setModalOpen(false); // 모달 닫기
  };

  return (
    <Card>
      <NameContainer>
        <Name>{name}</Name>
        <Desc>{desc}</Desc>
      </NameContainer>
      <Row>
        <Label>CPU</Label>
        <Value>{cpu}</Value>
      </Row>
      <Row>
        <Label>Memory</Label>
        <Value>{memory}</Value>
      </Row>
      <Row>
        <Label>Disk</Label>
        <Value>{disk}</Value>
      </Row>
      <Row>
        <Label>공인 IP</Label>
        <Value>{publicIP}</Value>
      </Row>
      <Row>
        <Label>트래픽</Label>
        <Value>{traffic}</Value>
      </Row>

      <PriceContainer>
        <Price>{price} (월)</Price>
        <ActionButton onClick={handleInquiryClick}>신청하기</ActionButton>
        <Modal show={modalOpen} onClose={handleClose} />
      </PriceContainer>
    </Card>
  );
};

export default WebServerCard;
