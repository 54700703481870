import React from 'react';
import styled from 'styled-components';
import TabComponentSub from '../../../components/TabComponentSub';
import FrontEnd from './DevelopmentLanguageTabs/FrontEnd';
import BackEnd from './DevelopmentLanguageTabs/BackEnd';
import Android from './DevelopmentLanguageTabs/Android';
import Ios from './DevelopmentLanguageTabs/Ios';
import EmbeddedSystem from './DevelopmentLanguageTabs/EmbeddedSystem';

const SoftwareSection = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 50px;
  background-color: #f5f5f5;
  padding: 80px;

  @media (max-width: 768px) {
    gap: 30px;
  }
`;

const Title = styled.div`
  font-size: 50px;
  font-weight: 700;
  @media (max-width: 1024px) {
    font-size: 40px;
  }
  @media (max-width: 768px) {
    font-size: 30px;
  }
`;

function SoftwareSection3() {
  const tabs = [
    {
      title: '프론트엔드',
      content: <FrontEnd />,
    },
    {
      title: '백엔드',
      content: <BackEnd />,
    },
    {
      title: 'Android',
      content: <Android />,
    },
    {
      title: 'iOS',
      content: <Ios />,
    },
    {
      title: '임베디드 시스템',
      content: <EmbeddedSystem />,
    },
  ];
  return (
    <SoftwareSection>
      <Title>소프트웨어 개발 언어</Title>
      <TabComponentSub tabs={tabs} />
    </SoftwareSection>
  );
}

export default SoftwareSection3;
