import Footer from '../components/Footer';
import styled from 'styled-components';
import BusinessSection from '../components/mainPage/BusinessSection';
import HeaderBanner from '../components/mainPage/HeaderBanner ';
import IntroductionSection from '../components/mainPage/IntroductionSection';
import DevelopmentPortfolio from '../components/mainPage/DevelopmentPortfolio';
import BusinessPartner from '../components/BusinessPartner';
import WayToCome from '../components/mainPage/WayToCome';

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

function MainPage() {
  return (
    <PageContainer>
      <HeaderBanner />
      <BusinessSection />
      <IntroductionSection />
      <DevelopmentPortfolio />
      <BusinessPartner />
      <WayToCome />
      <Footer />
    </PageContainer>
  );
}

export default MainPage;
