import React from 'react';
import DetailHeaderR from '../../../../../components/SoftwareDevelopmentjPage/SoftwareDevelopmentTab2/project/projectDetail/DetailHeaderR';
import FBMS from '../../../../../assets/images/SoftwareDevelopment/SoftwareDevelopmentTab2/projectDetail/FBMS/FBMS_Dmain.png';

const FBMSHome: React.FC = () => {
  return (
    <>
      <DetailHeaderR
        tagline="지게차 배터리 관리 시스템"
        title="F-BMS"
        description1="중요한 정보를 담고 있는 그래프를 한눈에 볼 수 있는 장점을 강조하여 디자인 하였습니다."
        description2="다른 페이지들도 통일성있는 레이아웃으로 디자인을 진행하였으며, 적절한 명도를 가진 색상들을 사용하여 눈에 부담이 없도록 디자인하였습니다."
        hashtags={[
          '베터리',
          '지게차',
          'UI',
          'UX',
          'UI  UX',
          '디자인',
          '웹 디자인',
          'UI  UX 디자인',
        ]}
        imgSRC={FBMS}
        client="F-BMS"
        service="UI UX 디자인"
        year="2023"
        duration="2주 소요"
        textColor="#B7E6FF"
        lineColor="#223139"
      />
    </>
  );
};

export default FBMSHome;
