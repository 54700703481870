import React, { useState, useEffect, useRef } from 'react';
import * as pdfjsLib from 'pdfjs-dist';
import 'pdfjs-dist/web/pdf_viewer.css';
import './BrochurePage.css';

// 올바른 타입을 가져오기 위해 필요한 경로로 수정합니다.
import {
  PDFDocumentProxy,
  PDFPageProxy,
} from 'pdfjs-dist/types/src/display/api';

// PDF.js 작업자 경로 설정
pdfjsLib.GlobalWorkerOptions.workerSrc = `${process.env.PUBLIC_URL}/pdfjs/pdf.worker.min.js`;

const BrochurePage: React.FC = () => {
  const [pdfDoc, setPdfDoc] = useState<PDFDocumentProxy | null>(null);
  const [numPages, setNumPages] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const viewerRef = useRef<HTMLDivElement | null>(null);
  const hasDownloadedRef = useRef(false);
  const isRenderingRef = useRef(false);

  const fetchAndLoadPdf = async (url: string) => {
    setLoading(true);
    try {
      const loadingTask = pdfjsLib.getDocument(url);
      const pdf = await loadingTask.promise;
      setPdfDoc(pdf);
      setNumPages(pdf.numPages);
      setLoading(false);
    } catch (error) {
      setError('Error fetching and loading PDF: ' + (error as Error).message);
      setLoading(false);
    }
  };

  const calculateScale = (page: PDFPageProxy) => {
    if (!viewerRef.current) {
      console.error('viewerRef is not available');
      return 1; // 기본 스케일 값 반환
    }

    const viewerWidth = viewerRef.current.clientWidth;
    const pdfWidth = page.getViewport({ scale: 1 }).width;
    return viewerWidth / pdfWidth;
  };

  const renderPages = async (
    startPageNum: number,
    numPagesToRender: number
  ) => {
    if (!pdfDoc || startPageNum > numPages) return;
    if (isRenderingRef.current) return; // 이미 렌더링 중이면 중단

    isRenderingRef.current = true;

    try {
      for (let i = 0; i < numPagesToRender; i++) {
        const pageNum = startPageNum + i;
        if (pageNum > numPages) break;

        const page = await pdfDoc.getPage(pageNum);
        const scale = calculateScale(page);
        const viewport = page.getViewport({ scale });

        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d')!;

        const outputScale = window.devicePixelRatio || 1;
        canvas.width = Math.floor(viewport.width * outputScale);
        canvas.height = Math.floor(viewport.height * outputScale);
        canvas.style.width = `${viewport.width}px`;
        canvas.style.height = `${viewport.height}px`;

        const renderContext = {
          canvasContext: context,
          viewport: viewport,
          transform: [outputScale, 0, 0, outputScale, 0, 0], // 고해상도 렌더링을 위한 transform 적용
        };

        await page.render(renderContext).promise;
        viewerRef.current?.appendChild(canvas);
      }

      isRenderingRef.current = false;

      // 다음 페이지를 계속 렌더링
      if (startPageNum + numPagesToRender <= numPages) {
        renderPages(startPageNum + numPagesToRender, numPagesToRender);
      }
    } catch (error) {
      console.error('Error rendering page:', (error as Error).message);
      isRenderingRef.current = false;
    }
  };

  useEffect(() => {
    const pdfUrl = `${process.env.PUBLIC_URL}/assets/LodongBrochure.pdf`;
    fetchAndLoadPdf(pdfUrl);

    if (!hasDownloadedRef.current) {
      const link = document.createElement('a');
      link.href = pdfUrl;
      link.download = 'LodongBrochure.pdf';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      hasDownloadedRef.current = true; // 다운로드를 한 번만 실행하도록 설정
    }

    return () => {
      if (viewerRef.current) {
        viewerRef.current.innerHTML = ''; // Clean up previously rendered pages
      }
    };
  }, []);

  useEffect(() => {
    if (pdfDoc && numPages > 0) {
      if (!viewerRef.current) {
        console.error('viewerRef is not available');
        return;
      }

      viewerRef.current.innerHTML = '';
      renderPages(1, 20); // 한 번에 3페이지 렌더링
    }
  }, [pdfDoc, numPages]);

  return (
    <div className="container">
      {loading && <div>Loading PDF...</div>}
      {error && <div className="error">{error}</div>}
      <div id="pdf-viewer" ref={viewerRef}></div>
    </div>
  );
};

export default BrochurePage;
