import CDNServiceTabSection2 from '../../../components/cdnService/cdnServiceTab1/CDNServiceTab1Section2';
import CDNServiceTabSection1 from '../../../components/cdnService/cdnServiceTab1/CDNServiceTab1Section1';
import React from 'react';
import styled from 'styled-components';

const CDNServiceTab1Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 20px;
  gap: 80px;
  @media (max-width: 768px) {
    padding-top: 30px;
    gap: 30px;
  }
`;

function CDNServiceTab1() {
  return (
    <CDNServiceTab1Container>
      <CDNServiceTabSection1 />
      <CDNServiceTabSection2 />
    </CDNServiceTab1Container>
  );
}

export default CDNServiceTab1;
