import React from 'react';
import LanguageCard from './LanguageCard';
import { DevelopmentLanguageContainer } from '../../../../assets/css/StyledComponents';
import html from '../../../../assets/images/SoftwareDevelopment/SoftwareDevelopmentTab1/DevelopmentLanguage/html.png';
import css from '../../../../assets/images/SoftwareDevelopment/SoftwareDevelopmentTab1/DevelopmentLanguage/css.png';
import js from '../../../../assets/images/SoftwareDevelopment/SoftwareDevelopmentTab1/DevelopmentLanguage/js.png';
import react from '../../../../assets/images/SoftwareDevelopment/SoftwareDevelopmentTab1/DevelopmentLanguage/React.png';
import ts from '../../../../assets/images/SoftwareDevelopment/SoftwareDevelopmentTab1/DevelopmentLanguage/ts.png';
import vue from '../../../../assets/images/SoftwareDevelopment/SoftwareDevelopmentTab1/DevelopmentLanguage/vue.png';
import B from '../../../../assets/images/SoftwareDevelopment/SoftwareDevelopmentTab1/DevelopmentLanguage/B.png';
import A from '../../../../assets/images/SoftwareDevelopment/SoftwareDevelopmentTab1/DevelopmentLanguage/A.png';
import JQuery from '../../../../assets/images/SoftwareDevelopment/SoftwareDevelopmentTab1/DevelopmentLanguage/JQuery.png';

function FrontEnd() {
  return (
    <DevelopmentLanguageContainer>
      <LanguageCard imgSrc={html} title="HTML" />
      <LanguageCard imgSrc={css} title="CSS" />
      <LanguageCard imgSrc={js} title="Javascript" />
      <LanguageCard imgSrc={react} title="React" />
      <LanguageCard imgSrc={ts} title="Type Script" />
      <LanguageCard imgSrc={vue} title="Vue" />
      <LanguageCard imgSrc={B} title="Bootstrap" />
      <LanguageCard imgSrc={A} title="Angular" />
      <LanguageCard imgSrc={JQuery} title="jQUERY" />
    </DevelopmentLanguageContainer>
  );
}

export default FrontEnd;
