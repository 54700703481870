import React from 'react';
import Ai from '../../../../../assets/images/SoftwareDevelopment/SoftwareDevelopmentTab2/projectDetail/Ai/Ai1.png';
import DetailHeaderR from '../../../../../components/SoftwareDevelopmentjPage/SoftwareDevelopmentTab2/project/projectDetail/DetailHeaderR';

const AiHome: React.FC = () => {
  return (
    <>
      <DetailHeaderR
        tagline="인공지능 윤리 설문조사"
        title="AI 도시를 부탁해"
        description1="설문조사 결과에 따라 유형을 부여하여 사용자들끼리의
        결속감을 높이고, 설문자의 조건을 조회하여 해당 설문자들의 
       통계를 확인할 수 있습니다."
        description2="또한, 각 문항마다 자신의 의견을 기입하여 AI에 대한 생각을 
        공유할 수 있습니다"
        hashtags={[
          '설문',
          '설문조사',
          '인공지능',
          '인공',
          'UI',
          'UX',
          'UI UX',
          'Web',
          '교육',
          '윤리',
          'AI',
          '유지 보수',
        ]}
        imgSRC={Ai}
        client="AI 도시를 부탁해"
        service="Web"
        year="2022"
        duration="1개월 소요"
        textColor="#f8d58a"
        lineColor="#f8e2b3"
        textLineColor="#000"
      />
    </>
  );
};

export default AiHome;
