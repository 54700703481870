import React from 'react';
import Onfabric from '../../../../../assets/images/SoftwareDevelopment/SoftwareDevelopmentTab2/projectDetail/OF/Onfabric1.png';
import DetailHeaderL from '../../../../../components/SoftwareDevelopmentjPage/SoftwareDevelopmentTab2/project/projectDetail/DetailHeaderL';

const OFHome: React.FC = () => {
  return (
    <>
      <DetailHeaderL
        tagline="진우텍스 홈페이지"
        title="Onfabric"
        description1="진우텍스 원단 수출 수입 홈페이지를 서버에 등록과 관리를하고
        관리자페이지가 따로 있어 관리자가 사용하기가 
        편리하게 되어있습니다."
        hashtags={[
          '원단',
          '수출',
          '원단 수출',
          'Web',
          '유지보수',
          '기능',
          'Web',
          'Web 기능',
        ]}
        imgSRC={Onfabric}
        client="Onfabric"
        service="Web"
        year="2023"
        duration="1개월 소요"
        textColor="#fff "
        lineColor="#EC864B"
      />
    </>
  );
};

export default OFHome;
